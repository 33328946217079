import React, { useContext } from "react";
import cn from "classnames";
import Box from "ui-box";
import { useGameplay } from "App";
import { Decimal } from "decimal.js";

import useNewCallout from "./useNewCallOut";

import "./CallOut.css";
import Brush from "./Brush";
import { useTranslation } from "react-i18next";

export const BaseCallOut = ({
  CN,
  EN,
  name,
  showBrush,
  showPointChanges,
  newGE,
  seat,
  calloutBySeat,
  discardBy,
}: {
  CN?: string;
  EN: string;
  name: string;
  showBrush: boolean;
  showPointChanges: boolean;
  newGE;
  seat: number;
  calloutBySeat?: number;
  discardBy?;
}) => {
  // console.log("[Callout] newGE", newGE);

  const gameMode = newGE.gameMode;
  const basePrice = new Decimal(newGE?.roomData?.basePrice || 0);
  const specialPrice = new Decimal(newGE?.roomData?.specialPrice || 0);

  // console.log("[CallOut]: basePrice, speicialPrice", basePrice.toString(), specialPrice.toString());

  let priceDisplay;
  let winlose;

  switch (name) {
    case "gang":
      if (seat === calloutBySeat) {
        priceDisplay = `+${basePrice.times(3).toFixed(2)}`;
        winlose = "win";
      } else {
        priceDisplay = `-${basePrice.times(3).toFixed(2)}`;
        winlose = "lose";
      }

      break;
    case "angang":
      if (seat === calloutBySeat) {
        priceDisplay = `+${specialPrice.times(3).toFixed(2)}`;
        winlose = "win";
      } else {
        priceDisplay = `-${specialPrice.toFixed(2)}`;
        winlose = "lose";
      }
      break;
    case "addgang":
      if (seat === calloutBySeat) {
        priceDisplay = `+${basePrice.times(3).toFixed(2)}`;
        winlose = "win";
      } else {
        priceDisplay = `-${basePrice.toFixed(2)}`;
        winlose = "lose";
      }

      break;

    default:
      break;
  }

  // console.log(`priceDisplay for ${seat}: ${priceDisplay}`);

  return (
    <Box
      className={cn("CallOut-container", {
        "CallOut-tile": !showBrush,
        "CallOut-take": showBrush,
        [`CallOut-${name}`]: showBrush,
      })}
    >
      {CN && <Box className="CallOut-CN">{CN}</Box>}
      <Box className="CallOut-EN">{EN}</Box>
      {showBrush && (
        <Brush
          className={cn("CallOut-brush", {
            [`CallOut-${name}`]: showBrush,
          })}
        />
      )}

      {showPointChanges && <div className={`pointChanges-${winlose}`}>{priceDisplay}</div>}
    </Box>
  );
};

export const CallOut = ({ seat, uid }: { seat: number; uid: string }) => {
  const { t } = useTranslation();
  const { newGE } = useGameplay();

  const lastPlay = newGE?.gameParams?.lastPlay;
  const claimReason = newGE?.gameParams?.seatReason;
  const seatTurn = newGE?.gameParams?.seatTurn;
  const isCalledOut = !!lastPlay || !!claimReason;

  const actionLastPlay = newGE?.gameParams?.actionLastPlay;

  const callout = useNewCallout({ lastPlay, claimReason, seatTurn });
  const isThisSeatsCallout =
    callout?.uid === uid || callout?.seat === seat || ["gang", "angang", "addgang"].some((v) => v === callout?.name);

  // console.log("[Callout] lastPlay:", lastPlay);
  // console.log("[Callout] claimReason:", claimReason);
  // console.log("[Callout] seatTurn:", seatTurn);
  // console.log("[Callout] isCalledOut:", isCalledOut);
  // console.log("[Callout] isThisSeatsCallout:", isThisSeatsCallout);
  // console.log("[Callout] actionLastPlay:", actionLastPlay);
  // console.log("[Callout] callout:", callout);
  // console.log("[Callout] seat, uid", seat, uid);

  // console.log("[Callout] Logic check:", !isCalledOut || !callout || !isThisSeatsCallout || !actionLastPlay);

  if (!isCalledOut || !callout || !isThisSeatsCallout) return null;
  if (callout.name === "gang" && !actionLastPlay) return null;

  switch (callout?.name) {
    case "chi":
      return (
        <BaseCallOut
          key={callout.id}
          name={callout.name}
          EN=""
          CN={t("callout.chi")}
          // "吃"
          showBrush={true}
          showPointChanges={false}
          newGE={newGE}
          seat={seat}
          calloutBySeat={callout.seat}
        />
      );
    case "peng":
      return (
        <BaseCallOut
          key={callout.id}
          name={callout.name}
          EN=""
          CN={t("callout.peng")}
          // "碰"
          showBrush={true}
          showPointChanges={false}
          newGE={newGE}
          seat={seat}
          calloutBySeat={callout.seat}
        />
      );
    case "gang":
      // add only show gong to relevant player logic

      if (seat === callout.seat || uid === actionLastPlay.by) {
        return (
          <BaseCallOut
            key={callout.id}
            name={callout.name}
            EN=""
            CN={t("callout.gang")}
            // "明杠"
            showBrush={true}
            showPointChanges={true}
            newGE={newGE}
            seat={seat}
            calloutBySeat={callout.seat}
            discardBy={actionLastPlay.by}
          />
        );
      } else {
        return null;
      }
      break;

    case "angang":
      return (
        <BaseCallOut
          key={callout.id}
          name={callout.name}
          EN=""
          CN={t("callout.angang")}
          // "暗杠"
          showBrush={true}
          showPointChanges={true}
          newGE={newGE}
          seat={seat}
          calloutBySeat={callout.seat}
        />
      );
    case "addgang":
      return (
        <BaseCallOut
          key={callout.id}
          name={callout.name}
          EN=""
          CN={t("callout.addgang")}
          // "加杠"
          showBrush={true}
          showPointChanges={true}
          newGE={newGE}
          seat={seat}
          calloutBySeat={callout.seat}
        />
      );
    case "hu":
      return (
        <BaseCallOut
          key={callout.id}
          name={callout.name}
          EN=""
          CN={t("callout.hu")}
          // "糊"
          showBrush={true}
          showPointChanges={false}
          newGE={newGE}
          seat={seat}
          calloutBySeat={callout.seat}
        />
      );
    default:
      return (
        <BaseCallOut
          key={callout.id}
          name={callout.name}
          EN={callout.name}
          showBrush={true}
          showPointChanges={false}
          newGE={newGE}
          seat={seat}
          calloutBySeat={callout.seat}
        />
      );
  }
};

export default CallOut;

import React, { useCallback, useContext, useEffect, useState } from "react";
import useSound from "use-sound";
import _ from "lodash";
import { useLocation } from "@reach/router";
import { useAuthState } from "react-firebase-hooks/auth";
import { navigate } from "@reach/router";

import Table from "./components/Table";
import Menu from "./components/Menu";
import "./InGame.scss";
import { priceList } from "game-constants";

import { useAuth, useGameplay } from "App";
import { getRoomData } from "actions/api";
import { createJoinedPlayerParams, createActivePlayerParams } from "newEngine/helpers";

// const REVEAL_SFX = require(" ../../sounds/tile-reveal.mp3");
import REVEAL_SFX from "sounds/tile-reveal.mp3";

import { useTranslation } from "react-i18next";

const GamePage = (props) => {
  const { t } = useTranslation();
  const { currentUser, idToken, Auth } = useAuth();
  const { gameData, setGameplayConfig, newGE } = useGameplay();
  const location = useLocation();
  const [user, loading, error] = useAuthState(Auth);

  const [roomData, setRoomData] = useState(null);
  const [activePlayers, setActivePlayers] = useState();
  const [joinedPlayers, setJoinedPlayers] = useState();

  const [isTryingToJoinRoom, setIsTryingToJoinRoom] = useState(true);

  const [play] = useSound(REVEAL_SFX);

  // const [priceMode, setPriceMode] = useState("");
  // From Reach Router
  // const roomId = props.roomId;
  // const gameMode = props.gameMode;
  // const priceMode = props.priceMode;

  const { roomId, gameMode, priceMode } = props;
  // const isOnAutopilot = GE?.isOnAutopilot;

  let gameModeString = "";

  useEffect(() => {
    if (roomData) {
      // console.log("roomData:", roomData);
      setIsTryingToJoinRoom(false);
    }
  }, [roomData]);

  useEffect(() => {
    // console.log("gameData changed", newGE?.roomData);
    setRoomData(newGE?.roomData);
    if (
      newGE?.roomData?.gameEnded === true && //
      newGE?.roomData?.gameMode === gameMode &&
      newGE?.roomData?.priceMode === priceMode &&
      newGE?.roomId === roomId
    ) {
      // console.log("newGE.gameEnded turned true");
      play();
      navigate(`/endGame/${gameMode}/${priceMode}/${roomId}`);
    }
  }, [newGE]);

  useEffect(() => {
    if (currentUser) {
      getCurrentRoomData().catch(console.error);

      // console.log("attaching gameplay listener");
      // Dynamically set the collection and document ID
      setGameplayConfig(`${gameMode}-${priceMode}`, roomId);
    }
  }, [currentUser]);

  const getCurrentRoomData = async () => {
    // let latestIdToken = await Auth.currentUser.getIdToken();
    // let latestIdToken = idToken;
    const latestIdToken = localStorage.getItem("mjToken");

    // console.log("latestIdToken", latestIdToken);
    const currentRoomData = await getRoomData(gameMode, priceMode, roomId, latestIdToken);
    setRoomData(currentRoomData);
    // console.log("currentRoomData from getCurrentRoomData", currentRoomData);
  };

  // console.log("priceMode", priceMode);
  if (gameMode === "BASIC") {
    gameModeString = "打出";
  } else {
    gameModeString = "自摸";
  }

  function getPriceModeString(priceMode) {
    let priceModeString = "";

    let priceModeItem = _.find(priceList, { priceModeId: priceMode });
    priceModeString = `${priceModeItem.basePrice} / ${priceModeItem.specialPrice}`;

    return priceModeString;
  }

  // console.log("roomData", roomData);

  return (
    <div className="ingame-container">
      {isTryingToJoinRoom && (
        <div className="matchingOtherPlayersPrompt">
          <div className="title">
            {/* 正在连接其他玩家... */}
            {t("ingame_container.connecting_other_players")}
          </div>
        </div>
      )}

      {roomData ? (
        roomData.gameStarted ? (
          <Table players={createActivePlayerParams(roomData.playerParams)} roomData={roomData} mode="ingame" />
        ) : (
          <Table players={createJoinedPlayerParams(roomData.playerParams)} roomData={roomData} mode="waiting" />
        )
      ) : (
        <div className="noRoomData">no room data</div>
      )}

      <Menu {...props} />
      {/* <div className="price-container">{priceMode && <>注码: {getPriceModeString(priceMode)} </>}</div> */}
      <div className="price-container">
        {priceMode && (
          <>
            {/* {" "} */}
            <div className="gamePriceModeString">
              {gameModeString}: {getPriceModeString(priceMode)}
            </div>
          </>
        )}
      </div>
      {/* {isOnAutopilot && (
        <div className="autopilotPrompt">
          <div className="title">您正处于自动代打模式，是否要继续游戏？</div>
          <div
            className="confirm-btn border-button"
            onClick={() => {
              // GE.cancelAutopilot();
            }}
          >
            返回游戏
          </div>
        </div>
      )} */}
      <div className="roomIdString">{roomId}</div>
    </div>
  );
};

export default GamePage;

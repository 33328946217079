import React, { useContext, useEffect, useRef, useState } from "react";
import { useGameplay } from "App";
import useNewCallout from "components/CallOut/useNewCallOut";
import "./AnimationStage.scss";

const AnimationStage = ({ players }) => {
  const { gameData } = useGameplay();

  const lastPlay = gameData?.gameParams?.lastPlay;
  const claimReason = gameData?.gameParams?.seatReason;
  const seatTurn = gameData?.gameParams?.seatTurn;

  const callout = useNewCallout({ lastPlay, claimReason, seatTurn });

  useEffect(() => {
    // console.log("[AnimationStage]: callout:", callout);
  }, [callout]);

  let calloutAction = "";

  if (callout?.name) {
    switch (callout?.name) {
      case "peng":
        calloutAction = "overlay_pong";
        break;
      case "gang":
        calloutAction = "overlay_gong";
        break;
      case "angang":
        calloutAction = "overlay_gong";
        break;
      case "addgang":
        calloutAction = "overlay_gong";
        break;
      case "hu":
        calloutAction = "overlay_hu";
        break;
      default:
        calloutAction = "";
        break;
    }
  }

  return (
    <div className="animationStage-container">
      {calloutAction !== "" ? (
        <div
          style={{ height: "100%", width: "100%" }}
          dangerouslySetInnerHTML={{
            __html: `
                    <video class="overlay_action" autoplay muted playsinline>
                          <source src="/animations/mp4/${calloutAction}.mp4" type="video/mp4;codecs=hvc1" />
                          <source src="/animations/webm/${calloutAction}.webm" type="video/webm" />
                          Your browser does not support the video tag.
                    </video>
                  `,
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default AnimationStage;

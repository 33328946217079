import "./App.scss";
import { initializeApp } from "firebase/app";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFirestore, connectFirestoreEmulator, getDoc, doc, onSnapshot } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { RecaptchaVerifier, getAuth, onAuthStateChanged, browserLocalPersistence } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faCopy, faGear, faRotate, faXmark, faFileArrowUp, faArrowRightFromBracket, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// import { faCopy } from "@fortawesome/free-regular-svg-icons";

import { useAuthState } from "react-firebase-hooks/auth";
import React, { createContext, useContext, useEffect, useState, useRef } from "react";

import { Router } from "@reach/router";

import { GameEngine } from "./engine/GameEngine";
import { GameState } from "./newEngine/newEngine";
// import { firebaseConfig } from "./firebaseConfig";
import GamePlayPage from "./pages/NewFlow/GamePlay";
import EndGamePage from "./pages/NewFlow/EndGame";
import WelcomePage from "./pages/NewFlow/WelcomePage";
import AuthContainer from "pages/NewFlow/AuthContainer";
import GameModeLobby from "pages/NewFlow/GameModeLobby";
import SettingsLobby from "pages/NewFlow/SettingsLobby";
import ProfileSetup from "pages/NewFlow/ProfileSetup";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

//- Firebase Setup
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const firestore = getFirestore(app);
const auth = getAuth(app, {
  persistence: [browserLocalPersistence],
});
const functions = getFunctions(app, "asia-southeast1");
const storage = getStorage(app);
const analytics = getAnalytics(app);

export const localVersion = "1.3.47";
export const localVersionDate = "240827-1140";
console.log("host:", window.location.host);

// if (window.location.host === "localhost:3000") {
//   console.log("connecting to emulator");
//   // connectDatabaseEmulator(db, "127.0.0.1", 9000);
//   connectFunctionsEmulator(functions, "127.0.0.1", 5001);
//   // connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
// }

export const FirebaseContext = React.createContext();
export const useFirebase = () => useContext(FirebaseContext);
// FIXME: We actually don't need to create a GE Ctx, we could instead directly export
// the GE object. This would be cleaner.

const FirebaseContextProvider = ({ children, Storage, Analytics, Firestore }) => {
  useEffect(() => {
    console.log("[Context] FirebaseContext is being called...");
  });

  const contextValue = {
    Firestore,
    Storage,
    Analytics,
  };

  return <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider>;
};

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children, Auth }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [idToken, setIdToken] = useState(null);

  useEffect(() => {
    const unsubscribe = Auth.onAuthStateChanged(async (user) => {
      // console.log("[Auth context] onAuthStateChanged changed", user);
      setCurrentUser(user);
      if (user) {
        const token = await user.getIdToken(true);
        setIdToken(token);
      } else {
        setIdToken(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={{ currentUser, idToken, Auth: Auth }}>{children}</AuthContext.Provider>;
};

export const GameplayContext = createContext();
export const useGameplay = () => useContext(GameplayContext);

export const GameplayProvider = ({ children, Firestore, Auth, CloudFunc }) => {
  const [gameData, setGameData] = useState(null);
  const [newGE, setNewGE] = useState(null);
  const [config, setConfig] = useState({ collectionName: null, documentId: null });
  const unsubscribeRef = useRef(null); // Ref to hold the unsubscribe function

  useEffect(() => {
    const { collectionName, documentId } = config;

    if (!collectionName || !documentId) return;

    const docRef = doc(Firestore, collectionName, documentId);

    unsubscribeRef.current = onSnapshot(docRef, (doc) => {
      // console.log("[App] received new gameplay snapshot", doc.data());
      setGameData(doc.data());
      setNewGE(new GameState(Firestore, Auth, CloudFunc, collectionName, documentId, doc.data(), unsubscribeGameplay));
    });

    return () => {
      if (unsubscribeRef.current) {
        console.log("[App] unsubscribing to gameplay data...");
        unsubscribeRef.current();
      }
    }; // Clean up the listener when the component unmounts
  }, [config]);

  const setGameplayConfig = (collectionName, documentId) => {
    setConfig({ collectionName, documentId });
  };

  const unsubscribeGameplay = () => {
    if (unsubscribeRef.current) {
      console.log("[App] manually unsubscribing to gameplay data...");
      unsubscribeRef.current();
      setNewGE(null);
      setGameData(null);
      setConfig({ collectionName: null, documentId: null });
    }
  };

  return (
    <GameplayContext.Provider value={{ gameData, newGE, setGameplayConfig, unsubscribeGameplay, Firestore: Firestore }}>
      {children}
    </GameplayContext.Provider>
  );
};

//- App Setup
const App = (props) => {
  const [modalBanner, setModalBanner] = useState(false);
  const [gameServerStatus, setGameServerStatus] = useState(null);
  const [gameServerStatusMessage, setGameServerStatusMessage] = useState("");

  useEffect(() => {
    const clearStorageAndData = async () => {
      localStorage.clear();
      if ("caches" in window) {
        const cacheNames = await caches.keys();
        await Promise.all(cacheNames.map((name) => caches.delete(name)));
      }
    };

    const checkForUpdates = async () => {
      const versionDoc = await getDoc(doc(firestore, "systemConfig", "version"));
      const remoteVersion = versionDoc.data().versionNum;
      const gameServerStatus = versionDoc.data().status;
      const gameServerStatusMessage = versionDoc.data().statusMessage;

      console.log("remote version is:", remoteVersion);
      console.log("local version is:", localVersion);
      console.log("gameServerStatus is:", gameServerStatus);

      if (gameServerStatus !== "live") {
        setGameServerStatusMessage(gameServerStatusMessage);
        setModalBanner("downtime");
        return;
      }

      if (remoteVersion !== localVersion) {
        // await clearStorageAndData();
        setModalBanner("newVersion");
        return;
      }
    };

    checkForUpdates();
  }, []);

  const handleUpdate = () => {
    // Assuming you're using service workers for your PWA
    console.log("handleUpdate PWA now...");
    window.location.reload(true);
  };

  return (
    // <EngineContext.Provider value={{ GE, Auth: auth, Firestore: firestore, Storage: storage, User: thisUser, CloudFunc: functions }}>
    <FirebaseContextProvider Firestore={firestore} Storage={storage} Analytics={analytics}>
      <AuthProvider Auth={auth}>
        <GameplayProvider Firestore={firestore} Auth={auth} CloudFunc={functions}>
          <Router>
            <WelcomePage path="/" default />
            <AuthContainer path="/auth" />
            <GamePlayPage path="/:gameMode/:priceMode/:roomId" />
            <EndGamePage path="/endGame/:gameMode/:priceMode/:roomId" />
            <GameModeLobby path="/GameModeLobby" />
            {/* <GameModeLobby path="/" default /> */}
            <SettingsLobby path="/Settings" />
            <ProfileSetup path="/ProfileSetup" />
          </Router>
          <div id="recaptcha-container"></div>

          {modalBanner === null && <></>}

          {modalBanner === "downtime" && (
            <div className="appUpdateModal">
              <div className="title">{gameServerStatusMessage}</div>
            </div>
          )}

          {modalBanner === "newVersion" && (
            <div className="appUpdateModal">
              <div className="title">游戏有新版本可用，请立即更新</div>
              <div className="confirm-btn border-button" onClick={handleUpdate}>
                立即更新
              </div>
            </div>
          )}
        </GameplayProvider>
      </AuthProvider>
    </FirebaseContextProvider>
    // </EngineContext.Provider>
  );
};

export default App;
library.add(faGear, faRotate, faXmark, faFileArrowUp, faArrowRightFromBracket, faCopy, faTelegram, faCircleInfo);

import React, { useContext, useEffect, useRef, useState } from "react";
import { useAuth, useGameplay, useFirebase } from "App";
import { doc, getDoc, collection, addDoc, setDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { useAuthState, useUpdateProfile } from "react-firebase-hooks/auth";
import { useUploadFile, useDownloadURL } from "react-firebase-hooks/storage";
import { checkReferralCode, generateToken } from "actions/api";
import { useTranslation } from "react-i18next";

import "./ProfileSetup.scss";
import { navigate } from "@reach/router";
// import { BiImageAdd } from "react-icons/bi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PuffLoader from "react-spinners/PuffLoader";

function ProfileSetup() {
  const { t } = useTranslation();

  const { Storage } = useFirebase();
  const { Firestore } = useGameplay();
  const { currentUser, idToken, Auth } = useAuth();
  const [user, authLoading, authError] = useAuthState(Auth);
  const [updateProfile, updating, error] = useUpdateProfile(Auth);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState();
  const [referralCode, setReferralCode] = useState("");
  const [downloadURL, setDownloadURL] = useState();
  const [referralCodeError, setReferralCodeError] = useState("");
  const latestIdToken = localStorage.getItem("mjToken");

  // const loading = true;
  function handleInputChange(e) {
    e.preventDefault();
    const { value } = e.target;
    setUsername(value);
  }

  function handleReferralCodeChange(e) {
    e.preventDefault();
    setReferralCode(e.target.value);
  }

  async function checkIfReferralCodeIsValid(referralCode) {
    console.log("referralCode Input", referralCode);

    // const idToken = await Auth.currentUser.getIdToken();

    if (referralCode === "") {
      return true;
    } else {
      // check if referral code is valid

      // let referralCodeResult = await checkReferralCode(referralCode, idToken);
      let referralCodeResult = await checkReferralCode(referralCode, latestIdToken);

      console.log("referralCodeResult", referralCodeResult);

      if (referralCodeResult.status === 404) {
        setReferralCodeError(t("profile_setup.referral_code_incorrect"));
        setLoading(false);
        return false;
      } else if (referralCodeResult.status === 201) {
        return true;
      }
    }
  }

  async function updateUserProfile() {
    setLoading(true);

    let referralCodeCheck = await checkIfReferralCodeIsValid(referralCode);

    console.log("referralCodeCheck", referralCodeCheck);

    if (referralCodeCheck) {
      const success = await updateProfile({ displayName: username, photoURL: downloadURL });
      if (success) {
        updateDoc(doc(Firestore, "users", user.uid), {
          username,
          profileURL: downloadURL,
          updatedAt: serverTimestamp(),
          profileSetup: true,
        })
          .then((result) => {
            navigate("/GameModeLobby", { state: { newUser: true } });
            // navigate("/", { state: { newUser: true } });
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
    // setLoading(false);
  }

  return (
    <>
      <div className="profileSetup-container">
        <div className="gameTitle"></div>

        <div className="profile-frame">
          <div className="profile-container">
            <ProfileUploadButton Storage={Storage} user={user} setDownloadURL={setDownloadURL} />
            <input
              className="username-input profile-setting-input"
              type="text"
              name="username"
              placeholder={t("profile_setup.please_enter_username")}
              onChange={handleInputChange}
              maxLength="20"
            />
            <input
              className="referral-code-input profile-setting-input"
              name="referralCode"
              type="text"
              placeholder={t("profile_setup.referral_code_if_any")}
              maxLength="4"
              onChange={handleReferralCodeChange}
            />
            <div className="referralCodeError">{referralCodeError}</div>
            <button
              className="finish-btn"
              disabled={!username || !downloadURL}
              onClick={(e) => {
                e.preventDefault();
                updateUserProfile();
              }}
            >
              {t("profile_setup.complete_registration")}
            </button>

            {/* </form> */}
          </div>
        </div>

        {authLoading || loading ? (
          <div className="loading-container">
            <PuffLoader className="puff-loader" color={"#ffffff"} loading={loading} size={180} />
            <div className="typewriter">now loading ...</div>
          </div>
        ) : null}
      </div>
    </>
  );
}

function ProfileUploadButton({ Storage, user, setDownloadURL }) {
  const ref = useRef(null);

  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileURL, setSelectedFileURL] = useState();

  const [uploadFile, uploading, snapshot, error] = useUploadFile();
  // const [value, loading, downloadURLError] = useDownloadURL(storageRef(Storage, firebaseStoragePath));

  // useEffect(() => {
  //   console.log("donwloadURL", value, loading, downloadURLError);
  // });

  const handleClick = () => {
    ref.current?.click();
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    setSelectedFileURL(URL.createObjectURL(file));
    setSelectedFile(file);

    uploadToFirebase(file);
  };

  const uploadToFirebase = async (file) => {
    console.log("calling uploadToFirebase");
    if (file) {
      const result = await uploadFile(storageRef(Storage, `user_images/${user.uid}/${file.name}`), file);
      // console.log(`uploaded result:`, result);

      const downloadURL = await getDownloadURL(storageRef(Storage, `user_images/${user.uid}/${file.name}`));
      console.log("downloadURL", downloadURL);
      setDownloadURL(downloadURL);
    }
  };

  return (
    <div className="profile-circle" onClick={handleClick}>
      {selectedFile ? (
        <img className="img-preview" alt="profile-img" src={selectedFileURL} />
      ) : (
        // <BiImageAdd className="profile-icon" />
        <FontAwesomeIcon icon="fa-file-arrow-up" className="profile-icon" />
      )}

      <input type="file" ref={ref} className="hidden" onChange={handleChange} inputprops={{ accept: "image/*" }} />
    </div>
  );
}

export default ProfileSetup;

import CardPak from "engine/CardPak";
import { Action, ActionParams, Card } from "engine/CardPakTypes";
import { oVal, sum } from "utils";

import { makeTiles } from "./Tiles";
import { Tile } from "./Tiles.model";

import type { Deck, Rules } from "engine/CardPakTypes";
import type { GameEngine } from "engine/GameEngine";
import { Decimal } from "decimal.js";

import { postGameResult } from "actions/api";

/**
 * Base Rule Set
 *
 * This is the basic infrastructure for a
 * Majiang rule set.
 * @class
 */
class Majiang extends CardPak {
  deck: Deck = {
    visualDeckId: "dx-traditional",
    cards: makeTiles(),
  };
  rules: Rules = {
    minSeats: 3,
    maxSeats: 4,
    turnBased: true,
    automaticWin: false,

    gameParams: {
      wall: [],
      deadWall: [],
      lastPlay: null,
      wind: 0,
    },
    playerParams: {
      closedHand: [], // Tiles in any order
      // openHand: {}, // Array of tiles open to be seen, grouped by meld
      openHand: [],
      playedTiles: [],
      gamePoints: 0,
      skipped: false,
      winner: false,
    },

    onGameStart: (gameEngine) => {
      const shuffledDeck = this.shuffledDeck.map((tile) => ({
        ...tile,
        params: tile.defaultParams,
      }));

      // Deal tiles to walls
      const deadWallStart = shuffledDeck.length - 14;
      let wall = shuffledDeck?.slice(0, deadWallStart);
      let deadWall = shuffledDeck?.slice(deadWallStart);

      // Draw tiles from walls
      gameEngine.playerParams?.forEach((player: any) => {
        const playerHand = wall.slice(0, 13);
        wall = wall.slice(13, deadWallStart);
        gameEngine.updatePlayer(player.id, {
          ...player,
          closedHand: playerHand,
        });
      });

      // Set Game Params
      gameEngine.updateGameParams({ wall, deadWall });
    },
    onTurnStart: () => {},
    onTurnEnd: () => {},
    onGameEnd: () => {},
    onCardClick: ({ executingPlayerId, card, gameEngine }) => {
      if (!executingPlayerId) return;

      const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      if (!isMyTurn || !card) return;

      const hasAFullHand = this.hasAFullHand(playerParams);
      const tileIsInClosedHand = !!playerParams.closedHand.find((c: Card) => c.id === card.id);
      if (!hasAFullHand || !tileIsInClosedHand) return;

      // Remove card from hand to played
      const closedHand = playerParams.closedHand.filter((c: Card) => c.id !== card.id).map((t: Card) => ({ ...t, justDrawn: false }));
      const playedTiles = [...playerParams.playedTiles, card];
      const newPlayerParams = {
        ...playerParams,
        closedHand,
        playedTiles,
      };

      gameEngine.updateGameParams({
        lastPlay: { card, by: executingPlayerId },
      });
      gameEngine.updatePlayer(executingPlayerId, newPlayerParams);
      gameEngine.finishTurn();
    },

    playerActions: [
      //* Draw
      {
        name: "Draw",
        isAvailable: ({ executingPlayerId, gameEngine }) => {
          const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);

          const hasAFullHand = this.hasAFullHand(playerParams);

          const awaitingNoOne = this.onlyMyActionOrEveryoneSkipped({
            executingPlayerId,
            gameEngine,
          });

          if (!isMyTurn || hasAFullHand || !awaitingNoOne) return false;
          return true;
        },
        onExecute: ({ executingPlayerId, gameEngine }) => {
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);

          const wall: Card[] = gameEngine.gameParams?.wall;
          const drawnTile = wall.shift();
          const closedHand = [...playerParams.closedHand, { ...drawnTile, justDrawn: true }];

          const newPlayerParams = { closedHand };

          this.resetSkips(gameEngine);
          gameEngine.updateGameParams({ wall });
          gameEngine.updatePlayer(executingPlayerId, newPlayerParams);
          gameEngine.updateReact();
        },
      },
    ],
  };

  FULL_HAND_SIZE = 14;

  //* Helper Methods
  endGame = async (gameEngine: GameEngine, winnerId?: string, winnerNewParams?: any) => {
    if (winnerId) {
      let winnerPlayerParams;
      let loserPlayerParams;
      let winnerPoints;
      let loserPoints;

      let specialPrice = gameEngine.specialPrice;

      let newWinnerParams;
      let newLoserParams;

      if (winnerNewParams.huReason === "winFromOthers") {
        // 出銃
        await gameEngine.finishGame(winnerNewParams.huReason);

        winnerPoints = specialPrice;
        loserPoints = specialPrice.times(-1);

        newWinnerParams = {
          gamePoints: winnerPoints.toFixed(6),
          ...winnerNewParams,
          winner: true,
          netGamePoints: new Decimal(1).minus(gameEngine.houseCommission).times(winnerPoints).toFixed(6),
        };

        gameEngine.updatePlayer(winnerId, newWinnerParams);

        console.log(
          "[winFromOthers] winnerPoints, loserPoints, specialPrice, winnerNewParams, newWinnerParams:",
          winnerPoints,
          loserPoints,
          specialPrice,
          newWinnerParams
        );

        let updatedWinnerParams = gameEngine.getPlayerParams(winnerId);

        let winnerWithGamePoints = [
          {
            uid: winnerId,
            gamePoints: winnerPoints,
            isWinner: true,
            gongPoints: new Decimal(updatedWinnerParams.gongPoints),
          },
        ];

        // construct and update loser params (lose by discarding)
        newLoserParams = { gamePoints: loserPoints.toFixed(6) };

        gameEngine.updatePlayer(winnerNewParams.discardBy, newLoserParams);
        console.log("[winFromOthers] newLoserParams:", newLoserParams);

        let loserId = winnerNewParams.discardBy;

        // construct params for all nonWinners and get gongPoints
        let nonWinnersParams = gameEngine.getNonWinnersParams(winnerId);
        console.log("[BaseSet endGame] nonWinnersParams from GameEngine", nonWinnersParams);
        console.log("[BaseSet endGame] nonWinnersParams length", nonWinnersParams.length);

        let nonWinnerswithGamePoints = nonWinnersParams.map((player) => {
          let playerItem;

          if (player.id === loserId) {
            // 出銃 loser
            playerItem = {
              uid: player.id,
              gamePoints: new Decimal(newLoserParams.gamePoints),
              isWinner: false,
              gongPoints: new Decimal(player.gongPoints),
            };
          } else {
            // neutral players
            playerItem = {
              uid: player.id,
              gamePoints: new Decimal(player.gamePoints),
              isWinner: false,
              gongPoints: new Decimal(player.gongPoints),
            };
          }

          return playerItem;
        });

        console.log("[BaseSet endGame] nonWinnersParams (after loser gamePoints)", nonWinnerswithGamePoints);

        // concat all players params
        let playersWithPoints = [...winnerWithGamePoints, ...nonWinnerswithGamePoints];

        // calculate gamePoint commission and gong points commission
        let gamePointCommission = new Decimal(0);
        // let gongPointCommission = new Decimal(0);

        playersWithPoints.forEach((player) => {
          if (new Decimal(player.gamePoints).gt(0)) {
            gamePointCommission = gameEngine.houseCommission.times(new Decimal(player.gamePoints)).plus(gamePointCommission);
            player["netGamePoints"] = new Decimal(1).minus(gameEngine.houseCommission).times(new Decimal(player.gamePoints));
          } else {
            player["netGamePoints"] = new Decimal(player.gamePoints);
          }

          player["netGongPoints"] = new Decimal(player.gongPoints);

          // if (new Decimal(player.gongPoints).gt(0)) {
          //   gongPointCommission = gameEngine.houseCommission.times(new Decimal(player.gongPoints)).plus(gongPointCommission);
          //   player["netGongPoints"] = new Decimal(1).minus(gameEngine.houseCommission).times(new Decimal(player.gongPoints));
          // } else {
          //   player["netGongPoints"] = new Decimal(player.gongPoints);
          // }
        });

        // TODO: add points to firestore too.

        let gameResult = {
          gameMode: gameEngine.gameMode,
          priceMode: gameEngine.priceMode,
          roomRef: gameEngine.roomRef,
          roomId: gameEngine.roomId,
          playersWithPoints: playersWithPoints,
          winnerId: winnerId,
          isGameDrawn: false,
          gamePointCommission,
          // gongPointCommission,
        };

        console.log("gameResult to send to API", gameResult);

        let latestIdToken = await gameEngine.firebaseAuth.currentUser.getIdToken(true);
        let result = await postGameResult(gameResult, latestIdToken);
        console.log("post game result", result);
        // await gameEngine.finishGame(winnerNewParams.huReason);
        gameEngine.endGame();
      } else if (winnerNewParams.huReason === "selfDrawn") {
        // add prize horse logic here
        await gameEngine.finishGame(winnerNewParams.huReason);

        const wall = gameEngine.gameParams?.wall;

        let horse1, horse2, horse3;
        let matched, horseMultiple;

        if (wall.length < 3) {
          // case when wall not enough to draw for prize horses
          matched = [{ matched: true }, { matched: true }, { matched: true }];
          horseMultiple = 4;
        } else {
          let horse1 = wall.shift();
          let horse2 = wall.shift();
          let horse3 = wall.shift();

          let prizeHorses = [horse1, horse2, horse3];
          let prizeHorsesWithVisual = gameEngine.pak.getVisualsOf(prizeHorses);

          let winnerParams = gameEngine.getPlayerParams(winnerId);
          let winnerSeat = winnerParams.seat;
          let prizeList;

          switch (winnerSeat) {
            case 0:
              // East Wind
              // [
              //   "East",
              //   "1 tong", "5 tong", "9 tong",
              //   "1 tiao", "5 tiao", "9 tiao",
              //   "1 wan", "5 wan", "9 wan",
              // ]
              prizeList = [27, 18, 22, 26, 9, 13, 17, 0, 4, 8];
              break;
            case 1:
              // South Wind
              // [
              //   "South",
              //   "2 tong", "6 tong",
              //   "2 tiao", "6 tiao",
              //   "2 wan", "6 wan",
              //   "红中", "发财", "白板"
              // ]
              prizeList = [28, 19, 23, 10, 14, 1, 5, 31, 32, 33];
              break;
            case 2:
              // West Wind
              // [
              //   "West",
              //   "3 tong", "7 tong",
              //   "3 tiao", "7 tiao",
              //   "3 wan", "7 wan",
              //   "红中", "发财", "白板"
              // ]

              prizeList = [29, 20, 24, 11, 15, 2, 6, 31, 32, 33];
              break;
            case 3:
              // North Wind
              // [
              //   "North",
              //   "4 tong", "8 tong",
              //   "4 tiao", "8 tiao",
              //   "4 wan", "8 wan",
              //   "红中", "发财", "白板"
              // ]
              prizeList = [30, 21, 25, 12, 16, 3, 7, 31, 32, 33];
              break;

            default:
              break;
          }

          matched = prizeHorsesWithVisual.map((tile) => {
            return {
              ...tile,
              matched: prizeList.includes(tile.visualCardIndex),
            };
          });

          console.log("[BaseSet endGame] matchedResults", matched);
          horseMultiple = matched.filter((horse) => horse.matched).length + 1;
        }

        console.log("[BaseSet endGame] horseMultiple", horseMultiple);

        gameEngine.updateGameParams({ wall, lastPlay: null, prizeHorses: matched, horseMultiple });
        gameEngine.updatePlayer(winnerId, { prizeHorses: matched, horseMultiple });

        winnerPoints = specialPrice.times(horseMultiple).times(3);
        loserPoints = specialPrice.times(horseMultiple).times(-1);

        newWinnerParams = {
          gamePoints: winnerPoints.toFixed(6),
          ...winnerNewParams,
          winner: true,
          netGamePoints: new Decimal(1).minus(gameEngine.houseCommission).times(winnerPoints).toFixed(6),
        };
        gameEngine.updatePlayer(winnerId, newWinnerParams);

        console.log(
          "[selfDrawn] winnerPoints, loserPoints, specialPrice, winnerNewParams, newWinnerParams:",
          winnerPoints,
          loserPoints,
          specialPrice,
          winnerNewParams,
          newWinnerParams
        );

        let updatedWinnerParams = gameEngine.getPlayerParams(winnerId);

        let winnerWithGamePoints = [
          {
            uid: winnerId,
            gamePoints: winnerPoints,
            isWinner: true,
            gongPoints: new Decimal(updatedWinnerParams.gongPoints),
          },
        ];

        // construct and update loser params
        newLoserParams = { gamePoints: loserPoints.toFixed(6) };

        let nonWinnersParams = gameEngine.getNonWinnersParams(winnerId);
        console.log("[BaseSet endGame] [self-drawn] nonWinnersParams", nonWinnersParams);
        console.log("[BaseSet endGame] [self-drawn] nonWinnersParams", nonWinnersParams.length);

        nonWinnersParams.forEach((item, index) => {
          gameEngine.updatePlayer(item.id, newLoserParams);
        });

        let nonWinnerswithGamePoints = nonWinnersParams.map((player) => {
          let playerItem;

          playerItem = {
            uid: player.id,
            gamePoints: new Decimal(newLoserParams.gamePoints),
            isWinner: false,
            gongPoints: new Decimal(player.gongPoints),
          };
          return playerItem;
        });

        console.log("[BaseSet endGame] nonWinnersParams (after loser gamePoints)", nonWinnerswithGamePoints);

        let playersWithPoints = [...winnerWithGamePoints, ...nonWinnerswithGamePoints];

        // calculate gamePoint commission and gong points commission
        let gamePointCommission = new Decimal(0);
        // let gongPointCommission = new Decimal(0);

        playersWithPoints.forEach((player) => {
          if (new Decimal(player.gamePoints).gt(0)) {
            gamePointCommission = gameEngine.houseCommission.times(new Decimal(player.gamePoints)).plus(gamePointCommission);
            player["netGamePoints"] = new Decimal(1).minus(gameEngine.houseCommission).times(new Decimal(player.gamePoints));
          } else {
            player["netGamePoints"] = new Decimal(player.gamePoints);
          }

          player["netGongPoints"] = new Decimal(player.gongPoints);

          // if (new Decimal(player.gongPoints).gt(0)) {
          //   gongPointCommission = gameEngine.houseCommission.times(new Decimal(player.gongPoints)).plus(gongPointCommission);
          //   player["netGongPoints"] = new Decimal(1).minus(gameEngine.houseCommission).times(new Decimal(player.gongPoints));
          // } else {
          //   player["netGongPoints"] = new Decimal(player.gongPoints);
          // }
        });

        // TODO: add points to firestore too.

        // construct a full game result object

        let gameResult = {
          gameMode: gameEngine.gameMode,
          priceMode: gameEngine.priceMode,
          roomRef: gameEngine.roomRef,
          roomId: gameEngine.roomId,
          playersWithPoints: playersWithPoints,
          winnerId: winnerId,
          isGameDrawn: false,
          prizeHorsesMatched: matched,
          horseMultiple: horseMultiple,
          gamePointCommission,
          // gongPointCommission,
        };

        let latestIdToken = await gameEngine.firebaseAuth.currentUser.getIdToken(true);
        let result = await postGameResult(gameResult, latestIdToken);
        console.log("post game result", result);

        gameEngine.endGame();
      }

      // update data for other non-winners
    } else {
      console.log("in Draw game");
      let allPlayerParams = gameEngine.getAllPlayerParams();

      let playersWithPoints = allPlayerParams?.map((player) => {
        let playerItem;

        playerItem = {
          uid: player.id,
          gamePoints: new Decimal(player.gamePoints),
          isWinner: false,
          gongPoints: new Decimal(player.gongPoints),
        };
        return playerItem;
      });

      // calculate gamePoint commission and gong points commission
      let gamePointCommission = new Decimal(0);
      // let gongPointCommission = new Decimal(0);

      playersWithPoints?.forEach((player) => {
        // player["netGamePoints"] = player.gamePoints
        player["netGamePoints"] = new Decimal(0);
        player["netGongPoints"] = new Decimal(player.gongPoints);

        // if (new Decimal(player.gongPoints).gt(0)) {
        //   gongPointCommission = gameEngine.houseCommission.times(new Decimal(player.gongPoints)).plus(gongPointCommission);
        //   player["netGongPoints"] = new Decimal(1).minus(gameEngine.houseCommission).times(new Decimal(player.gongPoints));
        // } else {
        //   player["netGongPoints"] = new Decimal(player.gongPoints);
        // }
      });
      // no update on player points, but still update game result to firestore
      // TODO: add points to firestore too.

      let gameResult = {
        gameMode: gameEngine.gameMode,
        priceMode: gameEngine.priceMode,
        roomRef: gameEngine.roomRef,
        roomId: gameEngine.roomId,
        playersWithPoints: playersWithPoints,
        winnerId: null,
        isGameDrawn: true,
        gamePointCommission,
        // gongPointCommission,
      };

      let latestIdToken = await gameEngine.firebaseAuth.currentUser.getIdToken(true);
      let result = await postGameResult(gameResult, latestIdToken);
      console.log("post game result", result);

      gameEngine.endGame();
    }

    // logic to change to next wind
    // const wind = ((gameEngine.gameParams?.wind || 0) + 1) % 4;
    // const newGameParams = { lastPlay: null, wind };
    // gameEngine.updateGameParams(newGameParams);
  };

  //* Helper Setters
  resetSkips = (gameEngine: ActionParams["gameEngine"]) => {
    // gameEngine.players.filter((p) => !p.spectator).forEach((player) => gameEngine.updatePlayer(player.id, { skipped: false }));
    gameEngine.players.forEach((player) => gameEngine.updatePlayer(player.id, { skipped: false }));
  };

  removeLastPlayedTile = ({ gameEngine }: ActionParams) => {
    const lastPlay = gameEngine.gameParams?.lastPlay;

    const playedPlayerParams = gameEngine.getPlayerParams(lastPlay.by);
    const playedTiles = playedPlayerParams.playedTiles.filter((t: Card) => t.id !== lastPlay.card.id);
    const newPlayedPlayerParams = { ...playedPlayerParams, playedTiles };
    gameEngine.updatePlayer(lastPlay.by, newPlayedPlayerParams);
  };

  createOpenHandWith = (playerParams: any, meld: any[]) => {
    // const openHand = { ...playerParams.openHand };
    const openHand = [...playerParams.openHand];

    console.log("[createOpenHandWith] openHand", openHand);
    console.log("[createOpenHandWith] oVal(playerParams.openHand)", oVal(playerParams.openHand));

    // TODO: check if meld exists to avoid duplicate

    openHand[oVal(playerParams.openHand).length] = meld;
    return openHand;
  };

  //* Helper Getters
  hasAFullHand = ({ closedHand, openHand }: any) => {
    return closedHand.length + oVal(openHand).length * 3 >= this.FULL_HAND_SIZE;
  };

  getOpenHand = (player: any) => {
    return player.openHand ? oVal(player.openHand) : [];
  };

  getAvailableActions = ({ executingPlayerId, gameEngine }: ActionParams) => {
    const ignoreTheseActions = ["Skip", "Draw"];
    const searchActions = this.rules.playerActions.filter((a) => !ignoreTheseActions.includes(a.name));

    const availableActions: Action[] = [];
    searchActions?.forEach((action) => {
      const isAvailable = action.isAvailable({
        executingPlayerId,
        gameEngine,
      });
      if (isAvailable) availableActions.push(action);
    });

    return availableActions;
  };

  onlyMyActionOrEveryoneSkipped = ({ executingPlayerId, gameEngine }: ActionParams) => {
    // console.log("calling onlyMyActionOrEveryoneSkipped()");

    const players: any[] = gameEngine.playerParams as any;
    if (!players) return null;

    const playersReady = players
      // get other players but executing one
      .filter((p) => p.id !== executingPlayerId)
      .map((p) => {
        const actions = this.getAvailableActions({
          executingPlayerId: p.id,
          gameEngine,
        });
        const skipped = p.skipped;
        // return true if skipped or have no actions
        return actions.length === 0 || skipped;
      });

    // console.log("[onlyMyActionOrEveryoneSkipped] playersReady: ", playersReady);

    //sum ready players and check if all players are ready
    return sum(playersReady) === players.length - 1;
  };

  canAnyoneElse = (actionName: string, { executingPlayerId, gameEngine }: ActionParams) => {
    const players: any[] = gameEngine.playerParams as any;
    if (!players) return null;

    const action = this.rules.playerActions.find((a) => a.name === actionName);
    const playersThatCan = players
      .filter((p) => p.id !== executingPlayerId)
      .map((p) => {
        const isAvailable = action?.isAvailable({
          executingPlayerId: p.id,
          gameEngine,
        });
        const skipped = p.skipped;
        return isAvailable && !skipped;
      });
    return sum(playersThatCan) > 0;
  };

  whoElseCan = (actionName: string, { executingPlayerId, gameEngine }: ActionParams) => {
    const players: any[] = gameEngine.playerParams as any;
    if (!players) return null;

    const action = this.rules.playerActions.find((a) => a.name === actionName);
    const playersThatCan = players
      .filter((p) => p.id !== executingPlayerId)
      .map((p) => {
        const isAvailable = action?.isAvailable({
          executingPlayerId: p.id,
          gameEngine,
        });
        const skipped = p.skipped;

        return {
          ...p,
          [`can${actionName}`]: isAvailable && !skipped,
        };
      });
    return playersThatCan;
  };

  getWinner = (gameEngine: GameEngine) => {
    const players: any[] = gameEngine.playerParams as any;
    if (!players) return null;

    const winner = players.filter((p) => p.winner);
    return winner;
  };

  //* Helpers Filters
  firstOne = (
    tile: {
      value: number | string;
      params?: { suit?: string };
    },
    index: number,
    self: Tile[]
  ) => index === self.findIndex((t) => t.value === tile.value && t.params?.suit === tile.params?.suit);

  valueInArray = (value: number | string, array: Tile[]) => {
    return array.findIndex((t) => t.value === value) > -1;
  };

  matchInSuit = (tile: Card) => {
    return (t: Card) => t.params.suit === tile.params.suit;
  };

  matchValueWithinTwo = (tile: Card) => {
    const lowerBound = Number(tile.value) - 2;
    const upperBound = Number(tile.value) + 2;
    return (t: Card) => Number(t.value) >= lowerBound && Number(t.value) <= upperBound;
  };

  matchInValueAndSuit = (tile: { value: number | string; params?: { suit?: string } }) => {
    return (t: Card) => t.value === tile.value && t.params.suit === tile.params?.suit;
  };

  firstMatchInValueAndSuit = (tile: { value: number | string; params?: { suit?: string } }) => {
    return (t: Card, index: number, self: Tile[]) =>
      t.value === tile.value && t.params.suit === tile.params?.suit && this.firstOne(tile, index, self);
  };

  matchStaircaseStartingAt = (startTile: Tile) => (t: Tile, i: number, self: Tile[]) =>
    this.firstMatchInValueAndSuit({
      value: startTile.value,
      params: { suit: startTile.params?.suit },
    })(t, i, self) ||
    this.firstMatchInValueAndSuit({
      value: Number(startTile.value) + 1,
      params: { suit: startTile.params?.suit },
    })(t, i, self) ||
    this.firstMatchInValueAndSuit({
      value: Number(startTile.value) + 2,
      params: { suit: startTile.params?.suit },
    })(t, i, self);
}

export default Majiang;

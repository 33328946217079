import React, { useContext, useEffect, useState, useRef } from "react";
import Box from "ui-box";

import { useAuth, useGameplay } from "App";
import { Orientation } from "components/Seat";
import Centerpiece from "components/Centerpiece";

import Player from "./Player";
import AnimationStage from "./AnimationStage";
import HuAnimation from "./HuAnimation";

import { DELAY_START_TIME } from "newEngine/newEngine";

import "./Table.css";
import { WindChinese } from "components/ZhongWenHelpers";
import { getVisualsOf } from "newEngine/cards";
import { oKey } from "utils";

import queryString from "query-string";
import { navigate, useLocation } from "@reach/router";
// import { getMySeat } from "newEngine/helpers";

export const getOrientation = (seat, mySeat) => {
  const baseSeat = mySeat || 0;
  const ori = {
    0: Orientation.B,
    1: Orientation.R,
    2: Orientation.T,
    3: Orientation.L,
  };
  let index = (seat - baseSeat) % 4;
  index = index >= 0 ? index : index + 4;
  return ori[index];
};

const Table = ({ players, mode, roomData }) => {
  const { currentUser } = useAuth();
  const { gameData, newGE } = useGameplay();

  let gameStarted = false;
  let mySeat;

  const myUid = currentUser.uid;

  let ori = [Orientation.R, Orientation.T, Orientation.L];

  let centerpieceData;
  let wind;

  // console.log("[Table] players", players);

  if (gameData && newGE) {
    // console.log("new GE", newGE);
    gameStarted = newGE?.gameStarted;
    mySeat = newGE?.getMySeat(myUid, newGE.playerParams);

    // console.log("Table players", players);

    if (newGE.gameStarted) {
      centerpieceData = newGE.playerParams.map((player, index) => {
        // console.log("[centerpiece]: player", player.id, player);

        let orientation;
        let playedTiles;
        let isTurn;

        if (mode === "waiting") {
          if (player.id === currentUser.uid) {
            orientation = Orientation.B;
          } else {
            orientation = ori.shift();
          }
        } else {
          orientation = getOrientation(player.seat, mySeat);
        }
        playedTiles = getVisualsOf(player.playedTiles);
        // isTurn = GE.gameStarted ? GE.isPlayersTurn(player.id) : false;
        isTurn = newGE?.isPlayersTurn(player.id);

        // console.log("[centerpieceData]: got centerpieceData: playedTiles", playedTiles);

        return { ...player, orientation, playedTiles, isTurn };
      });
    } else {
      centerpieceData = players.map((player, index) => {
        // console.log("[centerpiece]: player", player.id, player);

        let orientation;
        let playedTiles;
        let isTurn;

        if (mode === "waiting") {
          if (player.id === currentUser.uid) {
            orientation = Orientation.B;
          } else {
            orientation = ori.shift();
          }
        } else {
          orientation = getOrientation(player.seat, mySeat);
        }
        playedTiles = getVisualsOf(player.playedTiles);
        // isTurn = GE.gameStarted ? GE.isPlayersTurn(player.id) : false;
        isTurn = false;

        // console.log("[centerpieceData]: got centerpieceData: playedTiles", playedTiles);

        return { ...player, orientation, playedTiles, isTurn };
      });
    }

    // console.log("[CenterpieceData] centerpieceData", centerpieceData);

    wind = oKey(WindChinese)[newGE?.gameParams?.wind || 0];

    // console.log("roomData", roomData);
  }
  // const { gameStarted } = roomData;
  // console.log("[Table] players", players);
  // console.log("[Table] User", User.uid);

  const [delayStartSeconds, setDelayStartSeconds] = useState(DELAY_START_TIME);
  const [hasShownCountdown, setHasShownCountdown] = useState(false);
  const interval = useRef();

  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  let rejoin = false;

  if (searchParams.rejoin === "true") {
    rejoin = true;
  }

  function delayStart() {
    let timesRun = 0;
    const delayInterval = setInterval(() => {
      timesRun += 1;
      if (timesRun === DELAY_START_TIME) {
        clearInterval(delayInterval);
        setDelayStartSeconds(0);
      }
      setDelayStartSeconds((prev) => {
        // console.log("[Table] prev", prev);
        return prev - 1;
      });
    }, 1000);
  }

  useEffect(() => {
    // console.log("[Table] Triggering gameStart");
    if (newGE?.gameStarted === true && !rejoin) {
      // console.log("[Table] gameStarted === true");
      // console.log("[Table] delayStartSeconds", delayStartSeconds);

      delayStart();
      // setHasShownCountdown(true);
    }
  }, [newGE?.gameStarted]);

  if (!players) return null;

  return (
    <Box className="Table">
      {newGE?.roomData && (
        <Centerpiece
          roomData={newGE.roomData}
          data={centerpieceData}
          wind={wind}
          tilesRemaining={newGE?.gameParams?.wall?.length}
          lastPlayed={newGE?.gameParams?.lastPlay}
        />
      )}
      {newGE?.gameStarted
        ? newGE?.playerParams.map((player, index) => {
            // if (newGE?.roomData) {
            return (
              <Player
                key={`${player.id}-${index}`} //
                player={player}
                roomData={newGE.roomData}
                data={centerpieceData}
                mode={mode}
                mySeat={mySeat}
                delayStartSeconds={delayStartSeconds}
                rejoin={rejoin}
              />
            );
            // }
          })
        : players.map((player, index) => {
            if (newGE?.roomData) {
              return (
                <Player
                  key={`${player.id}-${index}`} //
                  player={player}
                  roomData={newGE.roomData}
                  data={centerpieceData}
                  mode={mode}
                  mySeat={mySeat}
                  delayStartSeconds={delayStartSeconds}
                  rejoin={rejoin}
                />
              );
            }
          })}

      {delayStartSeconds > 0 &&
        delayStartSeconds < DELAY_START_TIME && ( //
          <div className="gameStartCountdown">{delayStartSeconds}</div>
        )}

      <AnimationStage />
      {/* <HuAnimation /> */}
    </Box>
  );
};

export default Table;

import { VisualDeck } from "engine/CardPakTypes";
import yiwan from "assets/tiles/yiwan.svg";
import erwan from "assets/tiles/erwan.svg";

import sanwan from "assets/tiles/sanwan.svg";
import siwan from "assets/tiles/siwan.svg";
import wuwan from "assets/tiles/wuwan.svg";
import liuwan from "assets/tiles/liuwan.svg";
import qiwan from "assets/tiles/qiwan.svg";
import bawan from "assets/tiles/bawan.svg";
import jiuwan from "assets/tiles/jiuwan.svg";

import yaoji from "assets/tiles/yaoji.svg";
import ertiao from "assets/tiles/ertiao.svg";
import santiao from "assets/tiles/santiao.svg";
import sitiao from "assets/tiles/sitiao.svg";
import wutiao from "assets/tiles/wutiao.svg";
import liutiao from "assets/tiles/liutiao.svg";
import qitiao from "assets/tiles/qitiao.svg";
import batiao from "assets/tiles/batiao.svg";
import jiutiao from "assets/tiles/jiutiao.svg";

import dabing from "assets/tiles/dabing.svg";
import ertong from "assets/tiles/ertong.svg";
import santong from "assets/tiles/santong.svg";
import sitong from "assets/tiles/sitong.svg";
import wutong from "assets/tiles/wutong.svg";
import liutong from "assets/tiles/liutong.svg";
import qitong from "assets/tiles/qitong.svg";
import batong from "assets/tiles/batong.svg";
import jiutong from "assets/tiles/jiutong.svg";

import dongfeng from "assets/tiles/dongfeng.svg";
import nanfeng from "assets/tiles/nanfeng.svg";
import xifeng from "assets/tiles/xifeng.svg";
import beifeng from "assets/tiles/beifeng.svg";

import hongzhong from "assets/tiles/hongzhong.svg";
import facai from "assets/tiles/facai.svg";
import baiban from "assets/tiles/baiban.svg";

export const DianXinDefault: VisualDeck = {
  id: "dx-traditional",
  name: "DianXin Base Tile Pack",
  // visualCards: [
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fyiwan.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Ferwan.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fsanwan.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fsiwan.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fwuwan.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fliuwan.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fqiwan.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fbawan.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fjiuwan.svg?alt=media",

  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fyaoji.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fertiao.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fsantiao.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fsitiao.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fwutiao.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fliutiao.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fqitiao.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fbatiao.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fjiutiao.svg?alt=media",

  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fdabing.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fertong.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fsantong.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fsitong.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fwutong.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fliutong.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fqitong.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fbatong.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fjiutong.svg?alt=media",

  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fdongfeng.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fnanfeng.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fxifeng.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fbeifeng.svg?alt=media",

  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fhongzhong.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Ffacai.svg?alt=media",
  //   "https://firebasestorage.googleapis.com/v0/b/meta-mahjong-92768.appspot.com/o/mj-tiles%2Fbaiban.svg?alt=media",
  // ],
  visualCards: [
    yiwan,
    erwan,
    sanwan,
    siwan,
    wuwan,
    liuwan,
    qiwan,
    bawan,
    jiuwan,

    yaoji,
    ertiao,
    santiao,
    sitiao,
    wutiao,
    liutiao,
    qitiao,
    batiao,
    jiutiao,

    dabing,
    ertong,
    santong,
    sitong,
    wutong,
    liutong,
    qitong,
    batong,
    jiutong,

    dongfeng,
    nanfeng,
    xifeng,
    beifeng,

    hongzhong,
    facai,
    baiban,
  ],

  // visualCards: [
  //   "/DianXin/yiwan.svg",
  //   "/DianXin/erwan.svg",
  //   "/DianXin/sanwan.svg",
  //   "/DianXin/siwan.svg",
  //   "/DianXin/wuwan.svg",
  //   "/DianXin/liuwan.svg",
  //   "/DianXin/qiwan.svg",
  //   "/DianXin/bawan.svg",
  //   "/DianXin/jiuwan.svg",

  //   "/DianXin/yaoji.svg",
  //   "/DianXin/ertiao.svg",
  //   "/DianXin/santiao.svg",
  //   "/DianXin/sitiao.svg",
  //   "/DianXin/wutiao.svg",
  //   "/DianXin/liutiao.svg",
  //   "/DianXin/qitiao.svg",
  //   "/DianXin/batiao.svg",
  //   "/DianXin/jiutiao.svg",

  //   "/DianXin/dabing.svg",
  //   "/DianXin/ertong.svg",
  //   "/DianXin/santong.svg",
  //   "/DianXin/sitong.svg",
  //   "/DianXin/wutong.svg",
  //   "/DianXin/liutong.svg",
  //   "/DianXin/qitong.svg",
  //   "/DianXin/batong.svg",
  //   "/DianXin/jiutong.svg",

  //   "/DianXin/dongfeng.svg",
  //   "/DianXin/nanfeng.svg",
  //   "/DianXin/xifeng.svg",
  //   "/DianXin/beifeng.svg",

  //   "/DianXin/hongzhong.svg",
  //   "/DianXin/facai.svg",
  //   "/DianXin/baiban.svg",
  // ],
};

import "react-phone-number-input/style.css";
// import PhoneInput, { getCountries, getCountryCallingCode } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useContext, useEffect, useState } from "react";
import { useFirebase, useAuth } from "App";
import { RecaptchaVerifier, createUserWithEmailAndPassword, signInWithPhoneNumber } from "firebase/auth";
import { doc, getDoc, getDocsFromServer, collection, addDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { generateToken } from "actions/api";
import { useTranslation } from "react-i18next";

// import { useDocument, useDocumentDataOnce } from "react-firebase-hooks/firestore";

import "./AuthContainer.scss";
import { navigate } from "@reach/router";
import PuffLoader from "react-spinners/PuffLoader";
import PulseLoader from "react-spinners/PulseLoader";

function AuthContainer({ state = "login" }) {
  const { t } = useTranslation();
  const { currentUser, idToken, Auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [user, authLoading, error] = useAuthState(Auth);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState();

  useEffect(() => {
    const verifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible", // or 'normal' or 'compact'
        callback: (response) => {
          // reCAPTCHA solved, you can proceed with phone number verification
        },
      },
      Auth
    );

    verifier.render().then(() => {
      setRecaptchaVerifier(verifier);
    });
  }, []);

  return (
    <>
      <div className="auth-container">
        <div className="gameTitle"></div>
        {authLoading || loading ? (
          <div className="loading-container">
            <PuffLoader color={"#ffffff"} loading={loading} size={180} />
            <div className="typewriter">now loading ...</div>
          </div>
        ) : (
          <div
            className="auth-frame"
            // onClick={() => {
            //   // navigate("/login");
            // }}
          >
            {state === "login" && <Login setLoading={setLoading} recaptchaVerifier={recaptchaVerifier} t={t} />}
            {/* {state === "signup" && <Signup />} */}
          </div>
        )}
      </div>
    </>
  );
}

function Login({ setLoading, recaptchaVerifier, t }) {
  const { Firestore } = useFirebase();

  const { currentUser, idToken, Auth } = useAuth();
  const [formData, setFormData] = useState({ phone: "", password: "" });
  const [phone, setPhone] = useState();
  const [confirmationResult, setConfirmationResult] = useState("");
  const [password, setPassword] = useState();
  const [recaptchaToken, setRecaptchaToken] = useState();
  // const [user, loading, error] = useAuthState(Auth);

  const [verifyCodeSending, setVerifyCodeSending] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  useEffect(() => {
    if (currentUser) {
    }
  }, [currentUser]);

  async function getMjToken(uid) {
    const genTokenRes = await generateToken(uid);
    // console.log("genTokenRes", genTokenRes);
  }

  useEffect(() => {
    //onAuthStateChanged
    if (currentUser) {
      getMjToken(currentUser.uid).catch(console.error);

      // console.log(`user already logged in in Auth`, currentUser);
      let userId = currentUser.uid;
      let phoneNumber = currentUser.phoneNumber;
      setLoading(true);

      if (currentUser.displayName) {
        // console.log(`user already setup profile in`, currentUser);
        navigate("/GameModeLobby");
        // navigate("/");
        setLoading(false);
      } else {
        // console.log("user reg in Auth (firestore) but not yet setup profile");
        createOrUpdateFirestoreUser(userId, phoneNumber);
        navigate("/ProfileSetup");
        setLoading(false);
      }

      // const userRef = doc(Firestore, "users", userId);
      // getDoc(userRef).then((doc) => {
      //   let firestoreUserInfo = doc.data();

      //   console.log("firestoreUserInfo", firestoreUserInfo);

      //   if (firestoreUserInfo) {
      //     if (firestoreUserInfo?.profileSetup) {
      //       console.log(`user already setup profile in`, user);
      //       navigate("/GameModeLobby");
      //       setLoading(false);
      //     } else {
      //       console.log("user in firestore but not yet setup profile");
      //       navigate("/ProfileSetup");
      //       setLoading(false);
      //     }
      //   }
      // });
    } else {
      // console.log("no user logged in");
      setLoading(false);
      // setLoading(false);
    }
  }, [currentUser]);

  async function createOrUpdateFirestoreUser(userId, phoneNumber) {
    try {
      // setting up user profile in Firestore
      const docRef = await setDoc(
        doc(Firestore, "users", userId),
        {
          createdAt: serverTimestamp(),
          phoneNumber: phoneNumber,
        },
        { merge: true }
      );
    } catch (e) {
      console.error("Error adding document: ", e.message);
    }
  }

  const sendVerificationCode = async (event) => {
    event.preventDefault();
    // console.log("send verification code clicked");
    setVerifyCodeSending(true);

    // const appVerifier = await window.recaptchaVerifier;
    const appVerifier = recaptchaVerifier;
    const phoneStr = `+${phone}`;

    // console.log("phoneStr", phoneStr);

    try {
      const confirmationResult = await signInWithPhoneNumber(Auth, phoneStr, appVerifier);
      // console.log("confirmationResult", confirmationResult);

      setConfirmationResult(confirmationResult);
    } catch (e) {
      console.error({ e });

      setPhoneError(firebaseErrorCodeMapping(e.code));
    }

    setVerifyCodeSending(false);
    // window.confirmationResult = confirmationResult;
  };

  function firebaseErrorCodeMapping(code) {
    let display = "";

    switch (code) {
      case "auth/invalid-phone-number":
        // display = "Invalid phone number format";
        display = "電話號碼格式錯誤";
        // "电话号码格式错误";
        // t("auth_container.phone_format_error");

        break;
      case "auth/invalid-verification-code":
        display = "驗證碼無效";
        // "验证码无效";
        // t("auth_container.verification_code_error");

        break;

      default:
        display = "抱歉，出了點問題，請稍後重試";
        // "抱歉，出了点问题，请稍后重试";
        // t("auth_container.sorry_please_try_again");
        break;
    }

    return display;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    // console.log("formData", formData);
    // console.log("phone", phone);

    setLoginLoading(true);

    // verifying firebase sms code
    try {
      const res = await confirmationResult.confirm(formData.verificationCode);

      // console.log("res", res);

      if (res) {
        let userId = res.user.uid;

        const docRef = await setDoc(
          doc(Firestore, "users", userId),
          {
            userId,
            profileSetup: false,
            lastLoginAt: serverTimestamp(),
          },
          { merge: true }
        );

        // console.log("created new user in firestore", docRef);
      }
    } catch (e) {
      setLoginError(firebaseErrorCodeMapping(e.code, t));
      console.log({ e });
      // setLoginError({e});
    }

    setLoginLoading(false);
  };

  return (
    <>
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <div className="form-title">登入你的 </div>
            <div className="subtitle">METAVERSE CASINO</div>
            <div className="phone-row">
              <PhoneInput
                country={"cn"}
                value={phone}
                onChange={(input) => {
                  setPhoneError("");
                  setPhone(input);
                }}
                // enableSearch={true}
                placeholder={t("auth_container.please_enter_phone_number")}
                containerClass={"phone-container"}
                inputClass={"phone-input-class"}
                buttonClass={"phone-dropdown-class"}
                dropdownClass={"phone-dropdown-class"}
                onlyCountries={["hk", "tw", "sg", "cn", "mo", "gb", "th", "ca", "au", "nz", "my"]}
              />
              <button className="send-code-button" onClick={sendVerificationCode}>
                {verifyCodeSending ? (
                  <PulseLoader color={"#ffffffae"} loading={verifyCodeSending} size={8} />
                ) : (
                  <>
                    {/* 发送验证码 */}
                    {t("auth_container.send_verification_code")}
                  </>
                )}
              </button>
            </div>
            <div className={`phoneError`}>{phoneError}</div>

            <input
              className="verification-code-input auth-input"
              name="verificationCode"
              type="number"
              placeholder={t("auth_container.please_enter_verification_code")}
              onChange={(e) => handleChange(e)}
            />

            <button className="login-btn" type="submit" disabled={confirmationResult === "" ? true : false}>
              {loginLoading ? (
                <PulseLoader color={"#ffffffae"} loading={loginLoading} size={8} />
              ) : (
                <>
                  {/* 立即登入 */}
                  {t("auth_container.signin")}
                </>
              )}
            </button>
            <div className="loginError">{loginError}</div>
            {/* <div className="sub-container">
              <div className="signup-link">注册新账号</div>
              <div className="forgot-pw-link">忘记密码</div>
            </div> */}
          </div>
        </form>
      </div>
    </>
  );
}

export default AuthContainer;

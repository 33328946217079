import React, { useContext, useEffect, useState, useCallback } from "react";
import { useAuth, useGameplay } from "App";
import { navigate } from "@reach/router";
import { useAuthState } from "react-firebase-hooks/auth";
import { priceList } from "game-constants";
import { config } from "config";
import { Decimal } from "decimal.js";
import { getUserBalance, tryJoiningRoom } from "actions/api";
import { getVisualsOf } from "newEngine/cards";
import _ from "lodash";
import "./GameEnd.scss";
import HuAnimation from "../InGame/components/HuAnimation";
import { useTranslation } from "react-i18next";

const GameEnd = (props) => {
  const { t } = useTranslation();
  const { currentUser, idToken, Auth } = useAuth();
  const { gameData, newGE, setGameplayConfig } = useGameplay();
  const [user, loading, error] = useAuthState(Auth);

  const [playersData, setPlayersData] = useState<any>();
  const [winner, setWinner] = useState<any>();

  const players = newGE?.playerParams;
  const matchedResults = newGE?.gameParams?.prizeHorses;
  const horseMultiple = newGE?.gameParams?.horseMultiple;
  const finishAnimationToShow = newGE?.gameParams?.finishAnimationToShow;

  const [userBalanceObj, setUserBalanceObj] = useState<any>({});
  const [endPage, setEndPage] = useState("summary");
  const [isTryingToJoinRoom, setIsTryingToJoinRoom] = useState(false);

  const gameMode = props.gameMode;
  const priceMode = props.priceMode;
  const roomId = props.roomId;

  const houseCommission = newGE?.houseCommission;

  // let showPrizeHorse = false;

  function getHand(player) {
    const closedHand = getVisualsOf(player?.closedHand).sort((a: any, b: any) => {
      if (a?.justDrawn) return 1;
      if (a?.params?.suit > b?.params.suit) return -1;
      if (a?.params?.suit < b?.params.suit) return 1;
      if (a?.value > b?.value) return 1;
      if (a?.value < b?.value) return -1;
      return 1;
    });

    const openHand = transformOpenHand(player?.openHand);
    // console.log("openHand", openHand);

    if (openHand) {
      return [...closedHand, ...openHand];
    } else {
      return [...closedHand];
    }
  }

  function transformOpenHand(openHand) {
    if (!openHand) return;

    let openHandArray: any[] = [];

    openHand.forEach((meld: any) => {
      openHandArray = openHandArray.concat(Object.values(meld));
    });

    openHandArray = openHandArray.filter((obj) => obj !== undefined);

    return getVisualsOf(openHandArray);
  }

  useEffect(() => {
    if (currentUser) {
      // getCurrentRoomData().catch(console.error);

      // console.log("attaching gameplay listener");
      // Dynamically set the collection and document ID
      setGameplayConfig(`${gameMode}-${priceMode}`, roomId);
    }
  }, [currentUser]);

  useEffect(() => {
    let playersWithHand = players?.map((player) => {
      return {
        ...player,
        finalHand: getHand(player),
      };
    });

    const hasWinner = !!playersWithHand?.find((player) => player.winner);

    let isWinner;
    let winner;
    let nonWinners;

    if (hasWinner) {
      // console.log("finishAnimationToShow", finishAnimationToShow);

      if (finishAnimationToShow === "selfDrawn" || gameMode === "SELF_DRAWN") {
        setEndPage("prizeHorse");
      }
      // isWinner = winner.id == GE.uid;
      winner = playersWithHand?.filter((p) => p.winner)[0];
      // winner["netGamePoints"] = new Decimal(1).minus(houseCommission).times(new Decimal(winner.gamePoints));
      setWinner(winner);

      // console.log("[Gameend] winner", winner);

      nonWinners = playersWithHand?.filter((p) => !p.winner);
      // playersData = [winner, ...nonWinners];
      setPlayersData([winner, ...nonWinners]);
    } else {
      // playersData = playersWithHand;
      setPlayersData(playersWithHand);
    }
    // console.log("playersWithHand", playersWithHand);

    // console.log("playersData", playersData);
    // console.log("playersData after adding urls", playersData);
  }, [newGE]);

  const getBalance = async () => {
    const latestIdToken = localStorage.getItem("mjToken");
    // let latestIdToken = idToken;
    // setLatestIdToken(latestIdToken);

    const userBalanceObj = await getUserBalance(latestIdToken);
    // console.log("got updated userBalanceObj", userBalanceObj);

    setUserBalanceObj(userBalanceObj);
  };

  async function balanceCheckPass(priceMode) {
    // await getBalance().catch(console.error);
    const latestIdToken = localStorage.getItem("mjToken");
    // let latestIdToken = idToken;
    // setLatestIdToken(latestIdToken);

    const userBalanceObj = await getUserBalance(latestIdToken);
    // console.log("got updated userBalanceObj", userBalanceObj);

    let priceModeItem = _.find(priceList, { priceModeId: priceMode });
    // let basePrice = priceModeItem.basePrice;
    let specialPrice = new Decimal(priceModeItem.specialPrice);

    // console.log("priceMode", priceMode);
    // console.log("specialPrice", specialPrice);

    let minEntryAmount = specialPrice.times(config.MIN_BALANCE_THRESHOLD_MULTIPLE);

    // console.log("minEntryAmount", minEntryAmount);
    // console.log("userBalance", userBalanceObj?.availableUsdBalance);

    if (new Decimal(userBalanceObj?.availableUsdBalance).gte(minEntryAmount)) {
      return { balancePass: true, minEntryAmount: null };
    } else {
      return { balancePass: false, minEntryAmount: minEntryAmount.toFixed(2) };
    }
  }

  async function handleBackToLobby() {
    newGE.unsubscribeGameplay();
    navigate("/GameModeLobby");
  }

  async function handleContinue(gameMode, priceMode) {
    // console.log("[handleContinue] GameEnd: gameMode", gameMode);
    // console.log("[handleContinue] GameEnd: priceMode", priceMode);

    newGE.unsubscribeGameplay();

    setIsTryingToJoinRoom(true);

    let { balancePass, minEntryAmount } = await balanceCheckPass(priceMode);
    // await resetGameEngine();

    if (balancePass) {
      // console.log("balancePass is", balancePass);
      // setIsTryingToJoinRoom(true);

      const latestIdToken = localStorage.getItem("mjToken");
      // let latestIdToken = idToken;

      let roomId;
      roomId = await tryJoiningRoom(gameMode, priceMode, latestIdToken);
      // console.log("[GameEnd]: new roomId from Firestore", roomId);

      // roomId = await GE.tryJoiningRoom({ name: user.displayName, id: user.uid }, gameMode, priceMode);
      setIsTryingToJoinRoom(false);

      if (roomId) {
        // console.log(`Successfully joined the room ${roomId}, navigating to the GamePage`);
        navigate(`/${gameMode}/${priceMode}/${roomId}`);
      } else {
        // console.log("no roomId found, redirecting back to lobby");
        // Handle the failure case, maybe show a message to the user
      }
    } else {
      // console.log("balancePass not passed, redirecting ");

      navigate("/GameModeLobby", { state: { minEntry: true, minEntryAmount: minEntryAmount } });
    }
  }

  // console.log("playersData", playersData);

  return (
    <>
      {endPage === "summary" && (
        <div className="GameEnd-container">
          <div className="GameEnd-frame">
            <div className="GameEnd-title"></div>
            <div className="GameEnd-result-container">
              <table className="GameEnd-table">
                <colgroup>
                  <col span={1} style={{ width: "10%" }} />
                  <col span={1} style={{ width: "15%" }} />
                  <col span={1} style={{ width: "55%" }} />
                  <col span={1} style={{ width: "10%" }} />
                  <col span={1} style={{ width: "4%" }} />
                  <col span={1} style={{ width: "4%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>

                    <th className="th-Title">糊</th>
                    <th className="th-Title">杠</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {playersData
                    ? playersData?.map((player, index) => {
                        return (
                          <tr className="GameEnd-result-row" key={`tr-${index}`}>
                            <td className="playerProfile">
                              <div className={`hexagon hexagon1 ${player.winner ? "winner" : "nonwinner"}`}>
                                <div className="hexagon-in1">
                                  {/* <div
                                    className="playerPfp"
                                    style={{
                                      backgroundImage: `url(${player.profileUrl})`,
                                    }}
                                  ></div> */}
                                </div>
                              </div>

                              <div className={`playerProfileFrame ${player.winner ? "winnerFrame" : "normalFrame"}`}></div>
                            </td>
                            <td className="playerName">{player?.username}</td>
                            <td className="playerHand-td">
                              <div className="playerHand">
                                {player?.finalHand?.map((tile) => {
                                  return (
                                    <div
                                      key={tile.id}
                                      className={`tile ${player.finalHand.length > 14 ? "bighand" : ""}`}
                                      style={{
                                        backgroundColor: "white",
                                        backgroundImage: `url(${tile.visual})`,
                                        backgroundSize: "120%",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        marginLeft: 2,
                                        marginRight: 2,
                                        borderRadius: 4,
                                      }}
                                    ></div>
                                  );
                                })}
                              </div>
                            </td>
                            <td className={`playerPointChanges ${player?.gamePoints >= 0 ? "text-green" : "text-red"}`}>
                              {new Decimal(player?.gamePoints || 0).gt(0)
                                ? `+${(new Decimal(player?.netGamePoints || 0) || 0).toFixed(3)}`
                                : (new Decimal(player?.gamePoints || 0) || 0).toFixed(3)}
                            </td>
                            <td className={`playerPointChanges ${player?.gongPoints >= 0 ? "text-green" : "text-red"}`}>
                              {new Decimal(player?.gongPoints || 0).gt(0)
                                ? `+${(new Decimal(player?.gongPoints || 0) || 0).toFixed(3)}`
                                : (new Decimal(player?.gongPoints || 0) || 0).toFixed(3)}
                            </td>
                            <td className="playerPointChangesReason"></td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>

              {/* 3 other rows */}
              <div className="GameEnd-actionButton">
                <div
                  className="btn btn-backToLobby"
                  onClick={() => {
                    // GE.returnToLobby();
                    handleBackToLobby();
                  }}
                >
                  {/* 退出 */}
                  {t("game_end.exit")}
                </div>
                <div
                  className="btn btn-continue"
                  onClick={() => {
                    handleContinue(gameMode, priceMode);
                  }}
                >
                  {/* 继续 */}
                  {t("game_end.continue")}
                </div>
              </div>
            </div>
          </div>

          {isTryingToJoinRoom && (
            <div className="matchingOtherPlayersPrompt">
              <div className="title">
                {/* 正在连接其他玩家... */}
                {t("game_end.connecting_other_players")}
              </div>
            </div>
          )}
        </div>
      )}

      {/* {finishAnimationToShow === "selfDrawn" && winner && ( */}
      {endPage === "prizeHorse" && (
        <>
          <div className="PrizeHorse-container">
            <div className="PrizeHorse-frame">
              <div className="PrizeHorse-title">
                {/* 抽奖马 */}
                {t("game_end.prize_horse_draw")}
              </div>
              <div className="PrizeHorse-result-container">
                {matchedResults &&
                  matchedResults.map((tile, index) => {
                    return (
                      <div className="tile-container" key={`tile-container-${index}`}>
                        <div
                          key={index}
                          className={`tile ${tile.matched ? "matched" : ""}`}
                          style={{
                            backgroundColor: "white",
                            backgroundImage: `url(${tile?.visual})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            marginLeft: 2,
                            marginRight: 2,
                            borderRadius: 4,
                          }}
                        ></div>
                        {tile.matched && <div className="matchedFrame">中马</div>}
                      </div>
                    );
                  })}
              </div>
              <div className="winnerText">
                {/* 自摸赢家 */}
                {t("game_end.self_draw_winner")}
              </div>
              <div className="PrizeHorse-winner-container">
                <div className="playerProfile">
                  <div className={`hexagon hexagon1 winner`}>
                    <div className="hexagon-in1">
                      {/* <div
                        className="playerPfp"
                        style={{
                          backgroundImage: `url(${winner.profileUrl})`,
                        }}
                      ></div> */}
                    </div>
                  </div>

                  <div className={`playerProfileFrame winnerFrame`}></div>
                </div>
                <div className="playerName">{winner.name}</div>
              </div>
              <div className="prizeHorseText">
                {/* 奖马翻倍:  */}
                {t("game_end.prize_horse_multiple")}
                <span className="multiples">{horseMultiple}x</span>
              </div>
              <div className="toSummaryButton-container">
                <div
                  className="toSummaryButton"
                  onClick={() => {
                    setEndPage("summary");
                  }}
                ></div>
                <div className="toSummaryText">
                  {/* 查看结果 */}
                  {t("game_end.go_to_results")}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <HuAnimation />
    </>
  );
};

export default GameEnd;

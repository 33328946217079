import "./WelcomePage.scss";
import pressStartEN from "assets/metacasino/pregame/press_start_EN.png";
import pressStartCN from "assets/metacasino/pregame/press_start_CN.png";

import React, { useContext, useEffect, useState } from "react";
import { navigate } from "@reach/router";

import { localVersion, localVersionDate } from "App";

function WelcomePage() {
  return (
    <>
      <div className="welcomePage-container">
        <div className="gameTitle"></div>
        <div
          className="pressStartContainer"
          onClick={() => {
            navigate("/auth");
          }}
        >
          <img src={pressStartEN} alt="" className="pressStartEN" />
          {/* <img src={pressStartCN} alt="" className="pressStartCN" /> */}
        </div>
        <div className="version-text">{`v${localVersion}-${localVersionDate}`}</div>
      </div>
    </>
  );
}

export default WelcomePage;

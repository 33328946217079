import i18n from "i18n";

export const NUMBER_TILES = [
  {
    // name: "yiwan",
    name: i18n.t("tiles.一万"),
    defaultParams: { suit: "wan" },
    value: 1,
    visualCardIndex: 0,
  },
  {
    // name: "erwan",
    name: i18n.t("tiles.二万"),
    defaultParams: { suit: "wan" },
    value: 2,
    visualCardIndex: 1,
  },
  {
    // name: "sanwan",
    name: i18n.t("tiles.三万"),
    defaultParams: { suit: "wan" },
    value: 3,
    visualCardIndex: 2,
  },
  {
    // name: "siwan",
    name: i18n.t("tiles.四万"),
    defaultParams: { suit: "wan" },
    value: 4,
    visualCardIndex: 3,
  },
  {
    // name: "wuwan",
    name: i18n.t("tiles.五万"),
    defaultParams: { suit: "wan" },
    value: 5,
    visualCardIndex: 4,
  },
  {
    // name: "liuwan",
    name: i18n.t("tiles.六万"),
    defaultParams: { suit: "wan" },
    value: 6,
    visualCardIndex: 5,
  },
  {
    // name: "qiwan",
    name: i18n.t("tiles.七万"),
    defaultParams: { suit: "wan" },
    value: 7,
    visualCardIndex: 6,
  },
  {
    // name: "bawan",
    name: i18n.t("tiles.八万"),
    defaultParams: { suit: "wan" },
    value: 8,
    visualCardIndex: 7,
  },
  {
    // name: "jiuwan",
    name: i18n.t("tiles.九万"),
    defaultParams: { suit: "wan" },
    value: 9,
    visualCardIndex: 8,
  },

  {
    // name: "yaoji",
    name: i18n.t("tiles.一条"),
    defaultParams: { suit: "tiao" },
    value: 1,
    visualCardIndex: 9,
  },
  {
    // name: "liangtiao",
    name: i18n.t("tiles.二条"),
    defaultParams: { suit: "tiao" },
    value: 2,
    visualCardIndex: 10,
  },
  {
    // name: "santiao",
    name: i18n.t("tiles.三条"),
    defaultParams: { suit: "tiao" },
    value: 3,
    visualCardIndex: 11,
  },
  {
    // name: "sitiao",
    name: i18n.t("tiles.四条"),
    defaultParams: { suit: "tiao" },
    value: 4,
    visualCardIndex: 12,
  },
  {
    // name: "wutiao",
    name: i18n.t("tiles.五条"),
    defaultParams: { suit: "tiao" },
    value: 5,
    visualCardIndex: 13,
  },
  {
    // name: "liutiao",
    name: i18n.t("tiles.六条"),
    defaultParams: { suit: "tiao" },
    value: 6,
    visualCardIndex: 14,
  },
  {
    // name: "qitiao",
    name: i18n.t("tiles.七条"),
    defaultParams: { suit: "tiao" },
    value: 7,
    visualCardIndex: 15,
  },
  {
    // name: "batiao",
    name: i18n.t("tiles.八条"),
    defaultParams: { suit: "tiao" },
    value: 8,
    visualCardIndex: 16,
  },
  {
    // name: "jiutiao",
    name: i18n.t("tiles.九条"),
    defaultParams: { suit: "tiao" },
    value: 9,
    visualCardIndex: 17,
  },
  {
    // name: "yibing",
    name: i18n.t("tiles.一筒"),
    defaultParams: { suit: "tong" },
    value: 1,
    visualCardIndex: 18,
  },
  {
    // name: "ertong",
    name: i18n.t("tiles.二筒"),
    defaultParams: { suit: "tong" },
    value: 2,
    visualCardIndex: 19,
  },
  {
    // name: "santong",
    name: i18n.t("tiles.三筒"),
    defaultParams: { suit: "tong" },
    value: 3,
    visualCardIndex: 20,
  },
  {
    // name: "sitong",
    name: i18n.t("tiles.四筒"),
    defaultParams: { suit: "tong" },
    value: 4,
    visualCardIndex: 21,
  },
  {
    // name: "wutong",
    name: i18n.t("tiles.五筒"),
    defaultParams: { suit: "tong" },
    value: 5,
    visualCardIndex: 22,
  },
  {
    // name: "liutong",
    name: i18n.t("tiles.六筒"),
    defaultParams: { suit: "tong" },
    value: 6,
    visualCardIndex: 23,
  },
  {
    // name: "qitong",
    name: i18n.t("tiles.七筒"),
    defaultParams: { suit: "tong" },
    value: 7,
    visualCardIndex: 24,
  },
  {
    // name: "batong",
    name: i18n.t("tiles.八筒"),
    defaultParams: { suit: "tong" },
    value: 8,
    visualCardIndex: 25,
  },
  {
    // name: "jiutong",
    name: i18n.t("tiles.九筒"),
    defaultParams: { suit: "tong" },
    value: 9,
    visualCardIndex: 26,
  },
];

export const DRAGON_WIND_TILES = [
  {
    // name: "dongfeng",
    name: i18n.t("tiles.东风"),
    defaultParams: { suit: "feng" },
    value: "east",
    visualCardIndex: 27,
  },
  {
    // name: "nanfeng",
    name: i18n.t("tiles.南风"),
    defaultParams: { suit: "feng" },
    value: "south",
    visualCardIndex: 28,
  },
  {
    // name: "xifeng",
    name: i18n.t("tiles.西风"),
    defaultParams: { suit: "feng" },
    value: "west",
    visualCardIndex: 29,
  },
  {
    // name: "beifeng",
    name: i18n.t("tiles.北风"),
    defaultParams: { suit: "feng" },
    value: "north",
    visualCardIndex: 30,
  },

  {
    // name: "hongzhong",
    name: i18n.t("tiles.红中"),
    defaultParams: { suit: "long" },
    value: "hongzhong",
    visualCardIndex: 31,
  },
  {
    // name: "facai",
    name: i18n.t("tiles.发财"),
    defaultParams: { suit: "long" },
    value: "facai",
    visualCardIndex: 32,
  },
  {
    // name: "baiban",
    name: i18n.t("tiles.白板"),
    defaultParams: { suit: "long" },
    value: "baiban",
    visualCardIndex: 33,
  },
];

export const TILES = [...NUMBER_TILES, ...DRAGON_WIND_TILES];

export const makeTiles = () => {
  const fourOfEachTile = [...TILES, ...TILES, ...TILES, ...TILES];
  return fourOfEachTile.map((tile, i) => ({
    id: i,
    ...tile,
  }));
};

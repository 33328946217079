// import firebase from "firebase";
import {
  getDatabase,
  ref,
  set,
  child,
  get,
  serverTimestamp,
  update,
  onDisconnect,
  onValue,
  query,
  equalTo,
  limitToLast,
  limitToFirst,
  orderByKey,
  orderByValue,
  orderByChild,
  remove,
  runTransaction,
} from "firebase/database";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";

import { Decimal } from "decimal.js";
import Paks from "../paks/Paks";
import { Action, Card } from "./CardPakTypes";
import { oKey, oVal, shuffle } from "../utils";
import { nanoid } from "nanoid";
import { priceList } from "game-constants";

import _ from "lodash";
import { navigate } from "@reach/router";

export enum GameMode {
  basic = "BASIC",
  selfDrawn = "SELF_DRAWN",
}

export enum Stages {
  noRoom = "NO_ROOM",
  inLobby = "IN_LOBBY",
  inGame = "IN_GAME",
  gameEnd = "GAME_END",
  prizeHorse = "PRIZE_HORSE",
}

// export const ACTION_DECISON_TIME = 0.1; //in seconds
// export const TILE_DISCARD_TIME = 0.1; //in seconds
// export const DELAY_START_TIME = 1; //in seconds

export const ACTION_DECISON_TIME = 8; //in seconds
export const TILE_DISCARD_TIME = 16; //in seconds
export const DELAY_START_TIME = 6; //in seconds

// const AUTOPLAY_ENABLE = !!process.env.REACT_APP_AUTOPLAY_ENABLE;
const AUTOPLAY_ENABLE = process.env.REACT_APP_AUTOPLAY_ENABLE === "true";
const HOUSE_COMMISSION = new Decimal(0.05);

export const USE_TEST_DECK = false;

type Player = {
  id: string;
  connected?: boolean;
  spectator?: boolean;
  autopilot?: boolean;
};

/**
 * Game Engine Wukong
 * card.pak engine v1
 *
 * Handles room management, writing to firebase,
 * updating React when firebase changes are read, and
 * handling ALL of game state.
 * Mostly built to play diff. versions of majiang.
 * @class
 */
export class GameEngine {
  //static methods to keep track of rooms
  houseCommission = HOUSE_COMMISSION;
  // Current user information
  uid?: string | undefined = undefined;
  displayName?: string = undefined;

  // All your user data by games rooms you've joined
  // Helps for reconnecting
  allUsersToRooms: {
    [roomId: string]: { uid: string; displayName: string };
  } = {};

  // id of the cardpak being played
  pakId: string = "mhjng-dianxin";

  // id of the game room
  roomId?: string;
  gameMode?: string;
  priceMode?: string;
  basePrice: Decimal = new Decimal(0);
  specialPrice: Decimal = new Decimal(0);

  // id of the host player
  hostPlayerId?: string;

  // A copy of all the cards in the pak
  cards?: Card[];

  // Parameters of the current game
  gameParams?: any = { showPrizeHorseSelection: false };

  // Players parameters in current game
  playerParams?: any[];

  // Whether the current game has ended, used to determine
  // the current state of the game
  _gameEnded: boolean | null = null;

  gameStarted: boolean = false;

  timeRemaining: number = 0;

  countDownTimerId: string = "";

  nextTimeoutTimestamp = { timestamp: 0, type: null };

  actionTimerId;
  discardTimerId;

  // Array of all players in game
  players: {
    id: string;
    connected?: boolean;
    spectator?: boolean;
    name?: string;
    gamePoints?: Decimal;
    gongPoints?: Decimal;
    autopilot?: boolean;
  }[] = [];

  // A setState function used to update the top level React page
  localUpdater?: (val: any) => void;

  // The Firebase Realtime database
  db;
  firestore;
  firebaseAuth;
  firebaseFunc;
  firebaseAnalytics;

  // A Firebase Reference to the room of roomId
  // roomRef?: (refpath?: string) => DatabaseReference;
  roomRef;

  autoplayEnabled: boolean = AUTOPLAY_ENABLE;
  isOnAutopilot: boolean = false;

  unsubToSelf;
  unsubToPlayerParams;
  unsubToGameParams;
  unsubToNextTimeoutTimestamp;
  unsubToGameStarted;
  unsubToGameEnded;
  unsubToGameHost;
  unsubToPlayers;

  //* Constructor

  constructor() {
    console.log(".•˚•.Booting GameEngine˚•.•˚");

    // const allUsersToRooms = window?.localStorage?.getItem("allUsersToRooms");
    // if (allUsersToRooms) {
    //   try {
    //     this.allUsersToRooms = JSON.parse(allUsersToRooms) || {};
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    (window as any).GE = this;
  }

  resetTimers = () => {
    clearTimeout(this.actionTimerId);
    clearTimeout(this.discardTimerId);
    this.updateReact();
  };

  setupDiscardTimer = (actionFunc, executingParams) => {
    console.log("[DiscardTimer] setting up discard timer");

    clearTimeout(this.actionTimerId);
    clearTimeout(this.discardTimerId);

    this.discardTimerId = setTimeout(() => {
      console.log("[DiscardTimer] discard timeout, discarding tile...");
      console.log("[DiscardTimer] actionFunc", actionFunc);
      console.log("[DiscardTimer] executingParams", executingParams);

      actionFunc(executingParams);
    }, 1000 * TILE_DISCARD_TIME);
    this.updateReact();
  };

  setupActionTimer = (actionFunc, executingParams) => {
    console.log("[ActionTimer] setting up action timer");

    clearTimeout(this.actionTimerId);
    clearTimeout(this.discardTimerId);

    this.actionTimerId = setTimeout(() => {
      console.log("[ActionTimer] action timeout, executing next action...");
      console.log("[ActionTimer] actionFunc", actionFunc);
      console.log("[ActionTimer] executingParams", executingParams);

      actionFunc.onExecute(executingParams);
    }, 1000 * ACTION_DECISON_TIME);
    this.updateReact();
  };

  finishGame = async (huReason: string) => {
    console.log("[GE] Game finishes with reason", huReason);
    this.updateGameParams({ finishAnimationToShow: huReason });
    this.updateReact();

    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  resetRoom = () => {
    console.log(".•˚•.Rebooting GameEngine˚•.•˚");

    onDisconnect(ref(this.db, `${this.roomRef}/players/${this.uid}`)).cancel();
    this.cleanupSubscriptions();

    this.uid = undefined;
    this.roomId = undefined;
    this.allUsersToRooms = {};
    this.cards = undefined;
    this.players = [];
    this.roomRef = undefined;
    this.gameParams = {};
    this.playerParams = undefined;
    this._gameEnded = null;
    this.gameStarted = false;
    this.countDownTimerId = "";
    this.nextTimeoutTimestamp = { timestamp: 0, type: null };
    this.actionTimerId = undefined;
    this.discardTimerId = undefined;
    this.isOnAutopilot = false;
    this.updateReact();
  };

  attachReact = (setUpdate: (val: any) => void): void => {
    this.localUpdater = setUpdate;
  };

  attachFirebase = (db): void => {
    this.db = db;
    // this.db = getDatabase();
  };

  attachFirestore = (firestore): void => {
    // this.db = db;
    this.firestore = firestore;
  };

  attachFirebaseAuth = (auth): void => {
    // this.db = db;
    this.firebaseAuth = auth;
  };

  attachFirebaseFunc = (functions): void => {
    // this.db = db;
    this.firebaseFunc = functions;
  };

  attachFirebaseAnalytics = (analytics): void => {
    // this.db = db;
    this.firebaseAnalytics = analytics;
  };

  updateReact = (): void => {
    this.localUpdater && this.localUpdater(Math.random());
  };

  //* General room getters & setters

  get isHost() {
    return this.uid === this.hostPlayerId;
  }

  get stage() {
    if (this.roomId === undefined) return Stages.noRoom;
    if (this.gameEnded === null) return Stages.inLobby;
    if (this.gameEnded === false) return Stages.inGame;
    // if (this.gameEnded === false && this.gameParams.showPrizeHorseSelection === false) return Stages.inGame;
    // if (this.gameParams.showPrizeHorseSelection === true) return Stages.prizeHorse;
    if (this.gameEnded === true) return Stages.gameEnd;
    return Stages.noRoom;
  }

  get randomId() {
    // const firstPartNum = (Math.random() * 46656) | 0;
    // const secondPartNum = (Math.random() * 46656) | 0;
    // const firstPart = ("00" + firstPartNum.toString(36)).slice(-2);
    // const secondPart = ("00" + secondPartNum.toString(36)).slice(-2);
    // return firstPart + secondPart;
    return nanoid(10);
  }

  set user(user: any) {
    this.uid = user.uid;
    this.displayName = user.displayName;
  }

  get user() {
    return { uid: this.uid, displayName: this.displayName };
  }

  //* Room Methods

  // makeRoomRef = (roomId: string) => {
  //   return (refpath?: string) => {
  //     if (!refpath) {
  //       // return this.db?.ref(`rooms/${roomId}`);
  //       return ref(this.db, `rooms/${roomId}`);
  //     }
  //     return ref(this.db, `rooms/${roomId}/${ref}`);
  //   };
  // };

  makeRoomRef = (roomId: string, gameMode: string, priceMode: string) => {
    return `${gameMode}-${priceMode}/${roomId}`;
  };

  private createNewGameUser = async (roomId: string, displayName: string, givenUid: string) => {
    const uid = givenUid;

    this.user = { uid, displayName };
    this.allUsersToRooms[roomId] = this.user;

    // await window.localStorage.setItem("allUsersToRooms", JSON.stringify(this.allUsersToRooms));

    return;
  };

  getAvailableRoom = async (name: string, uid: string, gameMode: string, priceMode: string) => {
    this.gameMode = gameMode;
    this.priceMode = priceMode;

    let priceModeItem = _.find(priceList, { priceModeId: priceMode });
    this.basePrice = new Decimal(priceModeItem.basePrice);
    this.specialPrice = new Decimal(priceModeItem.specialPrice);

    const constraints = [orderByChild("createdAt"), limitToLast(1)];
    // const constraints = [orderByChild("gameStarted"), equalTo(false), limitToFirst(1)];
    // console.log("in getAvailableRoom()", name, uid, gameMode, priceMode);

    let latestRoomId, latestRoomInfo;

    const roomsRef = ref(this.db, `${gameMode}-${priceMode}`);
    const q = query(roomsRef, ...constraints);
    const docs = await get(q);

    let finalRoomId;

    console.log("[getAvailableRoom] docs", docs.size);

    if (docs.size === 0) {
      console.log("getAvailableRoom(): creating a new room because no room here");
      finalRoomId = this.createRoom(name, uid, gameMode, priceMode);
      return finalRoomId;
    } else {
      console.log("[getAvailableRoom] docs > 0");

      docs.forEach((doc) => {
        latestRoomId = doc.key;
        latestRoomInfo = doc.val();

        console.log("latestRoomId", latestRoomId);
        console.log("latestRoom", latestRoomInfo);

        finalRoomId = latestRoomId;
      });

      if (latestRoomInfo?.players) {
        let isHostPlayerHere: boolean = false; //roomHost exists and connected
        let roomHost: any = oVal(latestRoomInfo?.players).filter((player) => (player as any).id === latestRoomInfo?.hostPlayerId);

        if (roomHost.length > 0) {
          if (roomHost[0].connected === true) {
            isHostPlayerHere = true;
          }
        }
        console.log("checking conditions.... ");
        console.log("latestRoomInfo.gameStarted", latestRoomInfo.gameStarted);
        console.log("latestRoomInfo?.players", latestRoomInfo?.players);
        console.log("Object.keys(latestRoomInfo?.players).length", Object.keys(latestRoomInfo?.players).length);
        console.log("latestRoomInfo?.createdAt", latestRoomInfo?.createdAt);
        console.log("isHostPlayerHere", isHostPlayerHere);

        if (
          latestRoomInfo.gameStarted === false &&
          latestRoomInfo?.players &&
          Object.keys(latestRoomInfo?.players).length < 4 &&
          latestRoomInfo?.createdAt &&
          isHostPlayerHere
        ) {
          console.log(`this is an existing room ${finalRoomId}, joining...`);
          // finalRoomId = this.joinRoom(latestRoomId, name, uid, gameMode, priceMode);
          return finalRoomId;
        } else {
          console.log("conidtions not met, creating...");
          finalRoomId = this.createRoom(name, uid, gameMode, priceMode);
          return finalRoomId;
        }
      } else {
        //createNewRoom
        console.log("latestRoomInfo has no players, creating a new room");
        finalRoomId = this.createRoom(name, uid, gameMode, priceMode);
        return finalRoomId;
      }
    }

    // return finalRoomId;
  };

  createRoom = (name: string, uid: string, gameMode: string, priceMode: string) => {
    this.roomId = this.randomId;
    this.roomRef = this.makeRoomRef(this.roomId, gameMode, priceMode);
    // this.gameStarted = false;

    // console.log("createRoom: this.user", this.user);

    set(ref(this.db, `${this.roomRef}`), {
      pakId: this.pakId,
      hostPlayerId: uid,
      gameEnded: null,
      gameStarted: false,
      createdAt: serverTimestamp(),
      gameMode,
      priceMode,
    });

    // return this.joinRoom(this.roomId, name, uid, gameMode, priceMode);
    return this.roomId;
  };

  joinRoom = async (roomId: string, name: string, uid: string, gameMode: string, priceMode: string) => {
    // console.log("in joinRoom: roomId", roomId);

    let priceModeItem = _.find(priceList, { priceModeId: priceMode });
    this.basePrice = new Decimal(priceModeItem.basePrice);
    this.specialPrice = new Decimal(priceModeItem.specialPrice);
    this.roomId = roomId;

    this.roomRef = this.makeRoomRef(roomId, gameMode, priceMode);
    this.gameStarted = false;
    this.gameEnded = null;
    // console.log("joinRoom: priceModeItem", priceModeItem, this.basePrice.toFixed(2), this.specialPrice.toFixed(2));

    let userForRoom = this.allUsersToRooms[roomId];
    // console.log("in joinRoom: allUsersToRooms", this.allUsersToRooms);
    // console.log("in joinRoom: userForRoom", userForRoom);

    const isNoUserInfo = !this.uid && !name && !userForRoom;
    const isNoRoomInfo = !this.roomRef && !roomId;

    // console.log("isNoUserInfo", isNoUserInfo);
    // console.log("isNoRoomInfo", isNoRoomInfo);

    if (isNoUserInfo) return;
    if (isNoRoomInfo || !gameMode || !priceMode) return;

    // Set up the player's information
    const playerInfo = {
      id: uid,
      name: name,
      roomRef: this.roomRef,
    };

    // Try to add the player to the room
    console.log("[joinRoom] about to call this.addPlayerToRoom");
    let joinSuccess = await this.addPlayerToRoom(playerInfo, false);

    if (joinSuccess) {
      console.log("Player successfully joined the room:", roomId);
      this.updateReact();
      return true;
    } else {
      console.error("Failed to join the room:", roomId);
      this.roomRef = undefined;
      return false;
    }

    // const isNewRoomToJoin = !this.roomRef && !!roomId;
    // // console.log("isNewRoomToJoin", isNewRoomToJoin);

    // if (isNewRoomToJoin) {
    //   this.roomId = roomId;
    //   this.roomRef = this.makeRoomRef(this.roomId, gameMode, priceMode);
    // }

    // // Set user data based on whether we're creating a new user or not
    // if (!userForRoom) {
    //   await this.createNewGameUser(roomId, name as string, uid);
    //   userForRoom = this.allUsersToRooms[roomId];
    // }

    // get(child(ref(this.db), `${this.roomRef}`)).then(async (document) => {
    //   // console.log("In joinRoom(): roomRef", this.roomRef);
    //   // console.log("In joinRoom(): doc", doc);
    //   // console.log("In joinRoom(): doc", doc.val());

    //   const docExists = document.exists();
    //   const userIdsInRoom = oKey(document.val()?.players || {});

    //   // console.log("userIdsInRoom", userIdsInRoom);

    //   const isNewRoom = !docExists && !!roomId && !!name;
    //   const isExistingRoom = docExists;
    //   const hasUserInfo = !!this.uid && !!this.displayName;
    //   const isReconnecting = userIdsInRoom.includes(userForRoom?.uid);
    //   const isGameStarted = document.val()?.gameEnded === false;

    //   // console.log("In joinRoom() isExistingRoom", isExistingRoom);

    //   // It wont be a new room as new room is created in getAvailableRoom
    //   // if (isNewRoom) {
    //   //   this.createRoom(name as string, roomId);
    //   // } else
    //   if (isExistingRoom && isReconnecting) {
    //     // console.log("isExistingRoom && isReconnecting", isExistingRoom, isReconnecting, userForRoom);

    //     this.user = userForRoom;
    //     await this.reconnectPlayerToRoom(userForRoom.uid);
    //   } else if (isExistingRoom && hasUserInfo) {
    //     console.log("True: isExistingRoom && hasUserInfo, about to add player to room");

    //     // await this.addPlayerToRoom(
    //     //   {
    //     //     id: this.uid as string,
    //     //     name: this.displayName as string,
    //     //   },
    //     //   isGameStarted
    //     // );
    //     await this.tryJoiningRoom(
    //       {
    //         id: this.uid as string,
    //         name: this.displayName as string,
    //       },
    //       gameMode,
    //       priceMode
    //     );

    //     this.updateReact();
    //   } else {
    //     console.log("roomRef is invalid");
    //   }
    // });
  };

  cleanupSubscriptions() {
    if (this.unsubToSelf) this.unsubToSelf();
    if (this.unsubToPlayerParams) this.unsubToPlayerParams();
    if (this.unsubToGameParams) this.unsubToGameParams();
    if (this.unsubToNextTimeoutTimestamp) this.unsubToNextTimeoutTimestamp();
    if (this.unsubToGameStarted) this.unsubToGameStarted();
    if (this.unsubToGameEnded) this.unsubToGameEnded();
    if (this.unsubToGameHost) this.unsubToGameHost();
    if (this.unsubToPlayers) this.unsubToPlayers();
  }

  private tryJoiningRoom = async (playerInfo, gameMode, priceMode, existingRoomId) => {
    let roomId;
    let finalRoomId;
    const maxRetries = 5;
    let retryCount = 0;

    while (retryCount < maxRetries) {
      roomId = await this.getAvailableRoom(playerInfo.name, playerInfo.id, gameMode, priceMode);
      console.log("[tryJoiningRoom] getAvailableRoom, roomId", roomId);

      let roomRef = this.makeRoomRef(roomId, gameMode, priceMode);
      let roomInfo: any = await this.getRoomInfoByPath(roomRef);

      console.log("[tryJoiningRoom] roomInfo about to join:", roomInfo);

      if (roomInfo?.gameStarted !== false) {
        // only allow to join if game not yet started
        break;
      }

      // if users already has existing room, then reject

      this.createNewGameUser(roomId, playerInfo.name, playerInfo.id);

      // if (!roomId) {
      //   console.log("No available room. Creating a new room.");
      //   roomId = await this.createRoom(playerInfo.name, playerInfo.id, gameMode, priceMode);
      // }

      let joinSuccess = await this.joinRoom(roomId, playerInfo.name, playerInfo.id, gameMode, priceMode);

      if (joinSuccess) {
        console.log("[tryJoiningRoom] joinRoom success: ", roomId);

        console.log("[tryJoiningRoom] tryJoiningRoom uid, ", this.uid);

        this.unsubToSelf = this.subscribeToSelf();
        this.unsubToPlayerParams = this.subscribeToPlayerParams();
        this.unsubToGameParams = this.subscribeToGameParams();
        this.unsubToNextTimeoutTimestamp = this.subscribeToNextTimeoutTimestamp();
        this.unsubToGameStarted = this.subscribeToGameStarted();
        this.unsubToGameEnded = this.subscribeToGameEnded();
        this.unsubToGameHost = this.subscribeToGameHost();
        this.unsubToPlayers = this.subscribeToPlayers();

        this.updateReact();

        const docSnap = await updateDoc(doc(this.firestore, "users", this.uid as string), {
          lastRoomPath: this.roomRef,
        });
        console.log("updated latest room to firestore for user: ", this.uid);
        finalRoomId = roomId;
        break;
      } else {
        console.log("Joining room failed, retrying...");
        retryCount++;
      }
    }

    if (finalRoomId) {
      console.log(`Successfully joined the room ${finalRoomId} `);
    } else {
      console.log("Failed to join a room after maximum retries.");
    }

    return finalRoomId;
  };

  // /**
  //  * Method run when a user tries to navigate to a room via uuid
  //  * in the url
  //  */
  // tryRoom = async (roomId, name, uid, gameMode, priceMode) => {
  //   const testRoomRef = this.makeRoomRef(roomId, gameMode, priceMode);
  //   let roomExists = false;

  //   // console.log("[GE]:calling joinRoom...");

  //   // console.log("tryRoom", testRoomRef);

  //   // WORKAROUND: For some reason the await below the one here
  //   // doesn't seem to actually be awaiting when assigned to the
  //   // doc variable... But this one works?

  //   // await testRoomRef()?.once?.("value");
  //   // let doc = await testRoomRef()?.once("value");
  //   // roomExists = !!doc?.exists();

  //   get(child(ref(this.db), `${testRoomRef}`))
  //     .then(async (doc) => {
  //       // console.log("tryRoom doc", doc.val());

  //       if (!doc.exists()) {
  //         // when there is no room to rejoin, return false to navigate to lobby
  //         // console.log("tryRoom doc doesnt exists returning");
  //         // this.joinRoom(roomId, name, uid, gameMode, priceMode);

  //         return false;
  //       }

  //       // when the room exists, get user to joinRoom
  //       // console.log("tryRoom doc exists, trying to rejoin");
  //       return this.joinRoom(roomId, name, uid, gameMode, priceMode);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  private addPlayerToRoom = async (
    {
      id,
      name,
      roomRef,
    }: {
      id: string;
      name: string;
      roomRef: string;
    },
    isGameStarted?: boolean
  ) => {
    // console.log("[addPlayerToRoom] roomRef", roomRef);

    if (!this.roomRef) return;

    const roomPlayersRef = ref(this.db, `${this.roomRef}/players`);
    let transactionCompletedSuccessfully = false;

    const currentPlayers = await get(ref(this.db, `${this.roomRef}/players`));
    let currentPlayersVal = currentPlayers.val();

    console.log("[addPlayerToRoom] currentPlayers.val()", currentPlayersVal);

    if (currentPlayersVal && Object.keys(currentPlayersVal).length === 4) {
      console.log("[addPlayerToRoom] Enough players in this room, rejecting...");
      return false;
    }

    await runTransaction(roomPlayersRef, (currentPlayers) => {
      console.log("running addPlayer Transaction currentPlayers", currentPlayers);

      if (currentPlayers === null) {
        currentPlayers = {};
      }

      let numCurrentPlayers = Object.keys(currentPlayers).length;

      // Check if there are less than 4 players
      // if (numCurrentPlayers < 4 && numCurrentPlayers !== 0) {
      // if (numCurrentPlayers < 4) {
      if (numCurrentPlayers !== 4) {
        console.log("[transaction]: Object.keys(currentPlayers).length", Object.keys(currentPlayers).length);

        console.log("running transaction, as less than 4 players");

        // Add the new player
        currentPlayers[id] = {
          id,
          name,
          connected: true,
          timestamp: serverTimestamp(), // or serverTimestamp() if using Firebase server time
          spectator: false,
        };
        return currentPlayers; // Return the updated state
      } else {
        console.log("numCurrentPlayers", numCurrentPlayers);
        console.log("[transaction]: enough players, returning null");

        // Abort the transaction if there are already 4 players
        return undefined; // Returning undefined aborts the transaction
      }
    })
      .then((result) => {
        console.log("[transaction] result from transaction:", result, result.snapshot.val());

        if (result.committed) {
          transactionCompletedSuccessfully = true;
          console.log("[transaction]: transaction Completed Successfully: ", transactionCompletedSuccessfully);
        } else {
          console.log("[transaction]: transaction failed: ", transactionCompletedSuccessfully);
        }
      })
      .catch((error) => {
        console.error("Transaction failed: ", error);
        transactionCompletedSuccessfully = false;
      });

    console.log("[transaction]: transaction about to reutrn: ", transactionCompletedSuccessfully);

    return transactionCompletedSuccessfully;

    // return update(ref(this.db, `${this.roomRef}/players/${id}`), {
    //   id,
    //   name,
    //   connected: true,
    //   // timestamp: serverTimestamp(),
    //   timestamp: new Date(),
    //   spectator: false,
    // });
  };

  // private oldReconnectPlayerToRoom = async (userId: string) => {
  //   if (!this.roomRef) return;
  //   // console.log("reconnecting player to room");

  //   return update(ref(this.db, `${this.roomRef}/players/${userId}`), { connected: true });
  // };

  private reconnectPlayerToRoom = async (playerInfo, gameMode, priceMode, roomId) => {
    let reconnectSuccess = false;

    const currentRoomRef = this.makeRoomRef(roomId, gameMode, priceMode);
    const playerSnapshot = await get(ref(this.db, `${currentRoomRef}/players/${playerInfo.id}`));
    const players = await get(ref(this.db, `${currentRoomRef}/players`));
    let playersVal = players.val();

    // check if player is in the room and the room has really got 4 people
    if (playerSnapshot.exists() && Object.keys(playersVal).length === 4) {
      let playerVal = playerSnapshot.val();
      console.log("reconnecting player exists.", playerVal);

      this.gameMode = gameMode;
      this.priceMode = priceMode;

      let priceModeItem = _.find(priceList, { priceModeId: priceMode });
      this.basePrice = new Decimal(priceModeItem.basePrice);
      this.specialPrice = new Decimal(priceModeItem.specialPrice);

      this.roomId = roomId;
      this.roomRef = this.makeRoomRef(roomId, gameMode, priceMode);

      this.createNewGameUser(roomId, playerInfo.name, playerInfo.id);

      await update(ref(this.db, `${this.roomRef}/players/${playerInfo.id}`), { autopilot: false, connected: true });

      console.log("reconnectSuccess success: ", roomId);
      console.log("reconnectSuccess uid, ", this.uid);

      this.unsubToSelf = this.subscribeToSelf();
      this.unsubToPlayerParams = this.subscribeToPlayerParams();
      this.unsubToGameParams = this.subscribeToGameParams();
      this.unsubToNextTimeoutTimestamp = this.subscribeToNextTimeoutTimestamp();
      this.unsubToGameStarted = this.subscribeToGameStarted();
      this.unsubToGameEnded = this.subscribeToGameEnded();
      this.unsubToGameHost = this.subscribeToGameHost();
      this.unsubToPlayers = this.subscribeToPlayers();

      this.updateReact();

      reconnectSuccess = true;

      return reconnectSuccess;
    } else {
      console.log("reconnecting player doesnt belong to existing room, reject access");
      // remove lastRoomPath in firestore of user
      let docSnap = await updateDoc(doc(this.firestore, "users", playerInfo.id as string), {
        lastRoomPath: "",
      });

      return false;
    }
  };

  leaveRoom = async () => {
    if (!this.uid || !this.roomRef || !this.roomId) {
      // return
      this.cleanupSubscriptions();
      window.location.href = "/GameModeLobby";
    }

    // Remove your local reference to this game
    if (this.allUsersToRooms && this.roomId) {
      delete this.allUsersToRooms[this.roomId];
    }

    console.log("[leaveRoom] this.gameStarted", this.gameStarted);
    console.log("[leaveRoom] this.roomRef", this.roomRef, this.uid);

    if (this.gameStarted === true && this.gameEnded === false) {
      console.log("[leaveRoom] in this.gameStarted === true && this.gameEnded === false");

      await update(ref(this.db, `${this.roomRef}/players/${this.uid}`), { autopilot: true, connected: false });
      onDisconnect(ref(this.db, `${this.roomRef}/players/${this.uid}`)).cancel();
      this.cleanupSubscriptions();
      navigate("/GameModeLobby");
    } else if (this.gameStarted === false) {
      console.log("[leaveRoom] in this.gameStarted === false");

      onDisconnect(ref(this.db, `${this.roomRef}/players/${this.uid}`)).cancel();

      this.cleanupSubscriptions();
      await update(ref(this.db, `${this.roomRef}/players/${this.uid}`), { connected: false });
      // await remove(ref(this.db, `${this.roomRef}/players/${this.uid}`));
      navigate("/GameModeLobby");
    } else {
      console.log("[leaveRoom] in else ");
      onDisconnect(ref(this.db, `${this.roomRef}/players/${this.uid}`)).cancel();
      this.cleanupSubscriptions();
      window.location.href = "/GameModeLobby";
    }
    this.updateReact();

    // // If you are leaving, finish your turn for someone else
    // const seat = this.getPlayerParams(this.uid)?.seat;
    // if (seat === this.gameParams?.seatTurn) {
    //   this.finishTurn();
    // }

    // Remove your params
    // this.roomRef(`playerParams/${this.uid}`)?.set(null);
    // this.roomRef(`players/${this.uid}`)?.set(null);

    // set(ref(this.db, `${this.roomRef}/playerParams/${this.uid}`), null);
    // set(ref(this.db, `${this.roomRef}/players/${this.uid}`), null);

    // Go back to home
    // navigate("/GameModeLobby");

    // window.location.href = "/GameModeLobby";
  };

  //* Methods for in game/room lobby

  startGame = async (cardPakId: string = "mhjng-dianxin") => {
    // startGame = async () => {
    this.gameEnded = false;
    this.gameStarted = true;

    update(ref(this.db, `${this.roomRef}`), { gameStarted: true });

    this.setupNewGame();
    this.updateReact();
  };

  setupNewGame = async () => {
    // console.log("Pak in setupNewGame()", this.pak);
    console.log("[setupNewGame] calling setupNewGame()", this);

    const pak = this.pak;
    if (!pak || !pak.rules) return;

    const connectedPlayers = [...this.players].filter((p) => !!p.connected);
    // console.log("here are the connected players", connectedPlayers);

    // shuffle players
    this.playerParams = await shuffle(connectedPlayers).map((player, i) => {
      // this.playerParams = await connectedPlayers.map((player, i) => {
      const initialParams: any = {
        id: player.id,
        name: player.name,
        ...pak.rules?.playerParams,
        // gamePoints: player.gamePoints || new Decimal(0),
        gamePoints: 0,
        seat: i,
        gongPoints: 0,
      };
      console.log("initialPrarms", initialParams);

      // this.roomRef("playerParams/" + player.id)?.set(initialParams);
      // this.roomRef("players/" + player.id)?.update({ spectator: false });

      set(ref(this.db, `${this.roomRef}/playerParams/${player.id}`), initialParams);
      update(ref(this.db, `${this.roomRef}/players/${player.id}`), { spectator: false });

      // console.log("done updating connected players");

      return initialParams;
    });

    connectedPlayers.forEach(async (player) => {
      const docSnap = await getDoc(doc(this.firestore, "users", player.id));
      let playerProfileUrl = docSnap?.data()?.profileURL;

      console.log("[GameEnd] player id, url", player.id, playerProfileUrl);

      this.updatePlayer(player.id, { profileUrl: playerProfileUrl });
    });

    // // Reset disconnected players
    // [...this.players]
    //   .filter((p) => !p.connected)
    //   .forEach((player: any) => {
    //     const initialParams = {
    //       id: player.id,
    //       name: player.name,
    //       ...pak.rules?.playerParams,
    //       points: player.points || 0,
    //     };
    //     // this.roomRef("playerParams/" + player.id)?.set(initialParams);
    //     // this.roomRef("players/" + player.id)?.update({ spectator: true });

    //     set(ref(this.db, `${this.roomRef}/playerParams/${player.id}`), initialParams);
    //     update(ref(this.db), { [`${this.roomRef}/players/${player.id}`]: { spectator: true } });

    //     console.log("done updating disconnected players");
    //   });

    this.cards = pak.deck?.cards.map((card) => ({
      ...card,
      params: card.defaultParams,
    }));

    // console.log("this.cards", this.cards);

    // Set initial gameParams
    // this.roomRef("gameParams")?.set(pak.rules.gameParams);
    set(ref(this.db, `${this.roomRef}/gameParams`), pak.rules.gameParams);

    // if (pak.rules.turnBased) {

    this.updateGameParams({ seatTurn: 0 });

    // }
    setTimeout(() => {
      pak.rules.onGameStart(this);
    }, DELAY_START_TIME * 1000);

    // logs for analytics
    console.log("logging new_game_start event");

    logEvent(this.firebaseAnalytics, "new_game_start", {
      roomRef: this.roomRef,
    });

    this.updateReact();
  };

  returnToLobby = async () => {
    if (!this.uid || !this.roomRef || !this.roomId) return;
    // await this.roomRef()?.update({
    //   gameEnded: null,
    // });
    delete this.allUsersToRooms[this.roomId];

    // await update(ref(this.db, `${this.roomRef}`), { gameEndedAt: serverTimestamp(), gameEnded: true });

    this.resetRoom();
    this.updateReact();
  };

  //* Firebase Subscribers

  subscribeToSelf = () => {
    if (!this.uid) return;
    // const myRef = this.roomRef(`players/${this.uid}`);
    const unsubscribe = onValue(ref(this.db, `${this.roomRef}/players/${this.uid}`), (doc) => {
      if (doc.val()) {
        let { autopilot } = doc.val();
        if (this.gameStarted === true) {
          this.isOnAutopilot = autopilot;
          this.updateReact();
        }
      }
    });

    // if (this.gameStarted === true) {
    //   console.log("checking Disconnect: ", this.gameStarted);

    //   onDisconnect(ref(this.db, `${this.roomRef}/players/${this.uid}`)).update({ autopilot: true, connected: false });
    // } else {
    //   console.log("when gameStarted is false");
    //   console.log("try to log this when disconnecting", this);

    // }

    onDisconnect(ref(this.db, `${this.roomRef}/players/${this.uid}`)).update({ autopilot: true, connected: false });

    return unsubscribe;
  };

  pickAnyOtherUserId = (players, userIdToExclude) => {
    // Filter out the excludeUserId
    const filteredUserIds = players.filter((player) => player.id !== userIdToExclude);

    // Check if the filtered array is not empty
    if (filteredUserIds.length === 0) {
      // throw new Error('No other userIds available');
      console.log("last user in the room and can get new host");

      return userIdToExclude;
    }

    // Get a random index from the filtered array
    const randomIndex = Math.floor(Math.random() * filteredUserIds.length);

    // Return the userId at the random index
    return filteredUserIds[randomIndex];
  };

  subscribeToPlayers = () => {
    const unsubscribe = onValue(ref(this.db, `${this.roomRef}/players`), (doc) => {
      const data = doc.val();

      // console.log("[subscribeToPlayers ]subscribing to players, gameStarted", this.gameStarted);

      // console.log("[subscribeToPlayers](): players data", data);
      let playersDataArray: Player[] = data ? oVal(data) : [];

      // remove disconnected players
      // console.log("[subscribeToPlayers] playersDataArray", playersDataArray);

      playersDataArray.forEach(async (player) => {
        // console.log("each player", player);

        if (this.gameStarted === false) {
          if (player.connected === false || player.autopilot === true) {
            console.log("[subscribeToPlayers]: removing player ", player);

            await remove(ref(this.db, `${this.roomRef}/players/${player.id}`));

            if (player.id === this.hostPlayerId) {
              // switch gamehost if host has left
              console.log("gameHost is leaving");
              if (playersDataArray.length > 1) {
                // case if other players can be gameHost
                console.log("about to reassign gameHost..");

                let newHostPlayerId = this.pickAnyOtherUserId(playersDataArray, this.hostPlayerId);

                console.log("reassigning gameHost to:", newHostPlayerId.id);

                await update(ref(this.db, `${this.roomRef}`), { hostPlayerId: newHostPlayerId.id });
              }
            }
          }
        }
      });

      if (playersDataArray.length === 0 && !this.gameStarted) {
        console.log("[subscribeToPlayers]: no more players in the room, redirecting...");
        this.gameEnded = true;
      }

      // console.log("playersDataArray after purging disconnected player", playersDataArray);

      // check for zombie room
      let autopilotPlayers = playersDataArray.filter((player: Player) => player.autopilot === true);

      if (autopilotPlayers.length === 4 && this.gameStarted) {
        // terminate room if all went offline
        this.gameEnded = true;
      }

      this.players = playersDataArray.filter((player: Player) => {
        return player.connected === true || player.autopilot === true;
      });

      // console.log("in subscribeToPlayers(): players data (only connected)", this.players);

      this.updateReact();
      // console.log("👀 Received new players", this.players);

      // TODO: add checking to make sure all players are connected

      if (
        this.players.filter((player) => player.connected === true).length === 4 &&
        this.players.filter((player) => player.id === this.uid) &&
        this.isHost &&
        !this.gameStarted
      ) {
        console.log("about to start game, gameMode, priceMode", this.gameMode, this.priceMode);
        this.players = this.players.filter((player) => player.connected === true);
        this.startGame();
      }
    });

    // const unsubscribe = this.roomRef("players")?.on("value", (doc) => {
    //   const data = doc.val();
    //   this.players = data ? oVal(data) : [];
    //   this.updateReact();
    //   console.log("👀 Received new players", this.players);
    // });
    return unsubscribe;
  };

  subscribeToPlayerParams = () => {
    // const unsubscribe = this.roomRef("playerParams")?.on("value", (doc) => {
    const unsubscribe = onValue(ref(this.db, `${this.roomRef}/playerParams`), (doc) => {
      const data = doc.val();
      this.playerParams = data ? oVal(data) : [];
      this.updateReact();
      // console.log("👀 Received new player params", this.playerParams);
    });
    return unsubscribe;
  };

  subscribeToGameParams = () => {
    // const unsubscribe = this.roomRef("gameParams")?.on("value", (doc) => {
    const unsubscribe = onValue(ref(this.db, `${this.roomRef}/gameParams`), (doc) => {
      this.gameParams = doc.val();

      this.updateReact();
      // console.log("👀 Received new game params", this.gameParams);
      // console.log("[Debug]: Received Game Params from Firebase", new Date(), this.gameParams);
      // console.log("[Debug]: new lastTurnTimestamp", this.gameParams?.lastTurnTimestamp);
    });
    return unsubscribe;
  };

  subscribeToNextTimeoutTimestamp = () => {
    const unsubscribe = onValue(ref(this.db, `${this.roomRef}/nextTimeoutTimestamp`), (doc) => {
      this.nextTimeoutTimestamp = doc.val();
      this.updateReact();
      // console.log("👀 Received new nextTimeoutTimestamp", this.nextTimeoutTimestamp);
    });
    return unsubscribe;
  };

  subscribeToGameStarted = () => {
    // const unsubscribe = this.roomRef("gameEnded")?.on("value", (doc) => {
    const unsubscribe = onValue(ref(this.db, `${this.roomRef}/gameStarted`), (doc) => {
      this.gameStarted = doc.val();
      this.updateReact();
      // console.log("👀 Received new game ended", this._gameEnded);
    });
    return unsubscribe;
  };

  subscribeToGameEnded = () => {
    // const unsubscribe = this.roomRef("gameEnded")?.on("value", (doc) => {
    const unsubscribe = onValue(ref(this.db, `${this.roomRef}/gameEnded`), (doc) => {
      this._gameEnded = doc.val();
      this.updateReact();
      // if (doc.val() === true) {
      //   // navigate(`/endGame/${this.gameMode}/${this.priceMode}/${this.roomId}`);
      // }

      console.log("👀 Received new game ended", this._gameEnded);
    });
    return unsubscribe;
  };

  subscribeToGameHost = () => {
    // const unsubscribe = this.roomRef("hostPlayerId")?.on("value", (doc) => {
    const unsubscribe = onValue(ref(this.db, `${this.roomRef}/hostPlayerId`), (doc) => {
      this.hostPlayerId = doc.val();
      this.updateReact();
      // console.log("👀 Received new host player id", this.hostPlayerId);
    });
    return unsubscribe;
  };

  get gameEnded() {
    return this._gameEnded;
  }

  set gameEnded(newEnd) {
    // this.roomRef("gameEnded")?.set(newEnd);
    set(ref(this.db, `${this.roomRef}/gameEnded`), newEnd);
    set(ref(this.db, `${this.roomRef}/gameEndedAt`), new Date());
  }

  setGameAndPriceMode(gameMode, priceMode) {
    this.gameMode = gameMode;
    this.priceMode = priceMode;
  }

  setShowPrizeHorseSelection(show) {
    this.updateGameParams({ showPrizeHorseSelection: show });
  }

  getTimeRemaining() {
    return this.timeRemaining;
  }

  setTimeRemaining(seconds) {
    this.timeRemaining = seconds;
  }

  getCountDownTimerId() {
    return this.countDownTimerId;
  }

  setCountDownTimerId(id) {
    this.countDownTimerId = id;
  }

  //* In Game Methods

  getAllPlayersActions = () => {
    let playerActions = {};

    this.players.forEach((player) => {
      let playerAvailableActions = this.getAvailableActions(player.id);
      playerActions[player.id] = playerAvailableActions;
    });

    return playerActions;
  };

  finishTurn = async (extraParams?) => {
    let executingPlayerId;

    if (extraParams.player) {
      executingPlayerId = extraParams.player;
    }

    const activePlayers = this.players.filter((p) => !p?.spectator);
    let nextSeatTurn = (this.gameParams.seatTurn + 1) % activePlayers.length;
    let actionFunc;

    await this.updateGameParams({
      ...extraParams,
      seatTurn: nextSeatTurn,
      lastTurnTimestamp: serverTimestamp(),
      seatReason: null,
      actionLastPlay: null,
    });
    // console.log("[Debug]: In finishTurn(), updating Game Params by", extraParams, new Date());

    // let pendingActions = this.getAllPlayersActions();
    // let pendingActor = this.getPlayerBySeat(nextSeatTurn);

    // console.log("[Debug]: In finishTurn(), pending acitons", pendingActions);
    // console.log("[Debug]: In finishTurn(), pending acitor", pendingActor);

    // if (_.find(pendingActions[pendingActor.id], { name: "Draw" })) {
    //   actionFunc = _.find(pendingActions[pendingActor.id], { name: "Draw" });
    // } else if (_.find(pendingActions[pendingActor.id], { name: "Skip" })) {
    //   actionFunc = _.find(pendingActions[pendingActor.id], { name: "Skip" });
    // } else {
    //   console.log("no matching actions here: ", pendingActions);
    // }

    // //setting up action timer after finishTurn
    // let executingParams = { executingPlayerId: pendingActor.id, gameEngine: this };

    // this.setupActionTimer(actionFunc, executingParams);

    this.updateReact();
  };

  claimTurn = (id: string, reason?: string, lastPlay?) => {
    const player = this.getPlayerParams(id);
    this.updateGameParams({
      seatTurn: player.seat,
      seatReason: reason || null,
      lastTurnTimestamp: serverTimestamp(),
      actionLastPlay: lastPlay || null,
    });
    console.log("[Debug]: In claimTurn(), updating Game Params by", id, new Date());
    this.updateReact();
  };

  endGame = () => {
    this.gameEnded = true;
    this.updateReact();

    onDisconnect(ref(this.db, `${this.roomRef}/players/${this.uid}`)).cancel();
    this.cleanupSubscriptions();
  };

  //* Helpful Getters for In Game

  getPlayer = (id: string) => {
    return this.players.find((player) => id === player.id);
  };

  getPlayerBySeat = (seat: number) => {
    return this.playerParams?.find((player) => seat === player.seat);
  };

  getPlayerParams = (id?: string) => {
    if (!id) return this.pak.rules?.playerParams;
    const storedParams = this.playerParams?.find((player: any) => id === player.id);
    return { ...this.pak.rules?.playerParams, ...storedParams };
  };

  getOtherPlayersButId = (id: string) => {
    return this.playerParams?.filter((player) => player.id !== id);
  };

  getNonWinnersParams = (winnerId) => {
    let nonWinnersParams;

    // nonWinnersParams = this.playerParams?.filter((player) => player.winner === false);
    nonWinnersParams = this.playerParams?.filter((player) => player.id !== winnerId);
    return nonWinnersParams;
  };

  getAllPlayerParams = () => {
    return this.playerParams;
  };

  isPlayersTurn = (id: string) => {
    // if (!this.rules?.turnBased) return true;

    const playerParams = this.getPlayerParams(id);
    return playerParams.seat === this.gameParams?.seatTurn;
  };

  getAvailableActions = (id: string = "") => {
    console.log("[GE] calling getAvailableActions()");

    const playerParams = this.getPlayerParams(id);

    const allActions = this.rules?.playerActions;
    let availableActions: Action[] = [];

    allActions?.forEach((action) => {
      const isAvailable = action.isAvailable({
        executingPlayerId: id,
        gameEngine: this,
      });
      if (Array.isArray(isAvailable)) {
        isAvailable.forEach((subaction) => availableActions.push(subaction));
      } else if (isAvailable) {
        availableActions.push(action);
      }
    });

    console.log(`[getAvailableActions] Player ${id} actions`, availableActions);

    // check if availableActions contains Hu
    const filteredActions = availableActions.filter((action) => action.name === "Hu");

    if (filteredActions.length > 0) {
      console.log("Hu action exists.");

      console.log("[Hu] looking for other winners");

      const whoElseCanHu = this.pak.whoElseCan("Hu", {
        executingPlayerId: id,
        gameEngine: this,
      });

      console.log("[getAvailableActions] whoElseCanHu:", whoElseCanHu);

      let otherWinners = whoElseCanHu?.filter((p) => p.canHu === true);
      console.log("[getAvailableActions] otherWinners:", otherWinners);
      console.log("[getAvailableActions] playerParams:", playerParams);

      if (!isCurrentPlayerSeatLeast(playerParams, otherWinners)) {
        // current player canHu but has lower seat priority
        // isWinnable = false;

        availableActions = availableActions.filter((action) => {
          return action.name !== "Hu" && action.name !== "Skip";
        });
        console.log("[getAvailableActions] current player canHu but has lower seat priority hence cant Hu", availableActions);
      } else {
        console.log("[getAvailableActions] have Hu action, player has priority:", availableActions);
      }
    } else {
      console.log("[getAvailableActions] no Hu action", availableActions);
    }

    function isCurrentPlayerSeatLeast(currentPlayer, players) {
      // Loop through each player in the array
      for (let i = 0; i < players.length; i++) {
        // Check if currentPlayer's seat is not the least
        if (currentPlayer.seat >= players[i].seat) {
          return false; // currentPlayer does not have the least seat
        }
      }
      return true; // currentPlayer has the least seat
    }

    console.log("[getAvailableActions] final returning availableActions:", availableActions);
    return availableActions;
  };

  get activePlayerParams() {
    const result: any[] = [];
    this.players?.filter((p) => p.connected || p.autopilot).forEach((player) => result.push(this.getPlayerParams(player.id)));
    return result;
  }

  get joinedPlayerParams() {
    const result: any[] = [];
    this.players
      ?.filter((p) => p.connected)
      .forEach((player) => {
        result.push({ ...player, ...this.getPlayerParams(player.id) });
      });

    let numOfJoinedPlayers = result.length;
    let pendingPlayerParams = {
      connected: false,
      id: null,
      name: "等候玩家",
      spectator: false,
    };

    for (let index = 0; index < 4 - numOfJoinedPlayers; index++) {
      result.push({ ...pendingPlayerParams });
    }

    result.forEach((item, index) => {
      result[index] = { ...item, seat: index };
    });

    // console.log("joinedPlayerParams", result);

    return result;
  }

  get myParams() {
    return this.getPlayerParams(this.uid);
  }

  get mySeat() {
    return this.getPlayerParams(this.uid).seat;
  }

  get lastPlayedCard() {
    return this.gameParams.lastPlay;
  }

  get pak() {
    return Paks[this.pakId];
  }

  get rules() {
    return this.pak?.rules;
  }

  cancelAutopilot = () => {
    this.isOnAutopilot = false;
    this.updateReact();
    return update(ref(this.db, `${this.roomRef}/players/${this.uid}`), { autopilot: false, connected: true });
  };

  //* Firebase Updaters for In Game

  updateGameParams = (newParams: any) => {
    // console.log("[Debug]: Updating Game Params to Firebase", new Date().getTime(), newParams);

    // console.log("📙 Updated Game Params", newParams);
    return update(ref(this.db, `${this.roomRef}/gameParams`), newParams);
  };

  updateSelfPlayerParams = (playerId: string, newParams: any) => {
    let updatedPlayerParams = this.playerParams?.map((player) => {
      // Check if the current item's userId matches the one we're looking for
      if (player.id === playerId) {
        return { ...player, ...newParams };
      }
      // If it doesn't match, return the item as is
      return player;
    });

    this.playerParams = updatedPlayerParams;
    this.updateReact();
  };

  updatePlayer = async (playerId: string, newParams: any) => {
    // console.log("📙 Updated Player", playerId, newParams);
    // adding playerExistInRoomCheck
    // const playerParams = await get(child(ref(this.db), `${this.roomRef}/playerParams`));
    // const currentPlayers = await get(ref(this.db, `${this.roomRef}/players`));
    // let currentPlayersVal = currentPlayers.val();

    // console.log("[updatePlayer]: currentPlayersVal", currentPlayersVal);

    // if (Object.keys(this.players).includes(playerId)) {

    let foundPlayer = this.players.find((player) => player.id === playerId);
    if (foundPlayer) {
      console.log("[updatePlayer]: playerId valid to update, newParams", newParams);

      // playerId is part of the room, allow to update data
      // return
      await update(ref(this.db, `${this.roomRef}/playerParams/${playerId}`), { ...newParams });
    } else {
      console.log("[updatePlayer]: sth went wrong when updating player");
    }
    this.updateReact();
  };

  updateNextTimeoutTimestamp = ({ nextTimeoutTimestamp }) => {
    // this.db.ref(`${this.roomRef}`).update({ nextTimeoutTimestamp });
    update(ref(this.db, `${this.roomRef}`), { nextTimeoutTimestamp });
    this.nextTimeoutTimestamp = nextTimeoutTimestamp;
    this.updateReact();
    return;
  };

  getRoomInfoByPath = async (roomRef) => {
    const roomInfo = await get(child(ref(this.db), roomRef));

    if (!roomInfo.exists()) {
      console.log("room doc doesnt exists returning");
      return;
    }

    return roomInfo.val();
  };
}

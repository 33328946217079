import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import tcTranslation from "./locales/tc/translation.json";

const resources = {
  tc: {
    translation: tcTranslation,
  },
  // fr: {
  //   translation: frTranslation,
  // },
};

i18n
  // .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    resources,
    fallbackLng: "tc", // Default language
    debug: true,
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;

import React, { useContext, useEffect, useState } from "react";
import useSound from "use-sound";

import InGamePage from "../InGame/InGame";

import { useAuthState } from "react-firebase-hooks/auth";
import PuffLoader from "react-spinners/PuffLoader";

// const REVEAL_SFX = require("../../sounds/tile-reveal.mp3");

const GamePlayPage = (props) => {
  const hasPlayed = React.useRef(false);
  const [, setUpdate] = useState(null);
  // const [roomExists, setRoomExists] = useState(null);

  // const playRevealSoundOnce = () => {
  //   if (!hasPlayed.current) {
  //     playSound();
  //     console.log("[EndGame] playing sound");
  //     hasPlayed.current = true;
  //   }
  // };

  return (
    <div className="GamePage-container">
      <InGamePage {...props} />
    </div>
  );
};

export default GamePlayPage;

export async function generateToken(uid) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/generate-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid: uid }),
    });

    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (data) {
      localStorage.setItem("mjToken", data.customToken);
      console.log("mjToken from api:", data.customToken);
      return { mjToken: data.customToken, success: true };
    }
  } catch (error) {
    console.error("Error fetching custom token:", error);
    return { mjToken: null, success: false };
  }
}

export async function getUserBalance(idToken) {
  // console.log("idToken", idToken);

  try {
    // create userProfile in MongoDB
    // console.log("getting user balance");
    console.log("process.env.REACT_APP_BACKEND_HOST_URL", process.env.REACT_APP_BACKEND_HOST_URL);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/userBalance`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("User balance data:", data);

    if (data.usdTokenBalance) {
      return data;
    } else {
      return 0;
    }
  } catch (error) {
    console.log("Error fetching user balance: ", error);
  }
}

export async function getUserWallet(idToken) {
  // console.log("idToken", idToken);

  try {
    // create userProfile in MongoDB
    // console.log("getting user wallet address");
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/userWalletAddress`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    });

    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("User wallet data:", data);

    return data;
  } catch (error) {
    console.log("Error fetching user balance: ", error);
  }
}

export async function postGameResult(gameResult, idToken) {
  try {
    // post game result to MongoDB
    console.log("posting gameplay result", gameResult);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/postGameResult`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ gameResult: gameResult }),
    });
    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("Game result posted:", data);

    return data;
  } catch (error) {
    console.log("Error posting game result: ", error);
  }
}

export async function postGongResult(gongResult, idToken) {
  try {
    // post gong result to MongoDB
    console.log("posting gong result", gongResult);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/postGongResult`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ gongResult: gongResult }),
    });
    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("Gong result posted:", data);

    return data;
  } catch (error) {
    console.log("Error posting Gong result: ", error);
  }
}

export async function checkReferralCode(referralCode, idToken) {
  try {
    // post referral code to MongoDB
    console.log("posting referral code result");
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/checkReferralCode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ referralCode }),
    });
    // if (!response.ok) {
    //   console.log("error response", response);
    //   throw new Error(`HTTP error! status: ${response.status}`);
    // }

    const data = await response.json();
    console.log("check referral posted:", data);

    return data;
  } catch (error) {
    console.log("Error check referral code: ", error);
  }
}

export async function submitWithdrawRequest(withdraw, idToken) {
  try {
    console.log("posting withdraw request result");

    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/submitWithdrawRequest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        withdraw,
      }),
    });

    const data = await response.json();
    console.log("check referral posted:", data);

    return data;
  } catch (error) {
    console.log("Error submitting withdraw request: ", error);
  }
}

export async function tryJoiningRoom(gameMode, priceMode, idToken) {
  try {
    // post user and game details to join room
    console.log("tryJoiningRoom");
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/tryJoiningRoom`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ gameMode, priceMode }),
    });
    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("game details posted:", data);

    return data;
  } catch (error) {
    console.log("Error tryJoiningRoom: ", error);
  }
}

export async function getRoomData(gameMode, priceMode, roomId, idToken) {
  try {
    console.log("getting roomId data", gameMode, priceMode, roomId, idToken);
    console.log("process.env.REACT_APP_BACKEND_HOST_URL", process.env.REACT_APP_BACKEND_HOST_URL);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/getRoomData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ gameMode, priceMode, roomId }),
    });

    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("[API] room data:", data);

    return data;
  } catch (error) {
    console.log("Error fetching room data: ", error);
  }
}

export async function leaveRoom(gameMode, priceMode, roomId, idToken) {
  console.log("posting leaveRoom request");

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/leaveRoom`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ gameMode, priceMode, roomId }),
    });

    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("leaveRoom:", data);

    return data;
  } catch (error) {
    console.log("Error leaveRoom: ", error);
  }
}

export async function getAvailableActions(gameMode, priceMode, roomId, playerId, idToken) {
  console.log("posting getAvailableActions:");
  console.log("posting params", gameMode, priceMode, roomId, playerId, idToken);

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/getAvailableActions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ gameMode, priceMode, roomId, playerId }),
    });

    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("getAvailableActions:", data);

    return data;
  } catch (error) {
    console.log("Error getAvailableActions: ", error);
  }
}

export async function updateNextActionTimeout(gameMode, priceMode, roomId, idToken) {
  console.log("posting /updateNextActionTimeout:");

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/updateNextActionTimeout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ gameMode, priceMode, roomId, idToken }),
    });

    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("/updateNextActionTimeout:", data);

    return data;
  } catch (error) {
    console.log("Error /updateNextActionTimeout: ", error);
  }
}

export async function updateNextDiscardTimeout(gameMode, priceMode, roomId, playerId, idToken) {
  console.log("posting /updateNextDiscardTimeout:");

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/updateNextDiscardTimeout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ gameMode, priceMode, roomId, playerId, idToken }),
    });

    if (!response.ok) {
      console.log("error response", response);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("/updateNextDiscardTimeout:", data);

    return data;
  } catch (error) {
    console.log("Error /updateNextDiscardTimeout: ", error);
  }
}

import React, { useContext, useEffect, useState, useRef } from "react";
import useSound from "use-sound";
import { navigate } from "@reach/router";

import GameEndPage from "../GameEnd/GameEnd";

const EndGamePage = (props) => {
  return (
    <div className="GamePage-container">
      <GameEndPage {...props} />
    </div>
  );
};

export default EndGamePage;

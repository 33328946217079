import React from "react";

import { DrawAction, ChiAction, PengAction, GangAction, AnGangAction, HuAction, SkipAction, AddGangAction } from "./AnAction";

const Action = ({ name, onClick }) => {
  // console.log("current name in Action comp", name);
  if (name === "Draw") {
    // console.log("there is a draw action!");
    return <DrawAction className="action-Draw" onClick={onClick} />;
  }
  // if (name?.includes("Chi")) return <ChiAction className="action-Chi" onClick={onClick} details={name.slice(4)} />;
  if (name === "Peng") return <PengAction className="action-Pong" onClick={onClick} />;
  if (name === "Gang") return <GangAction className="action-Gong" onClick={onClick} />;
  if (name === "Add Gang") return <AddGangAction className="action-AddGang" onClick={onClick} />;
  if (name === "Hu") return <HuAction className={`action-Hu`} onClick={onClick} />;
  if (name === "Hu-Selfdrawn") return <HuAction className={`action-Hu-Selfdrawn`} onClick={onClick} />;
  if (name === "Skip") return <SkipAction className="action-Skip" onClick={onClick} />;
  if (name.includes("An Gang")) return <AnGangAction className="action-AnGang" onClick={onClick} details={name.slice(8)} />;
  return null;
};

export default Action;

import React from "react";
import Box from "ui-box";
import classnames from "classnames";

import "./OpenTile.scss";

export const OpenTile = ({ className, face, vertical = false, ...props }: { className?: string; face?: string; vertical?: boolean }) => {
  // console.log("in OpenTile, face", face);

  return (
    <Box
      className={classnames(className, "OpenTile-container", "Tile-borderRadius")}
      // backgroundImage={face ? `url(${face})` : undefined}
      backgroundImage={`url(${face})`}
      tabIndex={0}
      {...props}
    />
  );
};

export default OpenTile;

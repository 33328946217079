import React, { useEffect, useState } from "react";
import Box from "ui-box";
import cn from "classnames";
import useSound from "use-sound";
import { useTranslation } from "react-i18next";

import "./AnAction.scss";
import ActionAnimation from "./ActionAnimation";

const DRAW_SFX = require("sounds/pick-up.mp3");

const AnAction = ({ className, CN, EN, details, onClick, ...props }) => {
  const [btnHidden, setBtnHidden] = useState(false);

  const handleClick = (evt) => {
    if (onClick) {
      onClick(evt);
      setBtnHidden(true);
    }
  };

  if (btnHidden) {
    return null; // Don't render the component if it's hidden
  }

  return (
    <div className={cn(`${className}-wrapper`, "action-wrapper")}>
      <Box className={cn(className, "AnAction")} role="button" tabIndex="0" onClick={handleClick} {...props}>
        {/* <Box className="AnAction-CN">{CN}</Box>
        <Box className="AnAction-EN">{EN}</Box>
        <Box className="AnAction-details">{details}</Box> */}
      </Box>
      {/* <ActionAnimation key={`ani-${className}`} action={EN} /> */}
    </div>
  );
};

const DrawAction = ({ onClick, ...props }) => {
  const [play, { stop }] = useSound(DRAW_SFX);

  // useEffect(() => {
  //   return () => {
  //     stop();
  //   };
  // }, [stop]);

  const handleClick = (evt) => {
    if (onClick) {
      // console.log("play draw sound");
      play();
      onClick(evt);
    }
  };

  return <AnAction className="Draw" EN="draw" CN="抽" onClick={handleClick} {...props} />;
};

const ChiAction = (props) => <AnAction className="Chi" EN="chi" CN="吃" {...props} />;

const PengAction = (props) => <AnAction className="Peng" EN="peng" CN="碰" {...props} />;

const GangAction = (props) => <AnAction className="Gang" EN="gang" CN="槓" {...props} />;

const AddGangAction = (props) => <AnAction className="AddGang" EN="addgang" CN="加槓" {...props} />;

const AnGangAction = (props) => <AnAction className="AnGang" EN="angang" CN="暗槓" {...props} />;

const HuAction = (props) => <AnAction className={`Hu`} EN="hu" CN="糊" {...props} />;

const SkipAction = (props) => <AnAction className="Skip" EN="skip" CN="過" {...props} />;

export { DrawAction, ChiAction, PengAction, AnGangAction, GangAction, HuAction, SkipAction, AddGangAction };

import React, { useContext, useEffect, useState } from "react";
import Box from "ui-box";

import { useAuth, useGameplay } from "App";
import Seat from "components/Seat";
import CallOut from "components/CallOut";
import _ from "lodash";

import { Orientation } from "components/Seat";
import { getOrientation } from "./Table";
import { getVisualsOf, getOpenHand } from "newEngine/cards";
import { onCardClick } from "newEngine/gameActions";

const Player = ({ player, roomData, data, mode, mySeat, delayStartSeconds, rejoin }) => {
  // console.log("delayStartSeconds", delayStartSeconds);

  const { currentUser, idToken, Auth } = useAuth();
  const latestIdToken = localStorage.getItem("mjToken");

  const { newGE } = useGameplay();

  let closedHand = getVisualsOf(player.closedHand).sort((a, b) => {
    if (a.justDrawn) return 1;
    if (a.params.suit > b.params.suit) return -1;
    if (a.params.suit < b.params.suit) return 1;
    if (a.value > b.value) return 1;
    if (a.value < b.value) return -1;
  });

  const openHand = getOpenHand(player).reduce((acc, meld) => [...acc, getVisualsOf(meld)], []);
  const isSelf = currentUser.uid === player.id;

  let orientation;

  let ori = [Orientation.R, Orientation.T, Orientation.L];

  if (mode === "waiting") {
    if (player.id) {
      orientation = data.filter((data) => data.id === player.id)[0].orientation;
    } else {
      orientation = ori[player.seat - 1];
    }
  } else {
    // console.log("in Player (ingame)");
    // console.log(`player: ${player.id}, player.seat ${player.seat}, mySeat: ${mySeat}`);
    orientation = getOrientation(player.seat, mySeat);
  }
  // console.log("[PLayer] orientation", data, mode, orientation, player);

  useEffect(() => {
    if (isSelf) {
      console.log("nextTimeoutTimestamp changed:", newGE.roomData.nextTimeoutTimestamp);
    }
  }, [newGE.roomData.nextTimeoutTimestamp]);

  const handleTileClick = (tile) => {
    // GE.pak.rules.onCardClick({

    onCardClick({
      executingPlayerId: player.id,
      card: tile,
      // gameEngine: GE,
      gameEngine: newGE,
    });
  };

  return (
    <Box className={"Player Player-" + orientation}>
      <CallOut seat={player.seat} uid={player.id} />

      <Seat
        isSelf={isSelf}
        playerId={player.id}
        idToken={latestIdToken}
        name={player.username}
        seat={player.seat}
        orientation={orientation}
        closedHand={closedHand}
        openHand={openHand}
        onTileClick={_.debounce(handleTileClick, 2000, { leading: true, trailing: false })}
        availableActions={isSelf ? (closedHand.length > 0 ? newGE?.getAvailableActions(player.id) : []) : []}
        // availableActions={gameData?.playerParams[player.id]?.availableActions || []}
        executingParams={{
          executingPlayerId: player.id,
          gameEngine: newGE,
        }}
        player={player}
      />
    </Box>
  );
};

export default Player;

import React from "react";
import Box from "ui-box";
import { FaGear } from "react-icons/fa6";
import { useAuth, useGameplay } from "../../../App";
import { leaveRoom } from "actions/api";
import { navigate } from "@reach/router";
import "./Menu.scss";
import { useTranslation } from "react-i18next";

const Menu = (props) => {
  const { t } = useTranslation();
  const { currentUser, idToken, Auth } = useAuth();
  const { unsubscribeGameplay, newGE } = useGameplay();

  const { roomId, gameMode, priceMode } = props;

  const [showMenu, setShowMenu] = React.useState(false);
  const [menuOption, setMenuOption] = React.useState<string | null>(null);

  const ref = React.useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    if (ref?.current) ref.current.classList.add("Menu-fadeOut");
    setMenuOption(null);

    setTimeout(() => {
      setShowMenu(!showMenu);
      if (ref?.current) ref.current.classList.remove("Menu-fadeOut");
    }, 200);
  };
  const handleLeave = () => setMenuOption("leave");
  // const handleRestart = () => setMenuOption("restart");
  const handleConfirm = async () => {
    // if (menuOption === "restart") GE.returnToLobby();
    if (menuOption === "leave") {
      // let latestIdToken = idToken;
      const latestIdToken = localStorage.getItem("mjToken");

      if (!newGE.gameStarted) {
        leaveRoom(gameMode, priceMode, roomId, latestIdToken);
      }

      unsubscribeGameplay();
      // if (leaveRoomData.success) {
      navigate("/GameModeLobby");
      // }
    }
  };

  return (
    <>
      {showMenu && (
        <Box className="Menu-container" ref={ref}>
          {/* <img className="Menu-background" src="/assets/Brushes/InkBrush.svg" alt="" /> */}
          <Box className="Menu-content">
            {!menuOption && (
              <>
                <button className="Menu-button border-button" onClick={handleLeave}>
                  {/* 退出游戏 */}
                  {t("game_menu.exit_game")}
                </button>
              </>
            )}
            {menuOption && (
              <button className="Menu-button border-button" onClick={handleConfirm}>
                {/* 确定离开 */}
                {t("game_menu.confirm_exit")}
              </button>
            )}
            <button className="Menu-button border-button" onClick={toggleMenu}>
              {/* 返回 */}
              {t("game_menu.back")}
            </button>
          </Box>
        </Box>
      )}
      <button className="Menu-button Menu-openButton" onClick={toggleMenu}>
        <FaGear className="settings-icon" size={36} />
      </button>{" "}
    </>
  );
};

export default Menu;

import _ from "lodash";

// function getPlayerParams(id?: string) {
//   if (!id) return this.pak.rules?.playerParams;
//   const storedParams = this.playerParams?.find((player: any) => id === player.id);
//   return { ...this.pak.rules?.playerParams, ...storedParams };
// }

function reverseTransformPlayersData(playerParams) {
  let transformedPlayerParams = playerParams;
  Object.keys(transformedPlayerParams).forEach((playerId) => {
    const player = transformedPlayerParams[playerId];
    if (player.openHand && typeof player.openHand === "object" && !Array.isArray(player.openHand)) {
      const openHandArray: any[] = [];
      Object.keys(player.openHand).forEach((key) => {
        if (key.startsWith("hand_")) {
          const index = parseInt(key.split("_")[1], 10);
          openHandArray[index] = player.openHand[key];
        }
      });
      player.openHand = openHandArray;
    }
  });

  return transformedPlayerParams;
}

export function createActivePlayerParams(playerParams) {
  // console.log("[createActivePlayerParams] playerParams", playerParams);

  let transformedData = reverseTransformPlayersData(playerParams);
  // return _.values(playerParams);
  return _.values(transformedData);
}

export function createJoinedPlayerParams(players = []) {
  // console.log("[createJoinedPlayerParams]", players);

  let result: any[] = [];

  if (Object.keys(players).length) {
    // Object.keys(players)
    //   // ?.filter((p) => p.connected)
    //   .forEach((player) => {
    //     // result.push({ ...player, ...this.getPlayerParams(player.id) });
    //     result.push({ player });
    //   });

    result = Object.keys(players).map((key) => players[key]);

    let numOfJoinedPlayers = result.length;
    let pendingPlayerParams = {
      connected: false,
      id: null,
      // name: "等候玩家",
      username: "等候玩家",
    };

    for (let index = 0; index < 4 - numOfJoinedPlayers; index++) {
      result.push({ ...pendingPlayerParams });
    }

    result.forEach((item, index) => {
      result[index] = { ...item, seat: index };
    });
  }

  console.log("createJoinedPlayerParams", result);

  return result;
}

// mySeat
// export function getMySeat(playerId, playerParams) {
//   let playerInfo = _.get(playerParams, playerId);
//   return playerInfo?.seat;
// }

// isPlayersTurn(playerId)

function getPlayerParams(playerId, playerParams) {
  return _.get(playerParams, playerId);
}

export function isPlayersTurn(id: string, roomData: any) {
  const player: any = getPlayerParams(id, roomData.playerParams);

  return player.seat === roomData.gameParams?.seatTurn;
}

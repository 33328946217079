import { Action, Card } from "engine/CardPakTypes";
import _ from "lodash";
import { serverTimestamp } from "firebase/database";

import Majiang from "../BaseSet";
import { TileMatrix, ConditionFunction } from "../TileMatrix";
import { makeTiles } from "../Tiles";

import { ClaimReason, DianXinDeck, DianXinGameParams, DianXinPlayerParams, DianXinRules } from "./DianXin.model";
import type { Tile } from "../Tiles.model";
import { oVal } from "utils";
import { GameMode, ACTION_DECISON_TIME, TILE_DISCARD_TIME } from "engine/GameEngine";
import { Decimal } from "decimal.js";
import { postGongResult } from "actions/api";

/**
 * DianXin 点心
 *
 * This is the most basic version of Chinese majiang,
 * based off of Cantonese rules.
 * @class
 */
class DianXin extends Majiang {
  deck: DianXinDeck = {
    visualDeckId: "dx-traditional",
    cards: makeTiles(),
  };
  additionalWinConditions: ConditionFunction[] = [
    function (closedHandMatrix, openHandMatrix) {
      return true;
    },
  ];
  rules: DianXinRules = {
    minSeats: 3,
    maxSeats: 4,
    turnBased: true,
    automaticWin: false,

    gameParams: {
      wall: [],
      deadWall: [],
      lastPlay: null,
      finishAnimationToShow: "",
      showPrizeHorseSelection: false,
    },
    playerParams: {
      closedHand: [], // Tiles in any order
      // openHand: {}, // Array of tiles open to be seen, grouped by meld
      openHand: [],
      playedTiles: [],
      gamePoints: new Decimal(0), // FIXME: Mark this key as to not reset
      skipped: false,
      winner: false,
    },

    onGameStart: (gameEngine) => {
      const shuffledDeck = this.shuffledDeck.map((tile) => ({
        ...tile,
        params: tile.defaultParams,
      }));

      // console.log("[onGameStart]: shuffledDeck", shuffledDeck);
      // console.log("[onGameStart]: shuffledDeck.length", shuffledDeck.length);

      // Deal tiles to walls
      const deadWallSize = 0;
      // Deal tiles to walls
      const deadWallStart = shuffledDeck.length - deadWallSize; //136-14 = 122
      let wall = shuffledDeck?.slice(0, deadWallStart);
      let deadWall = shuffledDeck?.slice(deadWallStart);

      // console.log("[onGameStart]: wall", wall);
      // console.log("[onGameStart]: deadwall", deadWall);

      // Draw tiles from walls
      gameEngine.playerParams?.forEach((player: any) => {
        const playerHand = wall.slice(0, 13);
        wall = wall.slice(13, deadWallStart);

        // console.log(`[onGameStart]: ${player.id} playerHand'`, playerHand);
        // console.log("[onGameStart]: updated wall", wall, wall.length);

        gameEngine.updatePlayer(player.id, {
          ...player,
          closedHand: playerHand,
        });
      });

      // console.log("[onGameStart]: after playerhand allocation: ", wall, deadWall);

      // Set Game Params
      gameEngine.updateGameParams({ wall, deadWall, seatTurn: gameEngine.mySeat });

      console.log("[onGameStart]: GE.mySeat", gameEngine.mySeat);

      if (gameEngine.isHost) {
        console.log("this is the starter of the game: ", gameEngine.uid);

        let availableActions = gameEngine.getAvailableActions(gameEngine.uid);
        let actionFunc = _.find(availableActions, { name: "Draw" });
        let executingParams = { executingPlayerId: gameEngine.uid, gameEngine: gameEngine };

        console.log("[onGameStart] availableActions", availableActions);
        console.log("[onGameStart] actionFunc", actionFunc);
        console.log("[onGameStart] executingParams", executingParams);

        if (gameEngine.autoplayEnabled) {
          gameEngine.setupActionTimer(actionFunc, executingParams);
        }

        gameEngine.updateNextTimeoutTimestamp({
          nextTimeoutTimestamp: { timestamp: ACTION_DECISON_TIME * 1000 + new Date().valueOf(), type: "action" },
        });
      }
    },
    onTurnStart: () => {},
    onTurnEnd: () => {},
    onGameEnd: () => {},
    onCardClick: async ({ executingPlayerId, card, gameEngine }) => {
      console.log(`${executingPlayerId} is clicking`);
      console.log("clicking card", card);

      gameEngine.resetTimers();

      if (!executingPlayerId) return;

      const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      if (!isMyTurn || !card) return;

      const hasAFullHand = this.hasAFullHand(playerParams);
      const tileIsInClosedHand = !!playerParams.closedHand.find((c: Card) => c.id === card.id);
      if (!hasAFullHand || !tileIsInClosedHand) return;

      // Remove card from hand to played
      const closedHand = playerParams.closedHand.filter((c: Card) => c.id !== card.id).map((t: Card) => ({ ...t, justDrawn: false }));
      const playedTiles = [...playerParams.playedTiles, card];
      const newPlayerParams = {
        ...playerParams,
        closedHand,
        playedTiles,
      };

      // gameEngine.updateGameParams({
      //   lastPlay: { card, by: executingPlayerId },
      // });
      // gameEngine.updateSelfPlayerParams(executingPlayerId, newPlayerParams);
      gameEngine.updatePlayer(executingPlayerId, newPlayerParams);

      gameEngine.finishTurn({
        player: executingPlayerId,
        lastPlay: { card, by: executingPlayerId },
      });
      gameEngine.updateNextTimeoutTimestamp(
        {
          nextTimeoutTimestamp: { timestamp: ACTION_DECISON_TIME * 1000 + new Date().valueOf(), type: "action" },
        }
        // { nextTimeoutTimestamp: ACTION_DECISON_TIME * 1000 + new Date().valueOf() }
      );

      const isNoMoreWall = !gameEngine.gameParams?.wall;
      if (isNoMoreWall) {
        gameEngine.updateNextTimeoutTimestamp(
          {
            nextTimeoutTimestamp: { timestamp: ACTION_DECISON_TIME * 1000 + new Date().valueOf(), type: "action" },
          }
          // { nextTimeoutTimestamp: ACTION_DECISON_TIME * 1000 + new Date().valueOf() }
        );
        setTimeout(() => this.endGame(gameEngine), ACTION_DECISON_TIME + 10);
      }
    },

    playerActions: [
      //----------------------------------#01F2DF
      //- Draw
      {
        name: "Draw",
        isAvailable: ({ executingPlayerId, gameEngine }) => {
          const isWallEmpty = !gameEngine.gameParams?.wall;
          const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);

          const hasAFullHand = this.hasAFullHand(playerParams);

          // Deal with conflicting Hu
          const huKing = this.canAnyoneElse("Hu", {
            executingPlayerId,
            gameEngine,
          });

          const huSelfDrawnKing = this.canAnyoneElse("Hu-Selfdrawn", {
            executingPlayerId,
            gameEngine,
          });

          if (huKing || huSelfDrawnKing) return false;

          const awaitingNoOne = this.onlyMyActionOrEveryoneSkipped({
            executingPlayerId,
            gameEngine,
          });

          console.log("[Draw Action]: calling Draw isAvailable:");
          console.log("[Draw Action]: isWallEmpty", isWallEmpty);
          console.log("[Draw Action]: isMyTurn", isMyTurn);
          console.log("[Draw Action]: playerParams", playerParams);
          console.log("[Draw Action]: hasAFullHand", hasAFullHand);
          console.log("[Draw Action]: awaitingNoOne", awaitingNoOne);
          console.log("[Draw Action]: Draw should return:", isWallEmpty || !isMyTurn || hasAFullHand || !awaitingNoOne);

          if (isWallEmpty || !isMyTurn || hasAFullHand || !awaitingNoOne) return false;

          // to make Draw available:
          // Wall not empty (isWallEmpty == false)
          // isMyTurn (isMyTurn == true)
          // dont have 14 tiles (hasAFullHand == false)
          // awaitingNoOne is true (awaitingNoOne == true)
          return true;
        },
        onExecute: async ({ executingPlayerId, gameEngine }) => {
          console.log("Draw is calling onExecute");
          gameEngine.resetTimers();

          const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);

          if (!isMyTurn) {
            return;
          }

          const playerParams = gameEngine.getPlayerParams(executingPlayerId);

          const wall: Card[] = gameEngine.gameParams?.wall;
          const drawnTile = wall.shift();
          const closedHand = [...playerParams.closedHand, { ...drawnTile, justDrawn: true }];

          const newPlayerParams = { closedHand };

          // console.log(`[Draw Action]: playerParams of ${executingPlayerId}`, playerParams);
          // console.log(`[Draw Action]: wall from gameEngine:`, wall);
          // console.log(`[Draw Action]: drawnTile:`, drawnTile);
          // console.log(`[Draw Action]: closedHand:`, closedHand);
          // console.log(`[Draw Action]: newPlayerParams:`, newPlayerParams);

          console.log("Draw calling reset skips");

          // gameEngine.updateSelfPlayerParams(executingPlayerId, { ...newPlayerParams, skipped: false });
          this.resetSkips(gameEngine);
          gameEngine.updateGameParams({ wall, lastPlay: null });
          gameEngine.updatePlayer(executingPlayerId, newPlayerParams);

          let actionFunc = this.rules.onCardClick;
          let executingParams = { executingPlayerId, card: drawnTile, gameEngine };

          console.log("[Draw onExecute]: setting up DiscardTimer", executingParams);

          if (gameEngine.autoplayEnabled) {
            gameEngine.setupDiscardTimer(actionFunc, executingParams);
          }

          gameEngine.updateNextTimeoutTimestamp(
            {
              nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
            }
            // { nextTimeoutTimestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf() }
          );
          gameEngine.updateReact();
        },
      },
      //----------------------------------#01F2DF
      //- Peng
      {
        name: "Peng",
        isAvailable: ({ executingPlayerId, gameEngine }) => {
          // Deal with conflicting Hu
          const huKing = this.canAnyoneElse("Hu", {
            executingPlayerId,
            gameEngine,
          });

          const huSelfDrawnKing = this.canAnyoneElse("Hu-Selfdrawn", {
            executingPlayerId,
            gameEngine,
          });

          if (huKing || huSelfDrawnKing) return false;

          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const lastPlay = gameEngine.gameParams?.lastPlay;
          const notMyPlay = lastPlay?.by !== executingPlayerId;
          const makesAMeld = this.canIPeng(playerParams, lastPlay);
          const hasFullHand = this.hasAFullHand(playerParams);
          // alreadySkipped is always false as Draw will reset all skips
          const alreadySkipped = playerParams.skipped;

          // console.log("[Peng] alreadySkipped", alreadySkipped);

          // console.log("[Peng Action]: calling Peng isAvailable:");
          // console.log("[Peng Action]:playerParams", playerParams);
          // console.log("[Peng Action]:lastPlay", lastPlay);
          // console.log("[Peng Action]:notMyPlay", notMyPlay);
          // console.log("[Peng Action]:makesAMeld", makesAMeld);
          // console.log("[Peng Action]:hasFullHand", hasFullHand);
          // console.log("[Peng Action]:alreadySkipped", alreadySkipped);
          // console.log("[Peng Action]: should return", notMyPlay && makesAMeld && !hasFullHand && !alreadySkipped);

          return notMyPlay && makesAMeld && !hasFullHand && !alreadySkipped;
        },
        onExecute: async ({ executingPlayerId, gameEngine }) => {
          // console.log("Peng is calling onExecute");
          gameEngine.resetTimers();

          const lastPlay = gameEngine.gameParams?.lastPlay;
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);

          // Remove the tile from playedTiles of other player
          this.removeLastPlayedTile({ executingPlayerId, gameEngine });

          // Put the tile and your matching tiles in openHand
          const matching = playerParams.closedHand.filter(this.matchInValueAndSuit(lastPlay.card)).slice(0, 2);
          // const matching = playerParams.closedHand.filter(this.matchInValueAndSuit(lastPlay.card));
          // console.log("[Peng onExecute]: matching", matching);

          const meld = [lastPlay.card, ...matching];
          const openHand = this.createOpenHandWith(playerParams, meld);
          const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);
          const newPlayerParams = { openHand, closedHand };

          console.log("[Peng Execute] newPlayerParams", newPlayerParams);

          // Update params
          // gameEngine.updateSelfPlayerParams(executingPlayerId, { ...newPlayerParams, skipped: false });
          this.resetSkips(gameEngine);
          gameEngine.updatePlayer(executingPlayerId, newPlayerParams);
          gameEngine.updateGameParams({ lastPlay: null });
          gameEngine.claimTurn(executingPlayerId, ClaimReason.PENG);

          //auto discard logic
          let actionFunc = this.rules.onCardClick;
          let executingParams = { executingPlayerId, card: closedHand[0], gameEngine };

          // console.log("[Peng onExecute]: setting up DiscardTimer", executingParams);

          if (gameEngine.autoplayEnabled) {
            gameEngine.setupDiscardTimer(actionFunc, executingParams);
          }

          gameEngine.updateNextTimeoutTimestamp(
            {
              nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
            }
            // { nextTimeoutTimestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf() }
          );
          gameEngine.updateReact();
        },
      },
      //----------------------------------#01F2DF
      //- Add Gang
      {
        name: "Add Gang",
        isAvailable: ({ executingPlayerId, gameEngine }) => {
          // Deal with conflicting Hu
          const huKing = this.canAnyoneElse("Hu", {
            executingPlayerId,
            gameEngine,
          });

          const huSelfDrawnKing = this.canAnyoneElse("Hu-Selfdrawn", {
            executingPlayerId,
            gameEngine,
          });

          if (huKing || huSelfDrawnKing) return false;

          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
          const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];

          if (!lastDrawnTile) return false;

          const makesAMeld = this.canIAddGang(playerParams, lastDrawnTile);
          const alreadySkipped = playerParams.skipped;

          // console.log("[AddGang] playerParams: ", playerParams);
          // console.log("[AddGang] isMyTurn: ", isMyTurn);
          // console.log("[AddGang] lastDrawnTile: ", lastDrawnTile);
          // console.log("[AddGang] makesAMeld: ", makesAMeld);
          // console.log("[AddGang] alreadySkipped: ", alreadySkipped);

          if (makesAMeld.length === 0 || !isMyTurn || alreadySkipped) {
            return false;
          } else {
            return true;
          }
        },

        onExecute: async ({ executingPlayerId, gameEngine }) => {
          // console.log("AddGang onExecute got clicked");
          gameEngine.resetTimers();

          // const lastPlay = gameEngine.gameParams?.lastPlay;
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];

          // console.log("[AddGang onExecute]: lastDrawnTile", lastDrawnTile);

          let matching = [];
          let matchedMeld;
          let matchedMeldIndex;

          const tile = lastDrawnTile;

          playerParams.openHand.forEach((meld, index) => {
            matchedMeld = meld.filter(this.matchInValueAndSuit(tile));

            if (matchedMeld.length === 3) {
              matching = matchedMeld;
              matchedMeldIndex = index;
              // console.log("[AddGang onExecute]: found matching meld in openhand & its Index", matching, matchedMeldIndex);
            }
          });
          // console.log("[AddGang onExecute]: matching", matching);

          const meld = [lastDrawnTile, ...matching];
          // const meld = [...matching];
          // const openHand = this.createOpenHandWith(playerParams, meld);
          let openHand = [...playerParams.openHand];
          openHand[matchedMeldIndex] = meld;

          // logic to filter out meld tile from closed hand
          const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);

          // ------- logic to draw new tile and discard

          // Draw another tile
          // const deadWall: Card[] = gameEngine.gameParams?.deadWall;
          // const drawnTile = deadWall.shift();
          const wall: Card[] = gameEngine.gameParams?.wall;
          const drawnTile = wall.shift();
          closedHand.push({ ...drawnTile, justDrawn: true });

          const basePrice = gameEngine.basePrice;

          const currentGongPoints = playerParams.gongPoints;

          // new implementation
          let winnerGongPoints = basePrice.times(3);
          let winnerGongPointsAfterComm = new Decimal(1).minus(gameEngine.houseCommission).times(winnerGongPoints); // (Gong point - comm) in this action

          let winnerCurrentGongPoints = winnerGongPointsAfterComm.plus(new Decimal(currentGongPoints));

          const newPlayerParams = { openHand, closedHand, availableActions: [], gongPoints: winnerCurrentGongPoints.toFixed(6) };

          // Update params for player
          // gameEngine.updateSelfPlayerParams(executingPlayerId, { ...newPlayerParams, skipped: false });
          this.resetSkips(gameEngine);
          gameEngine.updatePlayer(executingPlayerId, newPlayerParams);

          // Update params for other players
          let otherPlayers = gameEngine.getOtherPlayersButId(executingPlayerId);
          let otherPlayersGongPoints: any[] = [];

          otherPlayers?.forEach((player, index) => {
            let currentPlayerGongPoints = player.gongPoints;
            let otherPlayerGongPoints = basePrice.times(-1);

            gameEngine.updatePlayer(player.id, { gongPoints: otherPlayerGongPoints.plus(new Decimal(currentPlayerGongPoints)).toFixed(6) });

            otherPlayersGongPoints.push({
              uid: player.id,
              gongPoints: otherPlayerGongPoints,
              netGongPoints: otherPlayerGongPoints,
            });
          });

          // send gong result to API
          let gongResult = {
            gameMode: gameEngine.gameMode,
            priceMode: gameEngine.priceMode,
            roomRef: gameEngine.roomRef,
            roomId: gameEngine.roomId,
            playersWithPoints: [
              ...otherPlayersGongPoints,
              {
                uid: executingPlayerId,
                gongPoints: winnerGongPoints,
                netGongPoints: winnerGongPointsAfterComm,
              },
            ],
          };

          gameEngine.updateGameParams({
            lastPlay: null,
            wall,
            // state: {
            //   status: GameStatus.CLAIMED_ANGANG,
            //   actionBy: executingPlayerId,
            //   timestamp: new Date().valueOf(),
            //   discardedTile: null,
            //   drawnTile: drawnTile,
            // },
            // seatReason: ClaimReason.ANGANG,
          });
          gameEngine.claimTurn(executingPlayerId, ClaimReason.ADDGANG);

          let latestIdToken = await gameEngine.firebaseAuth.currentUser.getIdToken(true);
          let result = await postGongResult(gongResult, latestIdToken);

          console.log("[Add Gong] Post Gong result: ", result);

          //auto discard logic
          let actionFunc = this.rules.onCardClick;
          let executingParams = { executingPlayerId, card: drawnTile, gameEngine };

          // console.log("[AddGang onExecute]: setting up DiscardTimer", executingParams);

          if (gameEngine.autoplayEnabled) {
            gameEngine.setupDiscardTimer(actionFunc, executingParams);
          }

          gameEngine.updateNextTimeoutTimestamp(
            {
              nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
            }
            // { nextTimeoutTimestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf() }
          );
          gameEngine.updateReact();
        },
      },
      //----------------------------------#01F2DF
      //- An Gang
      {
        name: "An Gang",
        isAvailable: ({ executingPlayerId, gameEngine }) => {
          // Deal with conflicting Hu
          const huKing = this.canAnyoneElse("Hu", {
            executingPlayerId,
            gameEngine,
          });

          const huSelfDrawnKing = this.canAnyoneElse("Hu-Selfdrawn", {
            executingPlayerId,
            gameEngine,
          });

          if (huKing || huSelfDrawnKing) return false;

          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);

          const makesAMeld = this.canIAnGang(playerParams);
          const alreadySkipped = playerParams.skipped;

          const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];

          if (!lastDrawnTile) return false;

          if (makesAMeld.length === 0 || !isMyTurn || alreadySkipped) {
            return false;
          } else {
            console.log("[AnGang] makesAMeld:", makesAMeld);

            return true;
          }
        },
        // const availableMelds: Action[] = makesAMeld.map((tile) => ({
        //   name: `An Gang ${tile.name}`,
        //   isAvailable: () => true,
        //   onExecute: ({ executingPlayerId, gameEngine }) => {
        //     const playerParams = gameEngine.getPlayerParams(executingPlayerId);

        //     // Put the tile and your matching tiles in openHand
        //     const meld = playerParams.closedHand.filter(this.matchInValueAndSuit(tile)).map((t: Tile) => ({ ...t, hide: true }));
        //     const openHand = this.createOpenHandWith(playerParams, meld);
        //     const closedHand = playerParams.closedHand.filter((t: Tile) => meld.findIndex((m: Tile) => m.id === t.id) === -1);

        //     // Draw another tile
        //     // const deadWall: Card[] = gameEngine.gameParams?.deadWall;
        //     // const drawnTile = deadWall.shift();
        //     const wall: Card[] = gameEngine.gameParams?.wall;
        //     const drawnTile = wall.shift();
        //     closedHand.push({ ...drawnTile, justDrawn: true });
        //     const newPlayerParams = { openHand, closedHand };

        //     // Update params
        //     this.resetSkips(gameEngine);
        //     gameEngine.updatePlayer(executingPlayerId, newPlayerParams);
        //     gameEngine.updateGameParams({ lastPlay: null, wall });
        //     gameEngine.claimTurn(executingPlayerId, ClaimReason.ANGANG);
        //   },
        // }));
        // return availableMelds;

        onExecute: async ({ executingPlayerId, gameEngine }) => {
          console.log("AnGang onExecute got clicked");
          gameEngine.resetTimers();

          // const lastPlay = gameEngine.gameParams?.lastPlay;
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const makesAMeld = this.canIAnGang(playerParams);
          const gangTile = makesAMeld[0];

          // const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];

          console.log("[AnGang onExecute]: gangTile", gangTile);

          // Put the tile and your matching tiles in openHand
          const matching = playerParams.closedHand.filter(this.matchInValueAndSuit(gangTile));
          console.log("[AnGang onExecute]: matching", matching);

          // const meld = [lastDrawnTile, ...matching];
          const meld = [...matching];
          const openHand = this.createOpenHandWith(playerParams, meld);
          const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);

          // Draw another tile
          // const deadWall: Card[] = gameEngine.gameParams?.deadWall;
          // const drawnTile = deadWall.shift();
          const wall: Card[] = gameEngine.gameParams?.wall;
          const drawnTile = wall.shift();
          closedHand.push({ ...drawnTile, justDrawn: true });

          const specialPrice = gameEngine.specialPrice;
          const currentGongPoints = playerParams.gongPoints;

          console.log("[AN GANG] specialPrice", specialPrice.toFixed(2));
          console.log("[AN GANG] currentGongPoints", currentGongPoints);

          let winnerGongPoints = specialPrice.times(3);
          let winnerGongPointsAfterComm = new Decimal(1).minus(gameEngine.houseCommission).times(winnerGongPoints); // (Gong point - comm) in this action

          let winnerCurrentGongPoints = winnerGongPointsAfterComm.plus(new Decimal(currentGongPoints));

          console.log("[AN GANG] winnerCurrentGongPoints", winnerCurrentGongPoints.toFixed(2));

          const newPlayerParams = { openHand, closedHand, availableActions: [], gongPoints: winnerCurrentGongPoints.toFixed(6) };

          // Update params for player
          // gameEngine.updateSelfPlayerParams(executingPlayerId, { ...newPlayerParams, skipped: false });

          this.resetSkips(gameEngine);
          gameEngine.updatePlayer(executingPlayerId, newPlayerParams);

          // Update params for other players
          let otherPlayers = gameEngine.getOtherPlayersButId(executingPlayerId);
          let otherPlayersGongPoints: any[] = [];

          otherPlayers?.forEach((player, index) => {
            let currentPlayerGongPoints = player.gongPoints;
            let otherPlayerGongPoints = specialPrice.times(-1);

            gameEngine.updatePlayer(player.id, {
              gongPoints: otherPlayerGongPoints.plus(new Decimal(currentPlayerGongPoints)).toFixed(6),
            });

            otherPlayersGongPoints.push({
              uid: player.id,
              gongPoints: otherPlayerGongPoints,
              netGongPoints: otherPlayerGongPoints,
            });
          });

          // send gong result to API
          let gongResult = {
            gameMode: gameEngine.gameMode,
            priceMode: gameEngine.priceMode,
            roomRef: gameEngine.roomRef,
            roomId: gameEngine.roomId,
            playersWithPoints: [
              ...otherPlayersGongPoints,
              {
                uid: executingPlayerId,
                gongPoints: winnerGongPoints,
                netGongPoints: winnerGongPointsAfterComm,
              },
            ],
          };

          gameEngine.updateGameParams({
            lastPlay: null,
            wall,
            // state: {
            //   status: GameStatus.CLAIMED_ANGANG,
            //   actionBy: executingPlayerId,
            //   timestamp: new Date().valueOf(),
            //   discardedTile: null,
            //   drawnTile: drawnTile,
            // },
            // seatReason: ClaimReason.ANGANG,
          });
          gameEngine.claimTurn(executingPlayerId, ClaimReason.ANGANG);

          let latestIdToken = await gameEngine.firebaseAuth.currentUser.getIdToken(true);
          let result = await postGongResult(gongResult, latestIdToken);

          console.log("[An Gong] Post Gong result: ", result);

          //auto discard logic
          let actionFunc = this.rules.onCardClick;
          let executingParams = { executingPlayerId, card: drawnTile, gameEngine };

          console.log("[AnGang onExecute]: setting up DiscardTimer", executingParams);

          if (gameEngine.autoplayEnabled) {
            gameEngine.setupDiscardTimer(actionFunc, executingParams);
          }

          gameEngine.updateNextTimeoutTimestamp(
            {
              nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
            }
            // { nextTimeoutTimestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf() }
          );
          gameEngine.updateReact();
        },
      },
      //----------------------------------#01F2DF
      //- Gang
      {
        name: "Gang",
        isAvailable: ({ executingPlayerId, gameEngine }) => {
          // Deal with conflicting Hu
          const huKing = this.canAnyoneElse("Hu", {
            executingPlayerId,
            gameEngine,
          });

          const huSelfDrawnKing = this.canAnyoneElse("Hu-Selfdrawn", {
            executingPlayerId,
            gameEngine,
          });

          if (huKing || huSelfDrawnKing) return false;

          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const lastPlay = gameEngine.gameParams?.lastPlay;
          const makesAMeld = this.canIGang(playerParams, lastPlay);
          const hasFullHand = this.hasAFullHand(playerParams);
          const alreadySkipped = playerParams.skipped;

          return makesAMeld && !hasFullHand && !alreadySkipped;
        },
        onExecute: async ({ executingPlayerId, gameEngine }) => {
          console.log("Gang onExecute got clicked");
          gameEngine.resetTimers();

          const lastPlay = gameEngine.gameParams?.lastPlay;
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);

          // Remove the tile from playedTiles of other player
          this.removeLastPlayedTile({ executingPlayerId, gameEngine });

          // Put the tile and your matching tiles in openHand
          // const matching = playerParams.closedHand.filter(this.matchInValueAndSuit(lastPlay.card)).slice(0, 3);
          const matching = playerParams.closedHand.filter(this.matchInValueAndSuit(lastPlay.card));

          console.log("[Gang onExecute]: matching", matching);

          const meld = [lastPlay.card, ...matching];
          const openHand = this.createOpenHandWith(playerParams, meld);
          const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);

          // Draw another tile
          // const deadWall: Card[] = gameEngine.gameParams?.deadWall;
          // const drawnTile = deadWall.shift();
          const wall: Card[] = gameEngine.gameParams?.wall;
          const drawnTile = wall.shift();
          closedHand.push({ ...drawnTile, justDrawn: true });

          const basePrice = gameEngine.basePrice;
          // const specialPrice = gameEngine.specialPrice;
          const currentGongPoints = playerParams.gongPoints;

          console.log("[GANG] currentGongPoints", currentGongPoints);

          let winnerGongPoints = basePrice.times(3);
          let winnerGongPointsAfterComm = new Decimal(1).minus(gameEngine.houseCommission).times(winnerGongPoints); // (Gong point - comm) in this action

          let winnerCurrentGongPoints = winnerGongPointsAfterComm.plus(new Decimal(currentGongPoints));
          // console.log("[GANG] winnerCurrentGongPoints", winnerCurrentGongPoints.toFixed(2));

          const newPlayerParams = { openHand, closedHand, gongPoints: winnerCurrentGongPoints.toFixed(6) };

          // Update params for gong-win player
          // gameEngine.updateSelfPlayerParams(executingPlayerId, { ...newPlayerParams, skipped: false });
          this.resetSkips(gameEngine);
          gameEngine.updatePlayer(executingPlayerId, newPlayerParams);

          // Update params for other player (gong-lose)
          const discardBy = lastPlay.by;
          let currentPlayerGongPoints = gameEngine.getPlayerParams(discardBy).gongPoints;
          let gongLosePlayerGongPoints = basePrice.times(-3);

          gameEngine.updatePlayer(discardBy, {
            gongPoints: gongLosePlayerGongPoints.plus(new Decimal(currentPlayerGongPoints)).toFixed(6),
          });

          // send gong result to API
          let gongResult = {
            gameMode: gameEngine.gameMode,
            priceMode: gameEngine.priceMode,
            roomRef: gameEngine.roomRef,
            roomId: gameEngine.roomId,
            playersWithPoints: [
              {
                uid: executingPlayerId,
                gongPoints: winnerGongPoints,
                netGongPoints: winnerGongPointsAfterComm,
              },
              {
                uid: discardBy,
                gongPoints: gongLosePlayerGongPoints,
                netGongPoints: gongLosePlayerGongPoints,
              },
            ],
          };

          // gameEngine.updateGameParams({ lastPlay: null, deadWall });
          gameEngine.updateGameParams({ lastPlay: null, wall });
          gameEngine.claimTurn(executingPlayerId, ClaimReason.GANG, lastPlay);

          let latestIdToken = await gameEngine.firebaseAuth.currentUser.getIdToken(true);
          let result = await postGongResult(gongResult, latestIdToken);

          console.log("[Gong] Post Gong result: ", result);

          //auto discard logic
          let actionFunc = this.rules.onCardClick;
          let executingParams = { executingPlayerId, card: drawnTile, gameEngine };

          console.log("[Gang onExecute]: setting up DiscardTimer", executingParams);

          if (gameEngine.autoplayEnabled) {
            gameEngine.setupDiscardTimer(actionFunc, executingParams);
          }

          gameEngine.updateNextTimeoutTimestamp(
            {
              nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
            }
            // { nextTimeoutTimestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf() }
          );
          gameEngine.updateReact();
        },
      },
      // //----------------------------------#01F2DF
      // //- Chi
      // {
      //   name: "Chi",
      //   isAvailable: ({ executingPlayerId, gameEngine }) => {
      //     // Deal with conflicting Hu
      //     const huKing = this.canAnyoneElse("Hu", {
      //       executingPlayerId,
      //       gameEngine,
      //     });
      //     if (huKing) return false;

      //     const lastPlay = gameEngine.gameParams?.lastPlay;
      //     if (!lastPlay) return false;

      //     const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      //     const alreadySkipped = playerParams.skipped;
      //     if (alreadySkipped) return false;

      //     // Deal with conflicting Peng
      //     const pengKing = this.canAnyoneElse("Peng", {
      //       executingPlayerId,
      //       gameEngine,
      //     });
      //     if (pengKing) return false;

      //     // You can't chi after you've already drawn
      //     const hasFullHand = this.hasAFullHand(playerParams);
      //     if (hasFullHand) return false;

      //     // Was last play was by the previous player
      //     const lastPlayerParams = gameEngine.getPlayerParams(lastPlay?.by);
      //     const lastWasRightBeforeMe = (playerParams.seat - lastPlayerParams.seat + 4) % 4 === 1;
      //     if (!lastWasRightBeforeMe) return false;

      //     // Does it finish one of your melds?
      //     const makesAMeld = this.canIChi(playerParams, lastPlay);
      //     if (!makesAMeld) return false;

      //     // Make array of actions from ones that start a meld
      //     const availableMelds: Action[] = makesAMeld.map((startTile) => ({
      //       name: `Chi ${startTile.value} ${Number(startTile.value) + 1} ${Number(startTile.value) + 2}`,
      //       isAvailable: () => true,
      //       onExecute: ({ executingPlayerId, gameEngine }) => {
      //         const lastPlay = gameEngine.gameParams?.lastPlay;
      //         const playerParams = gameEngine.getPlayerParams(executingPlayerId);

      //         // Remove the tile from playedTiles of other player
      //         this.removeLastPlayedTile({ executingPlayerId, gameEngine });

      //         // Put the tile and your matching tiles in openHand
      //         const meld = [lastPlay.card, ...playerParams.closedHand].filter(this.matchStaircaseStartingAt(startTile));
      //         const openHand = this.createOpenHandWith(playerParams, meld);
      //         const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);
      //         const newPlayerParams = { openHand, closedHand };

      //         // Update params
      //         this.resetSkips(gameEngine);
      //         gameEngine.updatePlayer(executingPlayerId, newPlayerParams);
      //         gameEngine.updateGameParams({ lastPlay: null });
      //         gameEngine.claimTurn(executingPlayerId, ClaimReason.CHI);
      //       },
      //     }));
      //     if (availableMelds.length === 0) return false;
      //     return availableMelds;
      //   },
      //   onExecute: () => {},
      // },
      //----------------------------------#01F2DF
      //- Hu
      {
        name: "Hu",
        isAvailable: ({ executingPlayerId, gameEngine }) => {
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const hasFullHand = this.hasAFullHand(playerParams);
          const lastPlay = gameEngine.gameParams?.lastPlay;
          const alreadySkipped = playerParams.skipped;
          const gameMode = gameEngine.gameMode;

          let isHandJustDrawn;
          if (alreadySkipped) return false;

          if (gameMode === GameMode.selfDrawn) return false;

          let hand: Tile[] = [];

          // console.log("calling is Hu available()...");

          // console.log("[in Hu available] playerParams", playerParams);
          // console.log("[in Hu available] hasFullHand", hasFullHand);
          // console.log("[in Hu available] lastPlay", lastPlay);
          // console.log("[in Hu available] alreadySkipped", alreadySkipped);
          // console.log("[in Hu available] gameMode", gameMode);

          // If your closed hand is full, check your closed
          // hand is winnable
          if (hasFullHand) {
            hand = playerParams.closedHand;
            const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];
            if (lastDrawnTile) {
              isHandJustDrawn = true;
            } else {
              return false;
            }
          }
          // Otherwise, try adding the last played tile to your
          // hand and see if that makes it a winning thing
          else if (!!lastPlay) {
            hand = [lastPlay.card, ...playerParams.closedHand];
            isHandJustDrawn = false;
          }

          if (hand.length === 0) return false;

          const openHand: Tile[] = oVal(playerParams?.openHand || {});
          const tileMatrix = new TileMatrix(hand, openHand, this.additionalWinConditions);

          // console.log("[Hu] checking if winnable", tileMatrix);
          let isWinnable = tileMatrix.isWinnable;

          // console.log("[Hu] isWinnable", isWinnable);

          if (isWinnable) {
            if (gameMode === GameMode.basic) {
              // console.log("final checking that only Baisc mode is working");

              if (isHandJustDrawn) {
                isWinnable = false;
                // isHandJustDrawn true should lead to Hu-selfdrawn
              } else {
                console.log("[Hu] isWinnable true and not just drawn");
                isWinnable = true;
              }
            } else {
              console.log("[Hu] Sth wrong that it doesnt win: ", gameMode, hand);
              isWinnable = false;
            }
          }

          // console.log("[Hu] check final isWinnable, GameMode, isSelfDrawn ", isWinnable, gameMode, isHandJustDrawn);

          return isWinnable;
        },
        onExecute: async ({ executingPlayerId, gameEngine }) => {
          console.log("Hu onExecute got clicked");

          gameEngine.resetTimers();

          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const hasFullHand = this.hasAFullHand(playerParams);
          const lastPlay = gameEngine.gameParams?.lastPlay;

          let hand;

          if (hasFullHand) {
            // self-drawn
            console.log("sth wrong here as it should be fullhand");

            hand = playerParams.closedHand;
          } else {
            // constructing the final hand
            hand = [lastPlay.card, ...playerParams.closedHand];
          }
          // discardBy = 出銃
          const newParams = { closedHand: hand, huReason: "winFromOthers", discardBy: lastPlay.by };

          this.resetSkips(gameEngine);
          this.endGame(gameEngine, executingPlayerId, newParams);
        },
      },
      //----------------------------------#01F2DF
      //- Hu-Selfdrawn
      {
        name: "Hu-Selfdrawn",
        isAvailable: ({ executingPlayerId, gameEngine }) => {
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const hasFullHand = this.hasAFullHand(playerParams);
          const lastPlay = gameEngine.gameParams?.lastPlay;
          const alreadySkipped = playerParams.skipped;
          const gameMode = gameEngine.gameMode;

          let isHandJustDrawn = false;
          if (alreadySkipped) return false;

          let hand: Tile[] = [];

          // if (executingPlayerId === gameEngine.uid) {
          //   console.log("calling Hu-Selfdrawn isAvailable()");
          //   console.log("[in Hu-Selfdrawn available] playerParams", playerParams);
          //   console.log("[in Hu-Selfdrawn available] hasFullHand", hasFullHand);
          //   console.log("[in Hu-Selfdrawn available] lastPlay", lastPlay);
          //   console.log("[in Hu-Selfdrawn available] alreadySkipped", alreadySkipped);
          //   console.log("[in Hu-Selfdrawn available] gameMode", gameMode);
          // }

          // If your closed hand is full, check your closed
          // hand is winnable
          if (hasFullHand) {
            // console.log("[Hu-Selfdrawn] hasFullHand is true la");

            hand = playerParams.closedHand;
            const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];
            if (lastDrawnTile) {
              isHandJustDrawn = true;
            } else {
              return false;
            }
          }
          // different logic than noraml Hu as no need to check for lastPlay or seatReason
          // this would allow Gong -> Self-drawn

          // else if (!!lastPlay || gameEngine?.gameParams?.seatReason !== null) {
          //   // console.log("there is lastPlay", lastPlay);
          //   // console.log("there is seatReason", gameEngine?.gameParams?.seatReason);
          //   // console.log("so Hu-Selfdrawn not callings");

          //   // hand = [lastPlay.card, ...playerParams.closedHand];
          //   // isHandJustDrawn = false;
          //   return false;
          // }

          if (hand.length === 0) return false;

          const openHand: Tile[] = oVal(playerParams?.openHand || {});
          const tileMatrix = new TileMatrix(hand, openHand, this.additionalWinConditions);

          // console.log("[Hu Selfdrawn] checking if winnable", tileMatrix);
          let isWinnable = tileMatrix.isWinnable;

          // console.log("[Hu Selfdrawn] isWinnable", isWinnable);

          // final check on isWinnable and whether it is really self-drawn
          if (isWinnable && isHandJustDrawn) {
            isWinnable = true;
          } else {
            isWinnable = false;
          }

          // if (isWinnable) {
          //   if (gameEngine.gameMode === GameMode.basic) {
          //     isWinnable = true;
          //   } else if (gameEngine.gameMode === GameMode.selfDrawn) {
          //     if (isHandJustDrawn) {
          //       isWinnable = true;
          //     }
          //   } else {
          //     console.log("[Hu] Sth wrong that it doesnt win: ", gameEngine.gameMode, hand, isHandJustDrawn);
          //   }
          // }

          // console.log("[Hu Selfdrawn] check final isWinnable, GameMode, isSelfDrawn ", isWinnable, gameMode, hand, isHandJustDrawn);

          return isWinnable;
        },
        onExecute: async ({ executingPlayerId, gameEngine }) => {
          console.log("Hu-Selfdrawn onExecute got clicked");
          gameEngine.resetTimers();

          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const hasFullHand = this.hasAFullHand(playerParams);
          const lastPlay = gameEngine.gameParams?.lastPlay;

          let hand;
          if (hasFullHand) {
            // self-drawn scenario
            hand = playerParams.closedHand;
          } else {
            console.log("sth wrong here as it should have fullhand");

            hand = [lastPlay.card, ...playerParams.closedHand];
          }
          const newParams = { closedHand: hand, huReason: "selfDrawn" };

          this.resetSkips(gameEngine);
          this.endGame(gameEngine, executingPlayerId, newParams);
        },
      },
      //----------------------------------#01F2DF
      //- Skip
      {
        name: "Skip",
        isAvailable: ({ executingPlayerId, gameEngine }) => {
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
          const alreadySkipped = playerParams.skipped;

          // console.log("calling skip isAvailable() by, ", executingPlayerId);
          // console.log("[skip isAvailable()], alreadySkipped", alreadySkipped);

          const actions = this.getAvailableActions({
            executingPlayerId,
            gameEngine,
          });
          const hasAvailableActions = actions.length > 0;

          // not sure if it should include isMyTurn
          // if (alreadySkipped || !hasAvailableActions || isMyTurn) return false;
          if (alreadySkipped || !hasAvailableActions) return false;
          return true;
        },
        onExecute: async ({ executingPlayerId, gameEngine }) => {
          console.log("Skip onExecute got clicked");
          gameEngine.resetTimers();

          // gameEngine.updateSelfPlayerParams(executingPlayerId, { skipped: true });
          gameEngine.updatePlayer(executingPlayerId, { skipped: true });
          // gameEngine.removeAvailableActionsOnSkip(executingPlayerId);

          // handle skip logic autoplay if have full hands
          const playerParams = gameEngine.getPlayerParams(executingPlayerId);
          const hasFullHand = this.hasAFullHand(playerParams);

          if (hasFullHand) {
            console.log("[Skip onExecute]: player hasFullHand", hasFullHand);
            //auto discard logic
            let actionFunc = this.rules.onCardClick;
            let executingParams = { executingPlayerId, card: playerParams.closedHand[playerParams.closedHand.length - 1], gameEngine };

            console.log("[Skip onExecute]: setting up DiscardTimer", executingParams);

            if (gameEngine.autoplayEnabled) {
              gameEngine.setupDiscardTimer(actionFunc, executingParams);
            }
          }

          gameEngine.updateNextTimeoutTimestamp({
            nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
          });
          gameEngine.updateReact();
        },
      },
    ],
  };

  FULL_HAND_SIZE = 14;

  constructor(props: any, id?: string) {
    super(props, id || "mhjng-dianxin");
  }

  //* Helper Getters
  canIPeng = ({ closedHand }: DianXinPlayerParams, lastPlay: DianXinGameParams["lastPlay"]) => {
    if (!lastPlay) return false;

    const tile = lastPlay.card;
    const matching = closedHand.filter(this.matchInValueAndSuit(tile));
    return matching.length >= 2;
  };

  canIGang = ({ closedHand }: DianXinPlayerParams, lastPlay: DianXinGameParams["lastPlay"]) => {
    if (!lastPlay) return false;

    const tile = lastPlay.card;
    const matching = closedHand.filter(this.matchInValueAndSuit(tile));
    return matching.length >= 3;
  };

  canIAddGang = ({ openHand }, lastDrawn) => {
    // console.log("[canIAddGang] calling, openHand, lastDrawn:", openHand, lastDrawn);

    let matching = [];
    let matchedMeld;

    const tile = lastDrawn;

    openHand.forEach((meld) => {
      matchedMeld = meld.filter(this.matchInValueAndSuit(tile));

      // console.log("[canIAddGang] each matchedMeld", matchedMeld);

      if (matchedMeld.length === 3) {
        matching = matchedMeld;
      }
    });

    // const matching = openHand?.filter(this.firstOne).filter((tile) => openHand.filter(this.matchInValueAndSuit(tile)).length > 3);
    return matching;
    // return [];
  };

  canIAnGang = ({ closedHand }: DianXinPlayerParams) => {
    const matching = closedHand.filter(this.firstOne).filter((tile) => closedHand.filter(this.matchInValueAndSuit(tile)).length > 3);
    return matching;
  };

  canIChi = ({ closedHand }: DianXinPlayerParams, lastPlay: DianXinGameParams["lastPlay"]) => {
    if (!lastPlay) return false;
    if (typeof lastPlay.card.value !== "number") return false;

    const tile = lastPlay.card;
    const matchingSuit = closedHand.filter(this.matchInSuit(tile));
    const withinTwo = matchingSuit.filter(this.matchValueWithinTwo(tile));

    const possibleChis = [...withinTwo, tile]
      .sort((a, b) => Number(a.value) - Number(b.value))
      .filter(
        (startingTile, i, self) =>
          typeof startingTile.value === "number" &&
          this.valueInArray(startingTile.value + 1, self) &&
          this.valueInArray(startingTile.value + 2, self) &&
          this.firstOne(startingTile, i, self)
      );

    return possibleChis;
  };
}

export default DianXin;

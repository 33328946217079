import _ from "lodash";
import { Decimal } from "decimal.js";

import { ClaimReason, DianXinDeck, DianXinGameParams, DianXinPlayerParams, DianXinRules } from "paks/mjng/DianXin/DianXin.model";
import { Tile } from "paks/mjng/Tiles.model";
import { TileMatrix, ConditionFunction } from "paks/mjng/TileMatrix";
import { Action, ActionParams, Card } from "engine/CardPakTypes";
import { getVisualsOf } from "newEngine/cards";
import type { GameState as GameEngine } from "newEngine/newEngine";
import { GameMode } from "newEngine/newEngine";
import {
  postGameResult, //
  postGongResult,
  updateNextActionTimeout,
  updateNextDiscardTimeout,
} from "actions/api";
import { oVal, sum } from "utils";
import { ACTION_DECISON_TIME, TILE_DISCARD_TIME, FULL_HAND_SIZE } from "newEngine/newEngine";
import { httpsCallable } from "firebase/functions";

const endGameThrottlePeriod = 10000; // in ms
let lastExecutionTime = 0;

const endGame = async (gameEngine, winnerId?: string, winnerNewParams?: any) => {
  const now = Date.now();

  // Check if the function is being called too quickly
  if (now - lastExecutionTime < endGameThrottlePeriod) {
    console.log("Function call is throttled. Please wait.");
    return; // Exit the function without executing the main logic
  }

  // Update the last execution time
  lastExecutionTime = now;

  if (winnerId) {
    // someone wins the game, not draw
    let winnerPlayerParams;
    let loserPlayerParams;
    let winnerPoints;
    let loserPoints;

    let specialPrice = new Decimal(gameEngine.roomData.specialPrice);

    let newWinnerParams;
    let newLoserParams;

    if (winnerNewParams.huReason === "winFromOthers") {
      console.log("[endGame] huReason === winFromOthers");

      // 出銃
      // await gameEngine.finishGame(winnerNewParams.huReason);

      winnerPoints = specialPrice;
      loserPoints = specialPrice.times(-1);

      newWinnerParams = {
        gamePoints: winnerPoints.toFixed(6),
        ...winnerNewParams,
        winner: true,
        netGamePoints: new Decimal(1).minus(gameEngine.houseCommission).times(winnerPoints).toFixed(6),
      };

      // gameEngine.updatePlayer(winnerId, newWinnerParams);

      // console.log(
      //   "[winFromOthers] winnerPoints, loserPoints, specialPrice, winnerNewParams, newWinnerParams:",
      //   winnerPoints,
      //   loserPoints,
      //   specialPrice,
      //   newWinnerParams
      // );

      // construct and update loser params (lose by discarding)
      newLoserParams = { gamePoints: loserPoints.toFixed(6) };

      // gameEngine.updatePlayer(winnerNewParams.discardBy, newLoserParams);
      // console.log("[winFromOthers] newLoserParams:", newLoserParams);

      let finalAllPlayerParamsObject = gameEngine.roomData.playerParams;

      let winnerWithGamePoints;
      let nonWinnerswithGamePoints: any[] = [];

      Object.keys(finalAllPlayerParamsObject).forEach((playerId) => {
        if (playerId === winnerId) {
          // Update the winner
          finalAllPlayerParamsObject[playerId] = {
            ...finalAllPlayerParamsObject[playerId], //
            ...newWinnerParams,
          };

          let updatedWinnerParams = gameEngine.getPlayerParams(winnerId);

          winnerWithGamePoints = [
            {
              uid: winnerId,
              gamePoints: winnerPoints.toString(),
              isWinner: true,
              gongPoints: new Decimal(updatedWinnerParams.gongPoints).toString(),
            },
          ];
        } else if (playerId === winnerNewParams.discardBy) {
          finalAllPlayerParamsObject[playerId] = {
            ...finalAllPlayerParamsObject[playerId], //
            ...newLoserParams,
          };

          // 出銃 loser
          let playerItem = {
            uid: playerId,
            gamePoints: new Decimal(newLoserParams.gamePoints).toString(),
            isWinner: false,
            gongPoints: new Decimal(finalAllPlayerParamsObject[playerId].gongPoints).toString(),
          };

          nonWinnerswithGamePoints.push(playerItem);
        } else {
          // Update the losers
          finalAllPlayerParamsObject[playerId] = {
            ...finalAllPlayerParamsObject[playerId],
          };

          // neutral players
          let playerItem = {
            uid: playerId,
            gamePoints: new Decimal(0).toString(),
            isWinner: false,
            gongPoints: new Decimal(finalAllPlayerParamsObject[playerId].gongPoints).toString(),
          };

          nonWinnerswithGamePoints.push(playerItem);
        }
      });

      // update all GameState at once
      gameEngine.updateGameState({
        ...gameEngine.roomData,
        playerParams: finalAllPlayerParamsObject,
        // nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
        gameEnded: true,
        gameEndedTime: new Date(),
        drawGame: false,
        gameParams: {
          ...gameEngine.gameParams, //
          finishAnimationToShow: winnerNewParams.huReason,
        },
      });

      // console.log("[new GE BaseSet endGame] nonWinnersParams (after loser gamePoints)", nonWinnerswithGamePoints);
      // concat all players params
      let playersWithPoints = [...winnerWithGamePoints, ...nonWinnerswithGamePoints];

      // console.log("[new GE BaseSet endGame] playersWithPoints", playersWithPoints);

      // calculate gamePoint commission
      let gamePointCommission = new Decimal(0);

      playersWithPoints.forEach((player) => {
        if (new Decimal(player.gamePoints).gt(0)) {
          gamePointCommission = gameEngine.houseCommission.times(new Decimal(player.gamePoints)).plus(gamePointCommission).toString();
          player["netGamePoints"] = new Decimal(1).minus(gameEngine.houseCommission).times(new Decimal(player.gamePoints)).toString();
        } else {
          player["netGamePoints"] = new Decimal(player.gamePoints).toString();
        }

        player["netGongPoints"] = new Decimal(player.gongPoints).toString();
      });

      // TODO: add points to firestore too.

      let gameResult = {
        gameMode: gameEngine.roomData.gameMode,
        priceMode: gameEngine.roomData.priceMode,
        roomId: gameEngine.roomData.roomId,
        playersWithPoints: playersWithPoints,
        winnerId: winnerId,
        isGameDrawn: false,
        gamePointCommission,
      };

      // console.log("[endGame BASIC] gameResult to send to API", gameResult);

      // let latestIdToken = await gameEngine.auth.currentUser.getIdToken(true);
      // const postGameResult = httpsCallable(gameEngine.cloudFuctions, "postGameResult");
      let result;
      const latestIdToken = localStorage.getItem("mjToken");

      try {
        result = await postGameResult(gameResult, latestIdToken);
        // result = await postGameResult({ gameResult });
        // console.log("[BASIC postGameResult] result", result);
      } catch (error) {
        console.log("[BASIC postGameResult] error", error);
      }

      // console.log("[BASIC endGame] post game result", result);

      // update gameEnded
      gameEngine.endGame();
    } else if (winnerNewParams.huReason === "selfDrawn") {
      console.log("[endGame] huReason === selfDrawn");

      // add prize horse logic here
      // await gameEngine.finishGame(winnerNewParams.huReason);

      const wall = gameEngine.gameParams?.wall;

      let matched, horseMultiple;

      if (wall.length < 3) {
        // case when wall not enough to draw for prize horses
        matched = [{ matched: true }, { matched: true }, { matched: true }];
        horseMultiple = 4;
      } else {
        let horse1 = wall.shift();
        let horse2 = wall.shift();
        let horse3 = wall.shift();

        let prizeHorses = [horse1, horse2, horse3];
        let prizeHorsesWithVisual = getVisualsOf(prizeHorses);

        let winnerParams = gameEngine.getPlayerParams(winnerId);
        let winnerSeat = winnerParams.seat;
        let prizeList;

        switch (winnerSeat) {
          case 0:
            // East Wind
            // [
            //   "East",
            //   "1 tong", "5 tong", "9 tong",
            //   "1 tiao", "5 tiao", "9 tiao",
            //   "1 wan", "5 wan", "9 wan",
            // ]
            prizeList = [27, 18, 22, 26, 9, 13, 17, 0, 4, 8];
            break;
          case 1:
            // South Wind
            // [
            //   "South",
            //   "2 tong", "6 tong",
            //   "2 tiao", "6 tiao",
            //   "2 wan", "6 wan",
            //   "红中", "发财", "白板"
            // ]
            prizeList = [28, 19, 23, 10, 14, 1, 5, 31, 32, 33];
            break;
          case 2:
            // West Wind
            // [
            //   "West",
            //   "3 tong", "7 tong",
            //   "3 tiao", "7 tiao",
            //   "3 wan", "7 wan",
            //   "红中", "发财", "白板"
            // ]

            prizeList = [29, 20, 24, 11, 15, 2, 6, 31, 32, 33];
            break;
          case 3:
            // North Wind
            // [
            //   "North",
            //   "4 tong", "8 tong",
            //   "4 tiao", "8 tiao",
            //   "4 wan", "8 wan",
            //   "红中", "发财", "白板"
            // ]
            prizeList = [30, 21, 25, 12, 16, 3, 7, 31, 32, 33];
            break;

          default:
            break;
        }

        matched = prizeHorsesWithVisual.map((tile) => {
          return {
            ...tile,
            matched: prizeList.includes(tile.visualCardIndex),
          };
        });

        // console.log("[SelfDrawn endGame] matchedResults", matched);
        horseMultiple = matched.filter((horse) => horse.matched).length + 1;
      }

      // console.log("[SelfDrawn endGame] horseMultiple", horseMultiple);

      // gameEngine.updateGameParams({ wall, lastPlay: null, prizeHorses: matched, horseMultiple });
      // gameEngine.updatePlayer(winnerId, { prizeHorses: matched, horseMultiple });

      winnerPoints = specialPrice.times(horseMultiple).times(3);
      loserPoints = specialPrice.times(horseMultiple).times(-1);

      newWinnerParams = {
        gamePoints: winnerPoints.toFixed(6),
        ...winnerNewParams,
        winner: true,
        prizeHorses: matched,
        horseMultiple,
        netGamePoints: new Decimal(1).minus(gameEngine.houseCommission).times(winnerPoints).toFixed(6),
      };

      // console.log(
      //   "[selfDrawn] winnerPoints, loserPoints, specialPrice, winnerNewParams, newWinnerParams:",
      //   winnerPoints,
      //   loserPoints,
      //   specialPrice,
      //   winnerNewParams,
      //   newWinnerParams
      // );

      // construct and update loser params
      newLoserParams = { gamePoints: loserPoints.toFixed(6) };

      // let nonWinnersParams = gameEngine.getNonWinnersParams(winnerId);
      // console.log("[BaseSet endGame] [self-drawn] nonWinnersParams", nonWinnersParams);
      // console.log("[BaseSet endGame] [self-drawn] nonWinnersParams", nonWinnersParams.length);

      // nonWinnersParams.forEach((item, index) => {
      //   gameEngine.updatePlayer(item.id, newLoserParams);
      // });

      // let nonWinnerswithGamePoints = nonWinnersParams.map((player) => {
      //   let playerItem;

      //   playerItem = {
      //     uid: player.id,
      //     gamePoints: new Decimal(newLoserParams.gamePoints),
      //     isWinner: false,
      //     gongPoints: new Decimal(player.gongPoints),
      //   };
      //   return playerItem;
      // });

      let finalAllPlayerParamsObject = gameEngine.roomData.playerParams;

      let winnerWithGamePoints;
      let nonWinnerswithGamePoints: any[] = [];

      Object.keys(finalAllPlayerParamsObject).forEach((playerId) => {
        if (playerId === winnerId) {
          // Update the winner
          finalAllPlayerParamsObject[playerId] = {
            ...finalAllPlayerParamsObject[playerId], //
            ...newWinnerParams,
          };

          let updatedWinnerParams = gameEngine.getPlayerParams(winnerId);

          winnerWithGamePoints = [
            {
              uid: winnerId,
              gamePoints: winnerPoints.toString(),
              isWinner: true,
              gongPoints: new Decimal(updatedWinnerParams.gongPoints).toString(),
            },
          ];
        } else {
          finalAllPlayerParamsObject[playerId] = {
            ...finalAllPlayerParamsObject[playerId], //
            ...newLoserParams,
          };

          // all losers
          let playerItem = {
            uid: playerId,
            gamePoints: new Decimal(newLoserParams.gamePoints).toString(),
            isWinner: false,
            gongPoints: new Decimal(finalAllPlayerParamsObject[playerId].gongPoints).toString(),
          };

          nonWinnerswithGamePoints.push(playerItem);
        }
      });

      // update all GameState at once
      gameEngine.updateGameState({
        ...gameEngine.roomData,
        playerParams: finalAllPlayerParamsObject,
        gameParams: {
          ...gameEngine.gameParams, //
          wall,
          lastPlay: null,
          prizeHorses: matched,
          horseMultiple,
          finishAnimationToShow: winnerNewParams.huReason,
        },
        // nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
        gameEnded: true,
        gameEndedTime: new Date(),
        drawGame: false,
      });

      // console.log("[new GE Hu-Selfdrawn endGame] nonWinnersParams (after loser gamePoints)", nonWinnerswithGamePoints);
      let playersWithPoints = [...winnerWithGamePoints, ...nonWinnerswithGamePoints];

      // console.log("[new GE Hu-Selfdrawn endGame] playersWithPoints", playersWithPoints);

      // calculate gamePoint commission and gong points commission
      let gamePointCommission = new Decimal(0);
      // let gongPointCommission = new Decimal(0);

      playersWithPoints.forEach((player) => {
        if (new Decimal(player.gamePoints).gt(0)) {
          gamePointCommission = gameEngine.houseCommission.times(new Decimal(player.gamePoints)).plus(gamePointCommission).toString();
          player["netGamePoints"] = new Decimal(1).minus(gameEngine.houseCommission).times(new Decimal(player.gamePoints)).toString();
        } else {
          player["netGamePoints"] = new Decimal(player.gamePoints).toString();
        }

        player["netGongPoints"] = new Decimal(player.gongPoints).toString();
      });

      // TODO: add points to firestore too.

      // construct a full game result object

      let gameResult = {
        gameMode: gameEngine.roomData.gameMode,
        priceMode: gameEngine.roomData.priceMode,
        roomId: gameEngine.roomId,
        playersWithPoints: playersWithPoints,
        winnerId: winnerId,
        isGameDrawn: false,
        prizeHorsesMatched: matched,
        horseMultiple: horseMultiple,
        gamePointCommission,
        // gongPointCommission,
      };

      // console.log("Self-Drawn gameResult to send to API", gameResult);

      // let latestIdToken = await gameEngine.auth.currentUser.getIdToken(true);
      // const postGameResult = httpsCallable(gameEngine.cloudFuctions, "postGameResult");
      let result;
      const latestIdToken = localStorage.getItem("mjToken");

      try {
        // result = await postGameResult({ gameResult });
        result = await postGameResult(gameResult, latestIdToken);
      } catch (error) {
        console.log("[Self-Drawn postGameResult] error", error);
      }

      // console.log("Self-Drawn endGame post game response", result);

      gameEngine.endGame();
    }
  } else {
    console.log("in Draw game");
    let allPlayerParams = gameEngine.playerParams;

    // update all GameState at once
    gameEngine.updateGameState({
      ...gameEngine.roomData,
      gameEnded: true,
      gameEndedTime: new Date(),
      drawGame: true,
    });

    let playersWithPoints = allPlayerParams?.map((player) => {
      let playerItem;

      playerItem = {
        uid: player.id,
        gamePoints: new Decimal(player.gamePoints).toFixed(6).toString(),
        isWinner: false,
        gongPoints: new Decimal(player.gongPoints).toFixed(6).toString(),
      };
      return playerItem;
    });

    // calculate gamePoint commission and gong points commission
    let gamePointCommission = new Decimal(0).toFixed(6).toString();
    // let gongPointCommission = new Decimal(0);

    playersWithPoints?.forEach((player) => {
      // player["netGamePoints"] = player.gamePoints
      player["netGamePoints"] = new Decimal(0).toFixed(6).toString();
      player["netGongPoints"] = new Decimal(player.gongPoints).toFixed(6).toString();
    });
    // no update on player points, but still update game result to firestore
    // TODO: add points to firestore too.

    let gameResult = {
      gameMode: gameEngine.roomData.gameMode,
      priceMode: gameEngine.roomData.priceMode,
      roomId: gameEngine.roomId,
      playersWithPoints: playersWithPoints,
      winnerId: null,
      isGameDrawn: true,
      gamePointCommission,
    };

    // console.log("Draw Game gameResult to send to API", gameResult);

    // let latestIdToken = await gameEngine.auth.currentUser.getIdToken(true);
    // const postGameResult = httpsCallable(gameEngine.cloudFuctions, "postGameResult");
    let result;
    const latestIdToken = localStorage.getItem("mjToken");
    try {
      // result = await postGameResult({ gameResult });
      result = await postGameResult(gameResult, latestIdToken);
    } catch (error) {
      console.log("[Draw postGameResult] error", error);
    }

    // console.log("post draw endGame response", result);

    gameEngine.endGame();
  }

  // logic to change to next wind
  // const wind = ((gameEngine.gameParams?.wind || 0) + 1) % 4;
  // const newGameParams = { lastPlay: null, wind };
  // gameEngine.updateGameParams(newGameParams);
};

const resetSkipsForAllPlayers = (gameEngine, allPlayerParams?) => {
  let newAllPlayerParamsWithSkip;

  if (allPlayerParams) {
    // supply with a allPlayerParams Object

    Object.keys(allPlayerParams).forEach((playerId) => {
      allPlayerParams[playerId].skipped = false;
    });

    newAllPlayerParamsWithSkip = allPlayerParams;
  } else {
    // just reset existing all playerParams for "Skip"
    newAllPlayerParamsWithSkip = gameEngine.playerParams.reduce((accumulator, currentPlayer) => {
      let updatedObject = { ...currentPlayer, skipped: false }; // Update the skipped property for all objects

      accumulator[currentPlayer.id] = updatedObject; // Use the id as the key for the object
      return accumulator;
    }, {}); // Initialize the accumulator as an empty object
  }

  return newAllPlayerParamsWithSkip;
};

const removeLastPlayedTileDraft = ({ gameEngine }: ActionParams) => {
  const lastPlay = gameEngine.gameParams?.lastPlay;

  const playedPlayerParams = gameEngine.getPlayerParams(lastPlay.by);
  const playedTiles = playedPlayerParams.playedTiles.filter((t: Card) => t.id !== lastPlay.card.id);
  const newPlayedPlayerParams = { ...playedPlayerParams, playedTiles };
  // gameEngine.updatePlayer(lastPlay.by, newPlayedPlayerParams);

  return { lastPlayedBy: lastPlay.by, lastPlayedPlayerParams: newPlayedPlayerParams };
};

const createOpenHandWith = (playerParams: any, meld: any[]) => {
  // const openHand = { ...playerParams.openHand };
  const openHand = [...playerParams.openHand];

  // console.log("[createOpenHandWith] openHand", openHand);
  // console.log("[createOpenHandWith] oVal(playerParams.openHand)", oVal(playerParams.openHand));

  // TODO: check if meld exists to avoid duplicate

  openHand[oVal(playerParams.openHand).length] = meld;
  return openHand;
};

const hasAFullHand = ({ closedHand, openHand }) => {
  return closedHand.length + oVal(openHand).length * 3 >= FULL_HAND_SIZE;
};

const getOpenHand = (player) => {
  return player.openHand ? oVal(player.openHand) : [];
};

const getAvailableActionsBaseSet = ({ executingPlayerId, gameEngine }) => {
  const ignoreTheseActions = ["Skip", "Draw"];
  const searchActions = playerActions.filter((a) => !ignoreTheseActions.includes(a.name));

  const availableActions: any[] = [];
  searchActions?.forEach((action) => {
    const isAvailable = action.isAvailable({
      executingPlayerId,
      gameEngine,
    });
    if (isAvailable) availableActions.push(action);
  });

  return availableActions;
};

const onlyMyActionOrEveryoneSkipped = ({ executingPlayerId, gameEngine }) => {
  // console.log("calling onlyMyActionOrEveryoneSkipped()");

  const players = gameEngine.playerParams;
  if (!players) return null;

  const playersReady = players
    // get other players but executing one
    .filter((p) => p.id !== executingPlayerId)
    .map((p) => {
      const actions = getAvailableActionsBaseSet({
        executingPlayerId: p.id,
        gameEngine,
      });
      const skipped = p.skipped;
      // return true if skipped or have no actions
      return actions.length === 0 || skipped;
    });

  // console.log("[onlyMyActionOrEveryoneSkipped] playersReady: ", playersReady);

  //sum ready players and check if all players are ready
  return sum(playersReady) === players.length - 1;
};

const canAnyoneElse = (actionName, { executingPlayerId, gameEngine }) => {
  const players = gameEngine.playerParams;
  if (!players) return null;

  // console.log("in canAnyoneElse():, playerActions.length", playerActions.length);

  const action = playerActions.find((a) => a.name === actionName);

  // console.log("in canAnyoneElse():, actionName", actionName);
  // console.log("in canAnyoneElse():, action", action);

  const playersThatCan = players
    .filter((p) => p.id !== executingPlayerId)
    .map((p) => {
      const isAvailable = action?.isAvailable({
        executingPlayerId: p.id,
        gameEngine,
      });
      const skipped = p.skipped;
      return isAvailable && !skipped;
    });
  return sum(playersThatCan) > 0;
};

export const whoElseCan = (actionName: string, { executingPlayerId, gameEngine }) => {
  const players: any[] = gameEngine.playerParams as any;
  if (!players) return null;

  const action = playerActions.find((a) => a.name === actionName);
  const playersThatCan = players
    .filter((p) => p.id !== executingPlayerId)
    .map((p) => {
      const isAvailable = action?.isAvailable({
        executingPlayerId: p.id,
        gameEngine,
      });
      const skipped = p.skipped;

      return {
        ...p,
        [`can${actionName}`]: isAvailable && !skipped,
      };
    });
  return playersThatCan;
};

const getWinner = (gameEngine: GameEngine) => {
  const players: any[] = gameEngine.playerParams as any;
  if (!players) return null;

  const winner = players.filter((p) => p.winner);
  return winner;
};

//* Helpers Filters
const firstOne = (
  tile: {
    value: number | string;
    params?: { suit?: string };
  },
  index: number,
  self: Tile[]
) => index === self.findIndex((t) => t.value === tile.value && t.params?.suit === tile.params?.suit);

const valueInArray = (value: number | string, array: Tile[]) => {
  return array.findIndex((t) => t.value === value) > -1;
};

const matchInSuit = (tile: Card) => {
  return (t: Card) => t.params.suit === tile.params.suit;
};

const matchValueWithinTwo = (tile: Card) => {
  const lowerBound = Number(tile.value) - 2;
  const upperBound = Number(tile.value) + 2;
  return (t: Card) => Number(t.value) >= lowerBound && Number(t.value) <= upperBound;
};

const matchInValueAndSuit = (tile: { value: number | string; params?: { suit?: string } }) => {
  return (t: Card) => t.value === tile.value && t.params.suit === tile.params?.suit;
};

const firstMatchInValueAndSuit = (tile: { value: number | string; params?: { suit?: string } }) => {
  return (t: Card, index: number, self: Tile[]) =>
    t.value === tile.value && t.params.suit === tile.params?.suit && firstOne(tile, index, self);
};

const matchStaircaseStartingAt = (startTile: Tile) => (t: Tile, i: number, self: Tile[]) =>
  firstMatchInValueAndSuit({
    value: startTile.value,
    params: { suit: startTile.params?.suit },
  })(t, i, self) ||
  firstMatchInValueAndSuit({
    value: Number(startTile.value) + 1,
    params: { suit: startTile.params?.suit },
  })(t, i, self) ||
  firstMatchInValueAndSuit({
    value: Number(startTile.value) + 2,
    params: { suit: startTile.params?.suit },
  })(t, i, self);

// DianXin Helper Getters

const canIPeng = ({ closedHand }: DianXinPlayerParams, lastPlay: DianXinGameParams["lastPlay"]) => {
  if (!lastPlay) return false;

  const tile = lastPlay.card;
  const matching = closedHand.filter(matchInValueAndSuit(tile));
  return matching.length >= 2;
};

const canIGang = ({ closedHand }: DianXinPlayerParams, lastPlay: DianXinGameParams["lastPlay"]) => {
  if (!lastPlay) return false;

  const tile = lastPlay.card;
  const matching = closedHand.filter(matchInValueAndSuit(tile));
  return matching.length >= 3;
};

const canIAddGang = ({ openHand }, lastDrawn) => {
  // console.log("[canIAddGang] calling, openHand, lastDrawn:", openHand, lastDrawn);

  let matching = [];
  let matchedMeld;

  const tile = lastDrawn;

  openHand.forEach((meld) => {
    matchedMeld = meld.filter(matchInValueAndSuit(tile));

    // console.log("[canIAddGang] each matchedMeld", matchedMeld);

    if (matchedMeld.length === 3) {
      matching = matchedMeld;
    }
  });

  // const matching = openHand?.filter(this.firstOne).filter((tile) => openHand.filter(this.matchInValueAndSuit(tile)).length > 3);
  return matching;
  // return [];
};

const canIAnGang = ({ closedHand }: DianXinPlayerParams) => {
  const matching = closedHand.filter(firstOne).filter((tile) => closedHand.filter(matchInValueAndSuit(tile)).length > 3);
  return matching;
};

export const playerActions = [
  //----------------------------------#01F2DF
  //- Draw
  {
    name: "Draw",
    isAvailable: ({ executingPlayerId, gameEngine }) => {
      const isWallEmpty = gameEngine.gameParams?.wall.length === 0;
      const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);

      const hasFullHand = hasAFullHand(playerParams);

      // Deal with conflicting Hu
      const huKing = canAnyoneElse("Hu", {
        executingPlayerId,
        gameEngine,
      });

      const huSelfDrawnKing = canAnyoneElse("Hu-Selfdrawn", {
        executingPlayerId,
        gameEngine,
      });

      // console.log(`huKing: ${huKing} and huSelfDrawnKing: ${huSelfDrawnKing}`);

      if (huKing || huSelfDrawnKing) return false;

      const awaitingNoOne = onlyMyActionOrEveryoneSkipped({
        executingPlayerId,
        gameEngine,
      });

      // console.log("[Draw Action]: calling Draw isAvailable:");
      // console.log("[Draw Action]: isWallEmpty", isWallEmpty);
      // console.log("[Draw Action]: isMyTurn", isMyTurn);
      // console.log("[Draw Action]: playerParams", playerParams);
      // console.log("[Draw Action]: hasFullHand", hasFullHand);
      // console.log("[Draw Action]: awaitingNoOne", awaitingNoOne);
      // console.log("[Draw Action]: Draw should return:", isWallEmpty || !isMyTurn || hasFullHand || !awaitingNoOne);

      if (isWallEmpty || !isMyTurn || hasFullHand || !awaitingNoOne) return false;

      // to make Draw available:
      // Wall not empty (isWallEmpty == false)
      // isMyTurn (isMyTurn == true)
      // dont have 14 tiles (hasAFullHand == false)
      // awaitingNoOne is true (awaitingNoOne == true)
      return true;
    },
    onExecute: async ({ executingPlayerId, gameEngine }) => {
      // console.log("Draw is calling onExecute");
      // gameEngine.resetTimers();

      // if (gameEngine.gameParams.wall.length === 0) {
      //   console.log("[Draw] empty wall, ending game now...");
      //   return endGame(gameEngine);
      // }

      const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const hasFullHand = hasAFullHand(playerParams);

      if (!isMyTurn) {
        console.log("[Error] not player's turn to execute");
        return;
      }

      if (hasFullHand) {
        console.error(`player ${executingPlayerId} has full hand already!`);
        return;
      }

      const wall = gameEngine.gameParams?.wall;
      const drawnTile = wall.shift();
      const closedHand = [...playerParams.closedHand, { ...drawnTile, justDrawn: true }];

      const newPlayerParams = { closedHand };

      // console.log(`[Draw Action]: playerParams of ${executingPlayerId}`, playerParams);
      // console.log(`[Draw Action]: wall from gameEngine:`, wall);
      // console.log(`[Draw Action]: drawnTile:`, drawnTile);
      // console.log(`[Draw Action]: closedHand:`, closedHand);
      // console.log(`[Draw Action]: newPlayerParams:`, newPlayerParams);

      console.log("Draw calling reset skips");

      let newAllPlayerParamsWithSkip = gameEngine.playerParams.reduce((accumulator, currentPlayer) => {
        let updatedObject = { ...currentPlayer, skipped: false }; // Update the skipped property for all objects

        // Check if the current object's id is 2, and if so, add the "message" property
        if (currentPlayer.id === executingPlayerId) {
          updatedObject = { ...updatedObject, closedHand: closedHand };
        }

        accumulator[currentPlayer.id] = updatedObject; // Use the id as the key for the object
        return accumulator;
      }, {}); // Initialize the accumulator as an empty object

      let newGameParams = { ...gameEngine.gameParams, wall, lastPlay: null };

      // update all GameState at once
      await gameEngine.updateGameState({
        ...gameEngine.roomData,
        playerParams: newAllPlayerParamsWithSkip,
        gameParams: newGameParams,
        // nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
      });

      // console.log("sending updates to /updateNextDiscardTimeout API");
      // let latestIdToken = await gameEngine.auth.currentUser.getIdToken();
      const latestIdToken = localStorage.getItem("mjToken");

      let result = await updateNextDiscardTimeout(
        gameEngine.roomData.gameMode,
        gameEngine.roomData.priceMode,
        gameEngine.roomId,
        executingPlayerId,
        latestIdToken
      );
      // console.log("sent /updateNextDiscardTimeout", result);

      // TODO: setup autoplay timer (send REST request to trigger update)

      // // setting up discardTimer
      // let actionFunc = this.rules.onCardClick;
      // let executingParams = { executingPlayerId, card: drawnTile, gameEngine };

      // console.log("[Draw onExecute]: setting up DiscardTimer", executingParams);

      // if (gameEngine.autoplayEnabled) {
      //   gameEngine.setupDiscardTimer(actionFunc, executingParams);
      // }

      // gameEngine.updateNextTimeoutTimestamp(
      //   {
      //     nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
      //   }
      //   // { nextTimeoutTimestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf() }
      // );
    },
  },
  //----------------------------------#01F2DF
  //- Peng
  {
    name: "Peng",
    isAvailable: ({ executingPlayerId, gameEngine }) => {
      // Deal with conflicting Hu
      const huKing = canAnyoneElse("Hu", {
        executingPlayerId,
        gameEngine,
      });

      const huSelfDrawnKing = canAnyoneElse("Hu-Selfdrawn", {
        executingPlayerId,
        gameEngine,
      });

      if (huKing || huSelfDrawnKing) return false;

      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const lastPlay = gameEngine.gameParams?.lastPlay;
      const notMyPlay = lastPlay?.by !== executingPlayerId;
      const makesAMeld = canIPeng(playerParams, lastPlay);
      const hasFullHand = hasAFullHand(playerParams);
      // alreadySkipped is always false as Draw will reset all skips
      const alreadySkipped = playerParams.skipped;

      // console.log("[Peng] alreadySkipped", alreadySkipped);

      // console.log("[Peng Action]: calling Peng isAvailable:");
      // console.log("[Peng Action]:playerParams", playerParams);
      // console.log("[Peng Action]:lastPlay", lastPlay);
      // console.log("[Peng Action]:notMyPlay", notMyPlay);
      // console.log("[Peng Action]:makesAMeld", makesAMeld);
      // console.log("[Peng Action]:hasFullHand", hasFullHand);
      // console.log("[Peng Action]:alreadySkipped", alreadySkipped);
      // console.log("[Peng Action]: should return", notMyPlay && makesAMeld && !hasFullHand && !alreadySkipped);

      return notMyPlay && makesAMeld && !hasFullHand && !alreadySkipped;
    },
    onExecute: async ({ executingPlayerId, gameEngine }) => {
      // console.log("Peng is calling onExecute");
      // gameEngine.resetTimers();

      const lastPlay = gameEngine.gameParams?.lastPlay;
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);

      // Remove the tile from playedTiles of other player
      let { lastPlayedBy, lastPlayedPlayerParams } = removeLastPlayedTileDraft({ executingPlayerId, gameEngine });

      // Put the tile and your matching tiles in openHand
      const matching = playerParams.closedHand.filter(matchInValueAndSuit(lastPlay.card)).slice(0, 2);
      // const matching = playerParams.closedHand.filter(this.matchInValueAndSuit(lastPlay.card));
      // console.log("[Peng onExecute]: matching", matching);

      const meld = [lastPlay.card, ...matching];
      const openHand = createOpenHandWith(playerParams, meld);
      const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);
      const newPlayerParams = { openHand, closedHand };

      // console.log("[Peng Execute] newPlayerParams", newPlayerParams);

      let finalAllPlayerParamsObject = {};

      finalAllPlayerParamsObject = {
        ...gameEngine.roomData.playerParams,
        [lastPlayedBy]: lastPlayedPlayerParams,
        [executingPlayerId]: { ...playerParams, ...newPlayerParams },
      };

      finalAllPlayerParamsObject = resetSkipsForAllPlayers(gameEngine, finalAllPlayerParamsObject);

      let newGameParams = gameEngine.claimTurn(executingPlayerId, ClaimReason.PENG);

      // console.log("[Peng execute: finalAllPlayerParamsObject ", finalAllPlayerParamsObject);

      // update all GameState at once
      gameEngine.updateGameState({
        ...gameEngine.roomData,
        playerParams: finalAllPlayerParamsObject,
        gameParams: { ...newGameParams, lastPlay: null },
        // nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
      });

      // console.log("sending updates to /updateNextDiscardTimeout API");
      // let latestIdToken = await gameEngine.auth.currentUser.getIdToken();
      const latestIdToken = localStorage.getItem("mjToken");

      let result = await updateNextDiscardTimeout(
        gameEngine.roomData.gameMode,
        gameEngine.roomData.priceMode,
        gameEngine.roomId,
        executingPlayerId,
        latestIdToken
      );
      // console.log("sent /updateNextDiscardTimeout", result);
    },
  },
  //----------------------------------#01F2DF
  //- Add Gang
  {
    name: "Add Gang",
    isAvailable: ({ executingPlayerId, gameEngine }) => {
      // Deal with conflicting Hu
      const huKing = canAnyoneElse("Hu", {
        executingPlayerId,
        gameEngine,
      });

      const huSelfDrawnKing = canAnyoneElse("Hu-Selfdrawn", {
        executingPlayerId,
        gameEngine,
      });

      if (huKing || huSelfDrawnKing) return false;

      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
      const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];

      if (!lastDrawnTile) return false;

      const makesAMeld = canIAddGang(playerParams, lastDrawnTile);
      const alreadySkipped = playerParams.skipped;
      const isWallEmpty = gameEngine.gameParams?.wall.length === 0;

      // console.log("[AddGang] playerParams: ", playerParams);
      // console.log("[AddGang] isMyTurn: ", isMyTurn);
      // console.log("[AddGang] lastDrawnTile: ", lastDrawnTile);
      // console.log("[AddGang] makesAMeld: ", makesAMeld);
      // console.log("[AddGang] alreadySkipped: ", alreadySkipped);

      if (makesAMeld.length === 0 || !isMyTurn || alreadySkipped || isWallEmpty) {
        return false;
      } else {
        return true;
      }
    },

    onExecute: async ({ executingPlayerId, gameEngine }) => {
      // console.log("AddGang onExecute got clicked");
      // gameEngine.resetTimers();

      // const lastPlay = gameEngine.gameParams?.lastPlay;
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];

      // console.log("[AddGang onExecute]: lastDrawnTile", lastDrawnTile);

      let matching = [];
      let matchedMeld;
      let matchedMeldIndex;

      const tile = lastDrawnTile;

      playerParams.openHand.forEach((meld, index) => {
        matchedMeld = meld.filter(matchInValueAndSuit(tile));

        if (matchedMeld.length === 3) {
          matching = matchedMeld;
          matchedMeldIndex = index;
          // console.log("[AddGang onExecute]: found matching meld in openhand & its Index", matching, matchedMeldIndex);
        }
      });
      // console.log("[AddGang onExecute]: matching", matching);

      const meld = [lastDrawnTile, ...matching];
      // const meld = [...matching];
      // const openHand = this.createOpenHandWith(playerParams, meld);
      let openHand = [...playerParams.openHand];
      openHand[matchedMeldIndex] = meld;

      // logic to filter out meld tile from closed hand
      const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);

      // ------- logic to draw new tile and discard

      // Draw another tile
      // const deadWall: Card[] = gameEngine.gameParams?.deadWall;
      // const drawnTile = deadWall.shift();
      const wall: Card[] = gameEngine.gameParams?.wall;
      const drawnTile = wall.shift();
      closedHand.push({ ...drawnTile, justDrawn: true });

      const basePrice = new Decimal(gameEngine.roomData.basePrice);
      const currentGongPoints = playerParams.gongPoints;

      // new implementation
      let winnerGongPoints = basePrice.times(3);
      let winnerGongPointsAfterComm = new Decimal(1).minus(gameEngine.houseCommission).times(winnerGongPoints); // (Gong point - comm) in this action

      let winnerCurrentGongPoints = winnerGongPointsAfterComm.plus(new Decimal(currentGongPoints));

      const newPlayerParams = { openHand, closedHand, gongPoints: winnerCurrentGongPoints.toFixed(6) };

      // Update params for player
      // gameEngine.updateSelfPlayerParams(executingPlayerId, { ...newPlayerParams, skipped: false });
      // this.resetSkips(gameEngine);
      // gameEngine.updatePlayer(executingPlayerId, newPlayerParams);

      // gameEngine.updateGameParams({
      //   lastPlay: null,
      //   wall,
      // });
      // gameEngine.claimTurn(executingPlayerId, ClaimReason.ADDGANG);
      // // Update params for other players
      // let otherPlayers = gameEngine.getOtherPlayersButId(executingPlayerId);
      // let otherPlayersGongPoints: any[] = [];

      // otherPlayers?.forEach((player, index) => {
      //   let currentPlayerGongPoints = player.gongPoints;
      //   let otherPlayerGongPoints = basePrice.times(-1);

      //   gameEngine.updatePlayer(player.id, { gongPoints: otherPlayerGongPoints.plus(new Decimal(currentPlayerGongPoints)).toFixed(6) });

      //   otherPlayersGongPoints.push({
      //     uid: player.id,
      //     gongPoints: otherPlayerGongPoints,
      //     netGongPoints: otherPlayerGongPoints,
      //   });
      // });

      let newGameParams = gameEngine.claimTurn(executingPlayerId, ClaimReason.ADDGANG);

      let finalAllPlayerParamsObject = gameEngine.roomData.playerParams;
      let otherPlayersGongPoints: any[] = [];

      Object.keys(finalAllPlayerParamsObject).forEach((playerId) => {
        if (playerId === executingPlayerId) {
          // Update the winner
          finalAllPlayerParamsObject[playerId] = {
            ...finalAllPlayerParamsObject[playerId], //
            ...newPlayerParams,
            skipped: false,
          };
        } else {
          // Update the losers

          let currentPlayerGongPoints = finalAllPlayerParamsObject[playerId].gongPoints;
          let otherPlayerGongPoints = basePrice.times(-1);

          finalAllPlayerParamsObject[playerId] = {
            ...finalAllPlayerParamsObject[playerId],
            gongPoints: otherPlayerGongPoints.plus(new Decimal(currentPlayerGongPoints)).toFixed(6),
            skipped: false,
          };

          otherPlayersGongPoints.push({
            uid: playerId,
            gongPoints: otherPlayerGongPoints.toString(),
            netGongPoints: otherPlayerGongPoints.toString(),
          });
        }
      });

      // update all GameState at once
      gameEngine.updateGameState({
        ...gameEngine.roomData,
        playerParams: finalAllPlayerParamsObject,
        gameParams: { ...newGameParams, lastPlay: null, wall },
        // nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
      });

      // send gong result to API
      let gongResult = {
        gameMode: gameEngine.roomData.gameMode,
        priceMode: gameEngine.roomData.priceMode,
        roomRef: gameEngine.roomRef,
        roomId: gameEngine.roomId,
        playersWithPoints: [
          ...otherPlayersGongPoints,
          {
            uid: executingPlayerId,
            gongPoints: winnerGongPoints.toString(),
            netGongPoints: winnerGongPointsAfterComm.toString(),
          },
        ],
      };

      // let result = await postGongResult(gongResult, latestIdToken);
      // const postGongResult = httpsCallable(gameEngine.cloudFuctions, "postGongResult");

      let result;
      const latestIdToken = localStorage.getItem("mjToken");
      try {
        // result = await postGongResult({ gongResult });
        result = await postGongResult(gongResult, latestIdToken);
      } catch (error) {
        console.log("[AddGong postGongResult] error", error);
      }

      console.log("[Add Gong] Post Gong result: ", result);

      console.log("sending updates to /updateNextDiscardTimeout API");
      // let latestIdToken = await gameEngine.auth.currentUser.getIdToken();

      let updateNextDiscardTimeoutResult = await updateNextDiscardTimeout(
        gameEngine.roomData.gameMode,
        gameEngine.roomData.priceMode,
        gameEngine.roomId,
        executingPlayerId,
        latestIdToken
      );
      console.log("sent /updateNextDiscardTimeout", updateNextDiscardTimeoutResult);
    },
  },
  //----------------------------------#01F2DF
  //- An Gang
  {
    name: "An Gang",
    isAvailable: ({ executingPlayerId, gameEngine }) => {
      // Deal with conflicting Hu
      const huKing = canAnyoneElse("Hu", {
        executingPlayerId,
        gameEngine,
      });

      const huSelfDrawnKing = canAnyoneElse("Hu-Selfdrawn", {
        executingPlayerId,
        gameEngine,
      });

      if (huKing || huSelfDrawnKing) return false;

      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);

      const makesAMeld = canIAnGang(playerParams);
      const alreadySkipped = playerParams.skipped;
      const isWallEmpty = gameEngine.gameParams?.wall.length === 0;

      const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];

      if (!lastDrawnTile) return false;

      if (makesAMeld.length === 0 || !isMyTurn || alreadySkipped || isWallEmpty) {
        return false;
      } else {
        console.log("[AnGang] makesAMeld:", makesAMeld);

        return true;
      }
    },
    onExecute: async ({ executingPlayerId, gameEngine }) => {
      console.log("AnGang onExecute got clicked");
      // gameEngine.resetTimers();

      // const lastPlay = gameEngine.gameParams?.lastPlay;
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const makesAMeld = canIAnGang(playerParams);
      const gangTile = makesAMeld[0];

      // const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];

      console.log("[AnGang onExecute]: gangTile", gangTile);

      // Put the tile and your matching tiles in openHand
      const matching = playerParams.closedHand.filter(matchInValueAndSuit(gangTile));
      console.log("[AnGang onExecute]: matching", matching);

      // const meld = [lastDrawnTile, ...matching];
      const meld = [...matching];
      const openHand = createOpenHandWith(playerParams, meld);
      const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);

      // Draw another tile
      // const deadWall: Card[] = gameEngine.gameParams?.deadWall;
      // const drawnTile = deadWall.shift();
      const wall: Card[] = gameEngine.gameParams?.wall;
      const drawnTile = wall.shift();
      closedHand.push({ ...drawnTile, justDrawn: true });

      const specialPrice = new Decimal(gameEngine.roomData.specialPrice);
      const currentGongPoints = playerParams.gongPoints;

      console.log("[AN GANG] specialPrice", specialPrice.toFixed(2));
      console.log("[AN GANG] currentGongPoints", currentGongPoints);

      let winnerGongPoints = specialPrice.times(3);
      let winnerGongPointsAfterComm = new Decimal(1).minus(gameEngine.houseCommission).times(winnerGongPoints); // (Gong point - comm) in this action

      let winnerCurrentGongPoints = winnerGongPointsAfterComm.plus(new Decimal(currentGongPoints));

      console.log("[AN GANG] winnerCurrentGongPoints", winnerCurrentGongPoints.toFixed(2));

      const newPlayerParams = { openHand, closedHand, gongPoints: winnerCurrentGongPoints.toFixed(6) };

      // Update params for player
      // this.resetSkips(gameEngine);
      // gameEngine.updatePlayer(executingPlayerId, newPlayerParams);

      // Update params for other players
      // let otherPlayers = gameEngine.getOtherPlayersButId(executingPlayerId);
      // let otherPlayersGongPoints: any[] = [];

      // otherPlayers?.forEach((player, index) => {
      //   let currentPlayerGongPoints = player.gongPoints;
      //   let otherPlayerGongPoints = specialPrice.times(-1);

      //   gameEngine.updatePlayer(player.id, {
      //     gongPoints: otherPlayerGongPoints.plus(new Decimal(currentPlayerGongPoints)).toFixed(6),
      //   });

      //   otherPlayersGongPoints.push({
      //     uid: player.id,
      //     gongPoints: otherPlayerGongPoints,
      //     netGongPoints: otherPlayerGongPoints,
      //   });
      // });

      // gameEngine.updateGameParams({
      //   lastPlay: null,
      //   wall,
      // });
      // gameEngine.claimTurn(executingPlayerId, ClaimReason.ANGANG);

      let newGameParams = gameEngine.claimTurn(executingPlayerId, ClaimReason.ANGANG);
      let finalAllPlayerParamsObject = gameEngine.roomData.playerParams;
      let otherPlayersGongPoints: any[] = [];

      Object.keys(finalAllPlayerParamsObject).forEach((playerId) => {
        if (playerId === executingPlayerId) {
          // Update the winner
          finalAllPlayerParamsObject[playerId] = {
            ...finalAllPlayerParamsObject[playerId], //
            ...newPlayerParams,
            skipped: false,
          };
        } else {
          // Update the losers

          let currentPlayerGongPoints = finalAllPlayerParamsObject[playerId].gongPoints;
          let otherPlayerGongPoints = specialPrice.times(-1);

          finalAllPlayerParamsObject[playerId] = {
            ...finalAllPlayerParamsObject[playerId],
            gongPoints: otherPlayerGongPoints.plus(new Decimal(currentPlayerGongPoints)).toFixed(6),
            skipped: false,
          };

          otherPlayersGongPoints.push({
            uid: playerId,
            gongPoints: otherPlayerGongPoints.toString(),
            netGongPoints: otherPlayerGongPoints.toString(),
          });
        }
      });

      // update all GameState at once
      gameEngine.updateGameState({
        ...gameEngine.roomData,
        playerParams: finalAllPlayerParamsObject,
        gameParams: { ...newGameParams, lastPlay: null, wall },
        // nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
      });

      // send gong result to API
      let gongResult = {
        gameMode: gameEngine.roomData.gameMode,
        priceMode: gameEngine.roomData.priceMode,
        roomRef: gameEngine.roomRef,
        roomId: gameEngine.roomId,
        playersWithPoints: [
          ...otherPlayersGongPoints,
          {
            uid: executingPlayerId,
            gongPoints: winnerGongPoints.toString(),
            netGongPoints: winnerGongPointsAfterComm.toString(),
          },
        ],
      };

      // const postGongResult = httpsCallable(gameEngine.cloudFuctions, "postGongResult");

      let result;
      const latestIdToken = localStorage.getItem("mjToken");

      try {
        // result = await postGongResult({ gongResult });
        result = await postGongResult(gongResult, latestIdToken);
      } catch (error) {
        console.log("[AnGang postGongResult] error", error);
      }

      console.log("[An Gong] Post Gong result: ", result);

      console.log("sending updates to /updateNextDiscardTimeout API");
      // let latestIdToken = await gameEngine.auth.currentUser.getIdToken();

      let updateNextDiscardTimeoutResult = await updateNextDiscardTimeout(
        gameEngine.roomData.gameMode,
        gameEngine.roomData.priceMode,
        gameEngine.roomId,
        executingPlayerId,
        latestIdToken
      );
      console.log("sent /updateNextDiscardTimeout", updateNextDiscardTimeoutResult);

      // //auto discard logic
      // let actionFunc = this.rules.onCardClick;
      // let executingParams = { executingPlayerId, card: drawnTile, gameEngine };

      // console.log("[AnGang onExecute]: setting up DiscardTimer", executingParams);

      // if (gameEngine.autoplayEnabled) {
      //   gameEngine.setupDiscardTimer(actionFunc, executingParams);
      // }

      // gameEngine.updateNextTimeoutTimestamp(
      //   {
      //     nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
      //   }
      // );
    },
  },
  //----------------------------------#01F2DF
  //- Gang
  {
    name: "Gang",
    isAvailable: ({ executingPlayerId, gameEngine }) => {
      // Deal with conflicting Hu
      const huKing = canAnyoneElse("Hu", {
        executingPlayerId,
        gameEngine,
      });

      const huSelfDrawnKing = canAnyoneElse("Hu-Selfdrawn", {
        executingPlayerId,
        gameEngine,
      });

      if (huKing || huSelfDrawnKing) return false;

      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const lastPlay = gameEngine.gameParams?.lastPlay;
      const makesAMeld = canIGang(playerParams, lastPlay);
      const hasFullHand = hasAFullHand(playerParams);
      const alreadySkipped = playerParams.skipped;

      return makesAMeld && !hasFullHand && !alreadySkipped;
    },
    onExecute: async ({ executingPlayerId, gameEngine }) => {
      console.log("Gang onExecute got clicked");
      // gameEngine.resetTimers();

      const lastPlay = gameEngine.gameParams?.lastPlay;
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);

      // Remove the tile from playedTiles of other player
      let { lastPlayedBy, lastPlayedPlayerParams } = removeLastPlayedTileDraft({ executingPlayerId, gameEngine });

      // Put the tile and your matching tiles in openHand
      // const matching = playerParams.closedHand.filter(this.matchInValueAndSuit(lastPlay.card)).slice(0, 3);
      const matching = playerParams.closedHand.filter(matchInValueAndSuit(lastPlay.card));

      console.log("[Gang onExecute]: matching", matching);

      const meld = [lastPlay.card, ...matching];
      const openHand = createOpenHandWith(playerParams, meld);
      const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);

      // Draw another tile
      // const deadWall: Card[] = gameEngine.gameParams?.deadWall;
      // const drawnTile = deadWall.shift();
      const wall: Card[] = gameEngine.gameParams?.wall;
      const drawnTile = wall.shift();
      closedHand.push({ ...drawnTile, justDrawn: true });

      const basePrice = new Decimal(gameEngine.roomData.basePrice);
      // const specialPrice = gameEngine.specialPrice;
      const currentGongPoints = playerParams.gongPoints;

      console.log("[GANG] currentGongPoints", currentGongPoints);

      let winnerGongPoints = basePrice.times(3);
      let winnerGongPointsAfterComm = new Decimal(1).minus(gameEngine.houseCommission).times(winnerGongPoints); // (Gong point - comm) in this action

      let winnerCurrentGongPoints = winnerGongPointsAfterComm.plus(new Decimal(currentGongPoints));
      // console.log("[GANG] winnerCurrentGongPoints", winnerCurrentGongPoints.toFixed(2));

      // Update params for gong-win player
      const newPlayerParams = { openHand, closedHand, gongPoints: winnerCurrentGongPoints.toFixed(6) };

      // Update params for other player (gong-lose)
      const discardBy = lastPlay.by;
      let currentPlayerGongPoints = gameEngine.getPlayerParams(discardBy).gongPoints;
      let gongLosePlayerGongPoints = basePrice.times(-3);

      let gongLosePlayerGongPointsString = gongLosePlayerGongPoints.plus(new Decimal(currentPlayerGongPoints)).toFixed(6);

      let finalAllPlayerParamsObject = {};

      finalAllPlayerParamsObject = {
        ...gameEngine.roomData.playerParams,
        [lastPlayedBy]: { ...lastPlayedPlayerParams, gongPoints: gongLosePlayerGongPointsString },
        [executingPlayerId]: { ...playerParams, ...newPlayerParams },
      };

      finalAllPlayerParamsObject = resetSkipsForAllPlayers(gameEngine, finalAllPlayerParamsObject);

      let newGameParams = gameEngine.claimTurn(executingPlayerId, ClaimReason.GANG, lastPlay);

      // this.resetSkips(gameEngine);
      // gameEngine.updatePlayer(executingPlayerId, newPlayerParams);
      // gameEngine.updatePlayer(discardBy, {
      //   gongPoints: gongLosePlayerGongPoints.plus(new Decimal(currentPlayerGongPoints)).toFixed(6),
      // });
      // gameEngine.updateGameParams({ lastPlay: null, wall });
      // gameEngine.claimTurn(executingPlayerId, ClaimReason.GANG, lastPlay);

      // update all GameState at once
      gameEngine.updateGameState({
        ...gameEngine.roomData,
        playerParams: finalAllPlayerParamsObject,
        gameParams: { ...newGameParams, lastPlay: null, wall },
        // nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
      });

      // send gong result to API
      let gongResult = {
        gameMode: gameEngine.roomData.gameMode,
        priceMode: gameEngine.roomData.priceMode,
        roomRef: gameEngine.roomRef,
        roomId: gameEngine.roomId,
        playersWithPoints: [
          {
            uid: executingPlayerId,
            gongPoints: winnerGongPoints.toString(),
            netGongPoints: winnerGongPointsAfterComm.toString(),
          },
          {
            uid: discardBy,
            gongPoints: gongLosePlayerGongPoints.toString(),
            netGongPoints: gongLosePlayerGongPoints.toString(),
          },
        ],
      };

      // const postGongResult = httpsCallable(gameEngine.cloudFuctions, "postGongResult");
      let result;
      const latestIdToken = localStorage.getItem("mjToken");

      try {
        result = await postGongResult(gongResult, latestIdToken);
        // result = await postGongResult({ gongResult });
      } catch (error) {
        console.log("[Gang postGongResult] error", error);
      }

      console.log("[Gong] Post Gong result: ", result);

      console.log("sending updates to /updateNextDiscardTimeout API");
      // let latestIdToken = await gameEngine.auth.currentUser.getIdToken();

      let updateNextDiscardTimeoutResult = await updateNextDiscardTimeout(
        gameEngine.roomData.gameMode,
        gameEngine.roomData.priceMode,
        gameEngine.roomId,
        executingPlayerId,
        latestIdToken
      );
      console.log("sent /updateNextDiscardTimeout", updateNextDiscardTimeoutResult);

      // //auto discard logic
      // let actionFunc = this.rules.onCardClick;
      // let executingParams = { executingPlayerId, card: drawnTile, gameEngine };

      // console.log("[Gang onExecute]: setting up DiscardTimer", executingParams);

      // if (gameEngine.autoplayEnabled) {
      //   gameEngine.setupDiscardTimer(actionFunc, executingParams);
      // }

      // gameEngine.updateNextTimeoutTimestamp(
      //   {
      //     nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
      //   }
      // );
    },
  },
  //----------------------------------#01F2DF
  //- Chi
  // {
  //   name: "Chi",
  //   isAvailable: ({ executingPlayerId, gameEngine }) => {
  //     // Deal with conflicting Hu
  //     const huKing = this.canAnyoneElse("Hu", {
  //       executingPlayerId,
  //       gameEngine,
  //     });
  //     if (huKing) return false;

  //     const lastPlay = gameEngine.gameParams?.lastPlay;
  //     if (!lastPlay) return false;

  //     const playerParams = gameEngine.getPlayerParams(executingPlayerId);
  //     const alreadySkipped = playerParams.skipped;
  //     if (alreadySkipped) return false;

  //     // Deal with conflicting Peng
  //     const pengKing = this.canAnyoneElse("Peng", {
  //       executingPlayerId,
  //       gameEngine,
  //     });
  //     if (pengKing) return false;

  //     // You can't chi after you've already drawn
  //     const hasFullHand = this.hasAFullHand(playerParams);
  //     if (hasFullHand) return false;

  //     // Was last play was by the previous player
  //     const lastPlayerParams = gameEngine.getPlayerParams(lastPlay?.by);
  //     const lastWasRightBeforeMe = (playerParams.seat - lastPlayerParams.seat + 4) % 4 === 1;
  //     if (!lastWasRightBeforeMe) return false;

  //     // Does it finish one of your melds?
  //     const makesAMeld = this.canIChi(playerParams, lastPlay);
  //     if (!makesAMeld) return false;

  //     // Make array of actions from ones that start a meld
  //     const availableMelds: Action[] = makesAMeld.map((startTile) => ({
  //       name: `Chi ${startTile.value} ${Number(startTile.value) + 1} ${Number(startTile.value) + 2}`,
  //       isAvailable: () => true,
  //       onExecute: ({ executingPlayerId, gameEngine }) => {
  //         const lastPlay = gameEngine.gameParams?.lastPlay;
  //         const playerParams = gameEngine.getPlayerParams(executingPlayerId);

  //         // Remove the tile from playedTiles of other player
  //         this.removeLastPlayedTile({ executingPlayerId, gameEngine });

  //         // Put the tile and your matching tiles in openHand
  //         const meld = [lastPlay.card, ...playerParams.closedHand].filter(this.matchStaircaseStartingAt(startTile));
  //         const openHand = this.createOpenHandWith(playerParams, meld);
  //         const closedHand = playerParams.closedHand.filter((t: Card) => meld.findIndex((m) => m.id === t.id) === -1);
  //         const newPlayerParams = { openHand, closedHand };

  //         // Update params
  //         this.resetSkips(gameEngine);
  //         gameEngine.updatePlayer(executingPlayerId, newPlayerParams);
  //         gameEngine.updateGameParams({ lastPlay: null });
  //         gameEngine.claimTurn(executingPlayerId, ClaimReason.CHI);
  //       },
  //     }));
  //     if (availableMelds.length === 0) return false;
  //     return availableMelds;
  //   },
  //   onExecute: () => {},
  // },
  //----------------------------------#01F2DF
  //- Hu
  {
    name: "Hu",
    isAvailable: ({ executingPlayerId, gameEngine }) => {
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const hasFullHand = hasAFullHand(playerParams);
      const lastPlay = gameEngine.gameParams?.lastPlay;
      const alreadySkipped = playerParams.skipped;
      const gameMode = gameEngine.roomData.gameMode;

      let isHandJustDrawn;
      if (alreadySkipped) return false;

      if (gameMode === GameMode.selfDrawn) return false;

      if (lastPlay?.by === executingPlayerId) return false;

      let hand: Tile[] = [];

      // console.log("calling is Hu available()...");

      // console.log("[in Hu available] playerParams", playerParams);
      // console.log("[in Hu available] hasFullHand", hasFullHand);
      // console.log("[in Hu available] lastPlay", lastPlay);
      // console.log("[in Hu available] alreadySkipped", alreadySkipped);
      // console.log("[in Hu available] gameMode", gameMode);

      // If your closed hand is full, check your closed
      // hand is winnable
      if (hasFullHand) {
        hand = playerParams.closedHand;
        const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];
        if (lastDrawnTile) {
          isHandJustDrawn = true;
        } else {
          return false;
        }
      }
      // Otherwise, try adding the last played tile to your
      // hand and see if that makes it a winning thing
      else if (!!lastPlay) {
        hand = [lastPlay.card, ...playerParams.closedHand];
        isHandJustDrawn = false;
      }

      if (hand.length === 0) return false;

      const openHand: Tile[] = oVal(playerParams?.openHand || {});
      const tileMatrix = new TileMatrix(hand, openHand, additionalWinConditions);

      // console.log(`[Hu] checking if ${executingPlayerId} winnable`);
      // console.log("[Hu]: tileMatrix", tileMatrix);

      let isWinnable = tileMatrix.isWinnable;

      // console.log("[Hu] isWinnable", executingPlayerId, isWinnable);

      if (isWinnable) {
        if (gameMode === GameMode.basic) {
          console.log("final checking that only Baisc mode is working");

          if (isHandJustDrawn) {
            isWinnable = false;
            // isHandJustDrawn true should lead to Hu-selfdrawn
          } else {
            console.log("[Hu] isWinnable true and not just drawn");
            // isWinnable = true;
          }
        } else {
          console.log("[Hu] Sth wrong that it doesnt win: ", gameMode, hand);
          isWinnable = false;
        }
      }

      // console.log("[Hu] check final isWinnable, GameMode, isSelfDrawn ", isWinnable, gameMode, isHandJustDrawn);

      return isWinnable;
    },
    onExecute: async ({ executingPlayerId, gameEngine }) => {
      console.log("Hu onExecute got clicked");

      // gameEngine.resetTimers();

      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const hasFullHand = hasAFullHand(playerParams);
      const lastPlay = gameEngine.gameParams?.lastPlay;

      let hand;

      if (hasFullHand) {
        // self-drawn
        console.log("sth wrong here as it shouldnt be fullhand");

        hand = playerParams.closedHand;
      } else {
        // constructing the final hand
        hand = [lastPlay.card, ...playerParams.closedHand];
      }
      // discardBy = 出銃
      const newParams = { closedHand: hand, huReason: "winFromOthers", discardBy: lastPlay.by };

      // gameEngine.updateGameEndingState({
      //   gameEnding: true,
      // });

      // gameEngine.updateNextTimeoutTimestamp({
      //   nextTimeoutTimestamp: { timestamp: ACTION_DECISON_TIME * 1000 + new Date().valueOf(), type: "action" },
      // });
      // this.resetSkips(gameEngine);
      endGame(gameEngine, executingPlayerId, newParams);
    },
  },
  //----------------------------------#01F2DF
  //- Hu-Selfdrawn
  {
    name: "Hu-Selfdrawn",
    isAvailable: ({ executingPlayerId, gameEngine }) => {
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const hasFullHand = hasAFullHand(playerParams);
      const lastPlay = gameEngine.gameParams?.lastPlay;
      const alreadySkipped = playerParams.skipped;
      const gameMode = gameEngine.roomData.gameMode;

      let isHandJustDrawn = false;
      if (alreadySkipped) return false;

      let hand: Tile[] = [];

      // if (executingPlayerId === gameEngine.uid) {
      //   console.log("calling Hu-Selfdrawn isAvailable()");
      //   console.log("[in Hu-Selfdrawn available] playerParams", playerParams);
      //   console.log("[in Hu-Selfdrawn available] hasFullHand", hasFullHand);
      //   console.log("[in Hu-Selfdrawn available] lastPlay", lastPlay);
      //   console.log("[in Hu-Selfdrawn available] alreadySkipped", alreadySkipped);
      //   console.log("[in Hu-Selfdrawn available] gameMode", gameMode);
      // }

      // If your closed hand is full, check your closed
      // hand is winnable
      if (hasFullHand) {
        // console.log("[Hu-Selfdrawn] hasFullHand is true la");

        hand = playerParams.closedHand;
        const lastDrawnTile = playerParams.closedHand.filter((tile) => tile.justDrawn === true)[0];
        if (lastDrawnTile) {
          isHandJustDrawn = true;
        } else {
          return false;
        }
      }
      // different logic than noraml Hu as no need to check for lastPlay or seatReason
      // this would allow Gong -> Self-drawn

      // else if (!!lastPlay || gameEngine?.gameParams?.seatReason !== null) {
      //   // console.log("there is lastPlay", lastPlay);
      //   // console.log("there is seatReason", gameEngine?.gameParams?.seatReason);
      //   // console.log("so Hu-Selfdrawn not callings");

      //   // hand = [lastPlay.card, ...playerParams.closedHand];
      //   // isHandJustDrawn = false;
      //   return false;
      // }

      if (hand.length === 0) return false;

      const openHand: Tile[] = oVal(playerParams?.openHand || {});
      const tileMatrix = new TileMatrix(hand, openHand, additionalWinConditions);

      // console.log("[Hu Selfdrawn] checking if winnable", tileMatrix);
      let isWinnable = tileMatrix.isWinnable;

      // console.log("[Hu Selfdrawn] isWinnable", isWinnable);

      // final check on isWinnable and whether it is really self-drawn
      if (isWinnable && isHandJustDrawn) {
        isWinnable = true;
      } else {
        isWinnable = false;
      }

      // if (isWinnable) {
      //   if (gameEngine.roomData.gameMode === GameMode.basic) {
      //     isWinnable = true;
      //   } else if (gameEngine.roomData.gameMode === GameMode.selfDrawn) {
      //     if (isHandJustDrawn) {
      //       isWinnable = true;
      //     }
      //   } else {
      //     console.log("[Hu] Sth wrong that it doesnt win: ", gameEngine.roomData.gameMode, hand, isHandJustDrawn);
      //   }
      // }

      // console.log("[Hu Selfdrawn] check final isWinnable, GameMode, isSelfDrawn ", isWinnable, gameMode, hand, isHandJustDrawn);

      return isWinnable;
    },
    onExecute: async ({ executingPlayerId, gameEngine }) => {
      console.log("Hu-Selfdrawn onExecute got clicked");
      // gameEngine.resetTimers();

      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const hasFullHand = hasAFullHand(playerParams);
      const lastPlay = gameEngine.gameParams?.lastPlay;

      let hand;
      if (hasFullHand) {
        // self-drawn scenario
        hand = playerParams.closedHand;
      } else {
        console.log("sth wrong here as it should have fullhand");

        hand = [lastPlay.card, ...playerParams.closedHand];
      }
      const newParams = { closedHand: hand, huReason: "selfDrawn" };

      // gameEngine.updateNextTimeoutTimestamp({
      //   nextTimeoutTimestamp: { timestamp: ACTION_DECISON_TIME * 1000 + new Date().valueOf(), type: "action" },
      // });

      // this.resetSkips(gameEngine);
      endGame(gameEngine, executingPlayerId, newParams);
    },
  },
  //----------------------------------#01F2DF
  //- Skip
  {
    name: "Skip",
    isAvailable: ({ executingPlayerId, gameEngine }) => {
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
      const alreadySkipped = playerParams.skipped;

      // console.log("calling skip isAvailable() by, ", executingPlayerId);
      // console.log("[skip isAvailable()], alreadySkipped", alreadySkipped);

      const actions = getAvailableActionsBaseSet({
        executingPlayerId,
        gameEngine,
      });
      const hasAvailableActions = actions.length > 0;

      // not sure if it should include isMyTurn
      // if (alreadySkipped || !hasAvailableActions || isMyTurn) return false;
      if (alreadySkipped || !hasAvailableActions) return false;
      return true;
    },
    onExecute: async ({ executingPlayerId, gameEngine }) => {
      console.log("Skip onExecute got clicked");
      // gameEngine.resetTimers();

      let newAllPlayerParams = gameEngine.updateAllPlayerParamsDraft(executingPlayerId, { skipped: true });

      gameEngine.updateGameState({
        ...gameEngine.roomData,
        playerParams: newAllPlayerParams,
        // nextTimeoutTimestamp: { timestamp: TILE_DISCARD_TIME * 1000 + new Date().valueOf(), type: "discard" },
      });

      // // handle skip logic autoplay if have full hands
      const playerParams = gameEngine.getPlayerParams(executingPlayerId);
      const hasFullHand = hasAFullHand(playerParams);

      // let latestIdToken = await gameEngine.auth.currentUser.getIdToken();
      const latestIdToken = localStorage.getItem("mjToken");

      if (hasFullHand) {
        // console.log("[Skip onExecute]: player hasFullHand", hasFullHand);
        // console.log("sending updates to /updateNextDiscardTimeout API");

        let updateNextDiscardTimeoutResult = await updateNextDiscardTimeout(
          gameEngine.roomData.gameMode,
          gameEngine.roomData.priceMode,
          gameEngine.roomId,
          executingPlayerId,
          latestIdToken
        );
        // console.log("sent /updateNextDiscardTimeout", updateNextDiscardTimeoutResult);
      } else {
        // console.log("[Skip onExecute]: player no hasFullHand, calling /updateNextActionTimeout");

        let result = await updateNextActionTimeout(
          gameEngine.roomData.gameMode,
          gameEngine.roomData.priceMode,
          gameEngine.roomId,
          latestIdToken
        );

        // gameEngine.updateNextTimeoutTimestamp({
        //   nextTimeoutTimestamp: { timestamp: ACTION_DECISON_TIME * 1000 + new Date().valueOf(), type: "action" },
        // });
      }
    },
  },
];

export const onCardClick = async ({ executingPlayerId, card, gameEngine }) => {
  // console.log(`${executingPlayerId} is clicking`);
  // console.log("clicking card", card);

  if (!executingPlayerId) return;

  const isMyTurn = gameEngine.isPlayersTurn(executingPlayerId);
  const playerParams = gameEngine.getPlayerParams(executingPlayerId);
  if (!isMyTurn || !card) return;

  const hasFullHand = hasAFullHand(playerParams);
  const tileIsInClosedHand = !!playerParams.closedHand.find((c) => c.id === card.id);
  if (!hasFullHand || !tileIsInClosedHand) return;

  // Remove card from hand to played
  const closedHand = playerParams.closedHand.filter((c) => c.id !== card.id).map((t) => ({ ...t, justDrawn: false }));
  const playedTiles = [...playerParams.playedTiles, card];
  const newPlayerParams = {
    ...playerParams,
    closedHand,
    playedTiles,
  };

  // directly upload the below to firestore
  let newAllPlayerParams = gameEngine.updateAllPlayerParamsDraft(executingPlayerId, newPlayerParams);
  let newGameParams = gameEngine.finishTurn({
    player: executingPlayerId,
    lastPlay: { card, by: executingPlayerId },
  });

  await gameEngine.updateGameState({
    ...gameEngine.roomData,
    playerParams: newAllPlayerParams,
    gameParams: newGameParams,
    // nextTimeoutTimestamp: { timestamp: ACTION_DECISON_TIME * 1000 + new Date().valueOf(), type: "action" },
  });

  const proposedNextTimeoutTimestamp = ACTION_DECISON_TIME * 1000 + new Date().valueOf();

  const isWallEmpty = gameEngine.gameParams?.wall.length === 0;
  // console.log("isWallEmpty?", isWallEmpty);
  // console.log("gameEngine.gameParams", gameEngine.gameParams);

  if (isWallEmpty) {
    setTimeout(() => {
      // console.log("[Client onCardClick()] No more wall scenario");
      // console.log("[Client onCardClick()] current ts: ", new Date().valueOf());
      // console.log("[Client onCardClick()] proposedNextTimeoutTimestamp", proposedNextTimeoutTimestamp);
      // console.log(
      //   "[Client onCardClick()] if current time already pass nextTimeoutTimestamp?",
      //   new Date().valueOf() >= proposedNextTimeoutTimestamp
      // );

      // if (new Date().valueOf() >= gameEngine.roomData.nextTimeoutTimestamp.timestamp && gameEngine.gameEnded !== true) {
      if (new Date().valueOf() >= proposedNextTimeoutTimestamp && gameEngine.gameEnded !== true) {
        // console.log("[Client onCardClick()] time's up, calling endGame...");

        endGame(gameEngine);
      }
    }, ACTION_DECISON_TIME * 1000);
  } else {
    // console.log("sending updates to /updateNextActionTimeout API");
    // let latestIdToken = await gameEngine.auth.currentUser.getIdToken();
    const latestIdToken = localStorage.getItem("mjToken");

    let result = await updateNextActionTimeout(
      gameEngine.roomData.gameMode,
      gameEngine.roomData.priceMode,
      gameEngine.roomId,
      latestIdToken
    );

    // console.log("sent /updateNextActionTimeout", result);
  }

  // Scenario: Draw the last tile, player should see available action, if no action, end the game.
};

const additionalWinConditions: ConditionFunction[] = [
  function (closedHandMatrix, openHandMatrix) {
    return true;
  },
];

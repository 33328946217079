import { DianXinDefault } from "paks/mjng/VisualDeck";
import { Card, Deck, Rules } from "engine/CardPakTypes";
import { oVal, sum } from "utils";

export function getVisualsOf(hand: Card[]) {
  if (!Array.isArray(hand)) return [];

  return hand?.map((tile) => {
    return {
      ...tile,
      visual: DianXinDefault.visualCards[tile.visualCardIndex],
    };
  });
}

export function getOpenHand(player: any) {
  return player.openHand ? oVal(player.openHand) : [];
}

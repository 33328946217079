import React, { useEffect, useContext, useState, useRef } from "react";
import Box from "ui-box";
import classnames from "classnames";
import { useGameplay } from "App";

import { WindChinese } from "../ZhongWenHelpers";
import { ACTION_DECISON_TIME, TILE_DISCARD_TIME } from "newEngine/newEngine";
import Tile from "components/Tile";

import "./Centerpiece.css";
import { PLAYER_TIMEOUT } from "utils";
import { useTranslation } from "react-i18next";

const Centerpiece = ({ roomData, data, wind = "east", tilesRemaining = 0, lastPlayed, ...props }) => {
  const { t } = useTranslation();

  const { newGE } = useGameplay();

  const [nextTimeoutTimestamp, setNextTimeoutTimestamp] = useState();
  const [nextTimeoutSeconds, setNextTimeoutSeconds] = useState();
  const [currentSeconds, setCurrentSeconds] = useState();

  const interval = useRef();

  useEffect(() => {
    // console.log("nextTimeoutTimestamp changed, trigger new setInterval", nextTimeoutTimestamp);
    clearInterval(interval.current);
    let timeLeft = 0;

    interval.current = setInterval(() => {
      // let timeLeft = Math.ceil((nextTimeoutTimestamp - new Date().valueOf()) / 1000);
      let timeLeft = currentSeconds - 1;

      if (timeLeft < 0) {
        timeLeft = "";
      }

      // console.log("[local]: timeLeft", timeLeft);
      setCurrentSeconds(timeLeft);
    }, 1000);

    return () => clearInterval(interval.current);
  }, [currentSeconds]);

  useEffect(() => {
    // console.log("nextTimeoutTimestamp update", newGE?.roomData?.nextTimeoutTimestamp?.type);

    if (newGE?.gameStarted && newGE?.roomData.nextTimeoutTimestamp?.timestamp !== 0) {
      let timeLeft = 0;

      if (newGE?.roomData?.nextTimeoutTimestamp?.type === "action") {
        timeLeft = ACTION_DECISON_TIME;
      } else {
        // type = "discard"
        timeLeft = TILE_DISCARD_TIME;
      }

      setCurrentSeconds(timeLeft);
    }
  }, [newGE.roomData?.nextTimeoutTimestamp]);

  return (
    <Box className="Centerpiece TiltTransform">
      {data?.map((p, i) => (
        <Box key={i} className={"Centerpiece-playedTiles Centerpiece-tile-" + p.orientation}>
          {p.playedTiles.map((t) => (
            <Tile.Open
              className={classnames({
                isLastPlayed: lastPlayed?.card.id === t.id,
              })}
              key={t.id}
              face={t.visual}
            />
          ))}
        </Box>
      ))}

      <Box className="Centerpiece-square Centerpiece-cut">
        <Box className="Centerpiece-squareCut Centerpiece-innerCut">
          <Box className="Centerpiece-squareCut Centerpiece-cut">
            <Box className="Centerpiece-squareCut Centerpiece-innerCut" />
          </Box>
        </Box>

        {data.map((p, i) => (
          <Box
            key={i}
            className={classnames("Centerpiece-turn", "Centerpiece-turn-" + p.orientation, {
              "Centerpiece-isTurn": p.isTurn,
            })}
          />
        ))}

        <Box className="Centerpiece-wind">
          {/* <Box className="Centerpiece-wind-CN">{WindChinese[wind]}</Box> */}
          <Box className="Centerpiece-wind-CN">{roomData.gameStarted && (+currentSeconds || "")}</Box>
          {/* <Box className="Centerpiece-wind-EN">{wind}</Box>
          <Box className="Centerpiece-wind-EN">{wind}</Box> */}
        </Box>

        <Box className="Centerpiece-tilesRemaining">{`剩牌: ${tilesRemaining}`}</Box>
        {roomData.gameStarted ? (
          <></>
        ) : (
          <div className="waiting-text">
            {/* 即将开始 */}
            {t("centerpiece.ready_to_start")}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Centerpiece;

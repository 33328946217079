export const priceList = [
  // {
  //   priceModeId: "0-0",
  //   basePrice: 0.0,
  //   specialPrice: 0.0,
  // },
  {
    priceModeId: "0_1-0_2",
    basePrice: 0.1,
    specialPrice: 0.2,
  },
  {
    priceModeId: "0_25-0_5",
    basePrice: 0.25,
    specialPrice: 0.5,
  },
  {
    priceModeId: "0_5-1",
    basePrice: 0.5,
    specialPrice: 1.0,
  },
  {
    priceModeId: "1_5-3",
    basePrice: 1.5,
    specialPrice: 3.0,
  },
  {
    priceModeId: "2_5-5",
    basePrice: 2.5,
    specialPrice: 5.0,
  },
  {
    priceModeId: "4-8",
    basePrice: 4.0,
    specialPrice: 8.0,
  },
  {
    priceModeId: "7-14",
    basePrice: 7.0,
    specialPrice: 14.0,
  },
  {
    priceModeId: "15-30",
    basePrice: 15.0,
    specialPrice: 30.0,
  },
  // {
  //   priceModeId: "70-140",
  //   basePrice: 70.0,
  //   specialPrice: 140.0,
  // },
  {
    priceModeId: "140-280",
    basePrice: 140.0,
    specialPrice: 280.0,
  },
];

export const gameRulesSC = `
# 跑马仔 基本规则

## 游戏人数: 4人

1. 序数牌. 筒/索/万1-9各四张(三门共108张)
2. 东、南、西、北)四款「风牌」及(中、发、白)三款,每款4张(七款共28张)

## 杠牌结算

- 大明杠：杠他人的牌,杠牌者得(小盲注+大盲注)
  - 送杠者失(小盲注+大盲注),其他人不失注
  - 打比人杠,一家付钱,如杠后自摸糊三家付钱

- 小明杠：碰牌后补杠,杠牌者可得(小盲注x3),其他3位玩家各失(小盲注x1)
  - 小明杠即摸即杠,插入牌内不可再杠

- 暗杠：杠牌者可得(大盲注x3),其他3位玩家各失(大盲注x1)
  - 暗杠可自由选择何时开杠

- 没有抢杠,一炮不可多响,顺位优先食糊。

## 自摸糊

### 奖马计算

- 牌局先结束时开马,根据中马情况结算
- 计算方法：
  - 每只马可得(大盲注x1), 食糊者得(大盲注x3 +大盲注x马数)
  - 而负方的3位玩家各失(大盲注x1 +大盲注x马数)
  - 最后三只牌自摸时,而牌数不足3只马时则当海底捞月均当中马(即3只)

### 奖马规则

- 如果自摸食糊为庄家,则看马是否有一、五、九(筒、索、万)或东、中、发、白,如果有则中马.
- 如果自摸食糊者为闲家-下家(即第二位),则看马是否有二、六 (筒、索、万)或南、中、发、白
- 如果自摸食糊者闲家 - 对家(即第三位),则看马是否有三.、七(筒、索、万)或西、中、发、白
- 如果自摸食糊者为闲家-尾家(即第四位),则看马是否有四、八(筒、索、万)或北、中、发、白

## 打出糊

- 食糊者可得(大盲注x1) ,出统者失(大盲注x1)

### 在游戏中的特殊规则

我们采用了一项令人兴奋且增加游戏趣味性、刺激性和公平性的规则：

**『没有抢杠,一炮不可多响,顺位优先食糊』。**

为了提升游戏体验,让游戏更具竞技性和公平性。根据这个规则,当多名玩家同时准备胡相对的牌时,只有顺位最靠前的玩家能够胡牌。这样的设计考虑到了顺位的重要性,并且增加了玩家之间的竞争和决策的重要性,使得游戏更加有策略性和紧张刺激。同时,这个规则也确保了游戏的公平性,使得每个玩家都有平等的机会获得胜利,无论他们的位置是靠前还是靠后。

牌例不能尽录,一切以元宇宙娱乐场作最后决定。

## 服务费
系统抽取盈利5%作为服务费
`;

export const gameRules = `
# 跑馬仔 基本規則

## 遊戲人數: 4人

1. 序數牌。筒/索/萬1-9各四張（三門共108張）
2. 東、南、西、北四款「風牌」及中、發、白三款，每款4張（七款共28張）

## 槓牌結算

- 大明槓：槓他人的牌，槓牌者得（小盲注+大盲注）
  - 送槓者失（小盲注+大盲注），其他人不失注
  - 打比人槓，一家付錢，如槓後自摸糊三家付錢

- 小明槓：碰牌後補槓，槓牌者可得（小盲注x3），其他3位玩家各失（小盲注x1）
  - 小明槓即摸即槓，插入牌內不可再槓

- 暗槓：槓牌者可得（大盲注x3），其他3位玩家各失（大盲注x1）
  - 暗槓可自由選擇何時開槓

- 沒有搶槓，一炮不可多響，順位優先食糊。

## 自摸糊

### 獎馬計算

- 牌局先結束時開馬，根據中馬情況結算
- 計算方法：
  - 每隻馬可得（大盲注x1），食糊者得（大盲注x3 + 大盲注x馬數）
  - 而負方的3位玩家各失（大盲注x1 + 大盲注x馬數）
  - 最後三隻牌自摸時，而牌數不足3隻馬時則當海底撈月均當中馬（即3隻）

### 獎馬規則

- 如果自摸食糊為莊家，則看馬是否有一、五、九（筒、索、萬）或東、中、發、白，如果有則中馬。
- 如果自摸食糊者為閒家-下家（即第二位），則看馬是否有二、六（筒、索、萬）或南、中、發、白
- 如果自摸食糊者閒家 - 對家（即第三位），則看馬是否有三、七（筒、索、萬）或西、中、發、白
- 如果自摸食糊者為閒家-尾家（即第四位），則看馬是否有四、八（筒、索、萬）或北、中、發、白

## 打出糊

- 食糊者可得（大盲注x1），出統者失（大盲注x1）

### 在遊戲中的特殊規則

我們採用了一項令人興奮且增加遊戲趣味性、刺激性和公平性的規則：

**『沒有搶槓，一炮不可多響，順位優先食糊』。**

為了提升遊戲體驗，讓遊戲更具競技性和公平性。根據這個規則，當多名玩家同時準備胡相對的牌時，只有順位最靠前的玩家能夠胡牌。這樣的設計考慮到了順位的重要性，並且增加了玩家之間的競爭和決策的重要性，使得遊戲更加有策略性和緊張刺激。同時，這個規則也確保了遊戲的公平性，使得每個玩家都有平等的機會獲得勝利，無論他們的位置是靠前還是靠後。

牌例不能盡錄，一切以元宇宙娛樂場作最後決定。

## 服務費
系統抽取盈利5%作為服務費
`;

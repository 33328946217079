import React, { useContext, useEffect, useState, useCallback } from "react";
import "./SettingsLobby.scss";
import { useTransition, animated } from "@react-spring/web";
// import { PiSignOutBold } from "react-icons/pi";
import { useAuth, useGameplay } from "App";
import { navigate } from "@reach/router";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useSignOut } from "react-firebase-hooks/auth";
import { useAuthState, useUpdateProfile } from "react-firebase-hooks/auth";
import parsePhoneNumber from "libphonenumber-js";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { FiCopy } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { getUserWallet } from "actions/api";
import QRCode from "react-qr-code";
import { isAddress } from "web3-validator";
import { Decimal } from "decimal.js";
import { submitWithdrawRequest } from "actions/api";
import { Checkmark } from "react-checkmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const SettingsLobby = (props) => {
  const { t } = useTranslation();

  const { Firestore } = useGameplay();
  const { currentUser, idToken, Auth } = useAuth();
  const [user, authLoading, error] = useAuthState(Auth);
  const [tab, setTab] = useState("account");
  const [username, setUsername] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [signOut, loading, signoutError] = useSignOut(Auth);
  const [updateProfile, updating, updateProfileError] = useUpdateProfile(Auth);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");

  const getWalletAddress = async () => {
    // let latestIdToken = await Auth.currentUser.getIdToken();
    const latestIdToken = localStorage.getItem("mjToken");
    // console.log("user", user);
    // console.log("idToken", idToken);
    const wallet = await getUserWallet(latestIdToken);
    // console.log("user wallet:", wallet);
    setWalletAddress(wallet.smartAccountAddress);
  };

  useEffect(() => {
    if (user) {
      setUsername(user?.displayName);
      getWalletAddress().catch(console.error);
    }
  }, [user]);

  useEffect(() => {
    // console.log("calling useEffect show ");
    setTimeout(() => {
      if (showConfirmModal === true) {
        setShowConfirmModal(false);
      }
    }, 2000);
  }, [showConfirmModal]);

  async function updateUserProfile() {
    // setLoading(true);

    const success = await updateProfile({
      displayName: username,
      // photoURL: downloadURL
    });
    if (success) {
      console.log("Auth profile updated successfully");
      setShowConfirmModal(true);
      setConfirmationText("账号更新成功!");

      updateDoc(doc(Firestore, "users", user.uid), {
        username,
        // profileURL: downloadURL,
        updatedAt: serverTimestamp(),
        // profileSetup: true,
      })
        .then((result) => {
          console.log("Firestore updated successfully");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  // console.log("props.location.state", props.location.state);

  return (
    <>
      <div className="gamemodeLobby-container">
        <div className="lobby-overlay">
          <div className="settings-page">
            <div className="settingsTitle"></div>
            <div className="logoutButton">
              <FontAwesomeIcon icon="fa-arrow-right-from-bracket" style={{ marginRight: 4 }} />
              {/* <PiSignOutBold size={20} /> */}
              <div
                className="signout-text"
                onClick={async () => {
                  console.log("signout button clicked");
                  localStorage.removeItem("mjToken");
                  navigate("/");
                  const success = await signOut();
                }}
              >
                {t("settings_lobby.logout_account")}
                {/* 登出账号 */}
              </div>
            </div>
            <div className="settings-container">
              <div className="tabs-container">
                <div className={`tab account-settings-tab ${tab === "account" ? "tab-active" : ""}`} onClick={() => setTab("account")}>
                  {/* 账号设定 */}
                  {t("settings_lobby.profile_settings")}
                </div>
                <div className={`tab wallet-settings-tab ${tab === "wallet" ? "tab-active" : ""}`} onClick={() => setTab("wallet")}>
                  {/* 充值设定 */}
                  {t("settings_lobby.deposit_settings")}
                </div>
                <div className={`tab wallet-settings-tab ${tab === "withdraw" ? "tab-active" : ""}`} onClick={() => setTab("withdraw")}>
                  {/* 提现 */}
                  {t("settings_lobby.withdraw_settings")}
                </div>
              </div>
              {tab === "account" && (
                <AccountSettings user={user} setUsername={setUsername} username={username} updateUserProfile={updateUserProfile} t={t} />
              )}
              {tab === "wallet" && <WalletSettings wallet={walletAddress} t={t} />}
              {tab === "withdraw" && (
                <WithdrawSettings
                  userBalanceObj={props.location.state?.userBalanceObj}
                  setShowConfirmModal={setShowConfirmModal}
                  setConfirmationText={setConfirmationText}
                  t={t}
                />
              )}
            </div>

            <div
              className="backTitle"
              onClick={() => {
                // setGameMode(null);
                navigate(`/GameModeLobby`, { state: { fromPath: "settings" } });
                // navigate(`/`, { state: { fromPath: "settings" } });
                // window.location.href = "/GameModeLobby";
              }}
            ></div>
          </div>
        </div>

        {showConfirmModal && (
          <div className="confirmationModal-container">
            <Checkmark size="96px" />
            <div className="title">{confirmationText}</div>
          </div>
        )}
      </div>
    </>
  );
};

function AccountSettings({ user, setUsername, username, updateUserProfile, t }) {
  // console.log("[account settings] user", user);
  // const { t } = useTranslation();
  let phoneNumber = parsePhoneNumber(user?.phoneNumber || "");

  if (phoneNumber) {
    phoneNumber = phoneNumber.formatInternational();
  }

  function handleInputChange(e) {
    e.preventDefault();
    const { value } = e.target;
    setUsername(value);
  }

  return (
    <div className="account-settings-container">
      <table>
        {/* <colgroup>
          <col className="label-col" />
          <col className="input-col" />
        </colgroup> */}
        <tbody>
          <tr>
            <td className="settings-label-td">
              <div className="settings-label">
                {/* 名称 */}
                {t("settings_lobby.name")}
              </div>
            </td>
            <td className="settings-input-td">
              <input
                className="settings-input username-input"
                name="username"
                type="text"
                value={username}
                onChange={(e) => handleInputChange(e)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="settings-label">
                {/* 电话号码 */}
                {t("settings_lobby.phone")}
              </div>
            </td>
            <td>
              <input className="settings-input phone-input" name="phone" type="text" value={phoneNumber} disabled={true} />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="save-settings-button" onClick={updateUserProfile}>
        {/* 保存 */}
        {t("settings_lobby.save")}
      </div>
    </div>
  );
}
export function WalletSettings({ wallet, t }) {
  // const { t } = useTranslation();
  const [copiedAddress, setCopiedAddress] = useState("");
  const [hasCopied, setHasCopied] = useState(false);

  return (
    <>
      <div className="wallet-settings-container">
        <Tooltip anchorSelect="#copyIcon" content="已複製地址" />
        <div className="settings-label">
          {/* 钱包地址  */}
          {/* {t("settings_lobby.wallet_address")} */}
          {/* 充值方法  */}
          {t("settings_lobby.deposit_method")}
          <br></br>
        </div>
        <div className="settings-label address-label">
          {/* (充值USDT时，请紧记选取「 Polygon / MATIC」为主网类型) */}
          {t("settings_lobby.polygon_prompt_reminder")}
        </div>
        <div className="wallet-container">
          <div className="wallet-address-container">
            <input className="settings-input wallet-address-input" type="text" value={wallet} disabled={true} />
            <CopyToClipboard
              text={wallet}
              onCopy={() => {
                setCopiedAddress(wallet);
              }}
            >
              {/* <FiCopy className="copy-icon" size={28} id="copyIcon" /> */}
              <FontAwesomeIcon icon="fa-copy" id="copyIcon" className="copy-icon" style={{ marginLeft: 8 }} />
            </CopyToClipboard>
            {/* <div className="copied-text">
            {hasCopied ? "" : ""}
            </div> */}
          </div>
          <div className="qr-container">
            <QRCode className="qrcode" value={wallet} size={72} />
          </div>
        </div>
        <div className="settings-label address-label">{t("settings_lobby.contact_cs_for_deposit")}</div>
        <div className="tg-container">
          <a
            className="tgSupportBtn"
            // href="https://t.me/metaverse_casino_support"
            href="https://t.me/metaversemj"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon="fa-brands fa-telegram" style={{ marginRight: 4 }} />
            {/* 客服专线 */}
            {t("settings_lobby.customer_service")}
          </a>
          <div className="settings-label address-label first-bonus-text">{t("settings_lobby.first_time_bonus")}</div>
        </div>
      </div>
    </>
  );
}

export function WithdrawSettings({ userBalanceObj, setShowConfirmModal, setConfirmationText, t }) {
  const { currentUser, idToken, Auth } = useAuth();

  const [formData, setFormData] = useState({ walletAddress: "", amount: "" });
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const latestIdToken = localStorage.getItem("mjToken");

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("formData", formData);

    if (checkFormData(formData, t)) {
      console.log("formDataCheck passed");

      // const idToken = await Auth.currentUser.getIdToken();

      let withdrawResult = await submitWithdrawRequest(formData, latestIdToken);
      console.log("withdrawResult", withdrawResult);

      if (withdrawResult.status === 201) {
        setShowConfirmModal(true);
        setConfirmationText(t("settings_lobby.withdraw_request_success"));
      }
    }
  };

  const handleChange = (event) => {
    // console.log("handling changes");
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  function checkFormData(formData, t) {
    let walletPass,
      amountPass = false;
    if (isAddress(formData.walletAddress)) {
      walletPass = true;
      setAddressErrorMessage("");
    } else {
      setAddressErrorMessage(t("settings_lobby.wallet_address_format_error"));
      walletPass = false;
    }

    let amount_dec = new Decimal(formData.amount);

    if (amount_dec.lte(0)) {
      setErrorMessage(t("settings_lobby.withdraw_amount_error"));
      amountPass = false;
    } else if (amount_dec.lt(new Decimal(10.0))) {
      setErrorMessage(t("settings_lobby.min_withdraw_10U"));
      amountPass = false;
    } else if (amount_dec.gt(new Decimal(userBalanceObj.availableUsdBalance))) {
      setErrorMessage(t("settings_lobby.insufficient_balance"));
      amountPass = false;
    } else {
      setErrorMessage("");
      amountPass = true;
    }

    return walletPass && amountPass;
  }

  // console.log("withdraw userBalance", userBalance);

  return (
    <>
      <div className="withdraw-settings-container">
        <form className="withdraw-form" onSubmit={handleSubmit}>
          <table>
            <tbody>
              <tr>
                <td className="settings-label-td">
                  <div className="settings-label">{t("settings_lobby.wallet_address")}</div>
                </td>
                <td className="settings-input-td td-override">
                  <input
                    className="settings-input withdraw-wallet-input"
                    name="walletAddress"
                    type="search"
                    // value={"0xd8ac0844F9904d4619A94fA30ECb598cd7043CEf"}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div className="error-text address-error-text"> {addressErrorMessage}</div>
                </td>
              </tr>
              <tr>
                <td className="settings-label-td">
                  <div className="settings-label">
                    {/* 提现金额 */}
                    {t("settings_lobby.withdraw_amount")}
                  </div>
                </td>
                <td className="settings-input-td">
                  <input
                    className="settings-input withdraw-amount-input"
                    name="amount"
                    type="number"
                    // value={""}
                    placeholder={t("settings_lobby.min_10U")}
                    onChange={(e) => handleChange(e)}
                    // pattern="^\d*(\.\d{0,4})?$"
                    step=".01"
                    required
                  />
                  <span className="withdraw-amount-unit">USDT</span>
                  <div className="max-amount-text">
                    {t("settings_lobby.max_amount")}: {userBalanceObj.availableUsdBalance}
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div className="error-text amount-error-text">{errorMessage}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            className="submit-button"
            type="submit"
            // disabled={confirmationResult === "" ? true : false}
          >
            {/* 提交 */}
            {t("settings_lobby.submit")}
          </button>
        </form>
      </div>
    </>
  );
}

export default SettingsLobby;

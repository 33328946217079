import React, { useContext, useEffect, useRef, useState } from "react";
import { useGameplay } from "App";
import useNewCallout from "components/CallOut/useNewCallOut";
import "./HuAnimation.scss";

const HuAnimation = ({}) => {
  const { newGE } = useGameplay();
  let finishAnimationToShow = newGE?.gameParams?.finishAnimationToShow;
  // console.log("[HuAnimation] finishAnimationToShow", finishAnimationToShow);

  return (
    <div className="HuAnimation-container">
      {finishAnimationToShow === "" && null}

      {finishAnimationToShow === "winFromOthers" && (
        <div
          style={{ height: "100%", width: "100%" }}
          dangerouslySetInnerHTML={{
            __html: `
                    <video class="overlay_action" autoplay muted playsinline>
                          <source src="/animations/mp4/fullscreen_hu.mp4" type="video/mp4;codecs=hvc1" />
                          <source src="/animations/webm/fullscreen_hu.webm" type="video/webm" />
                          Your browser does not support the video tag.
                    </video>
                  `,
          }}
        ></div>
      )}

      {finishAnimationToShow === "selfDrawn" && (
        <div
          style={{ height: "100%", width: "100%" }}
          dangerouslySetInnerHTML={{
            __html: `
                <video class="overlay_action" autoplay muted playsinline>
                      <source src="/animations/mp4/fullscreen_selfdrawn.mp4" type="video/mp4;codecs=hvc1" />
                      <source src="/animations/webm/fullscreen_selfdrawn.webm" type="video/webm" />
                      Your browser does not support the video tag.
                </video>
              `,
          }}
        ></div>
      )}
    </div>
  );
};

export default HuAnimation;

import _ from "lodash";
import { doc, getDoc, getFirestore, updateDoc, setDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { Decimal } from "decimal.js";
import { oKey, oVal, shuffle } from "utils";
import { playerActions, whoElseCan } from "./gameActions";
import { update } from "firebase/database";

export const FULL_HAND_SIZE = 14;
export const HOUSE_COMMISSION = new Decimal(0.05);
export const ACTION_DECISON_TIME = 8; //in seconds
export const TILE_DISCARD_TIME = 16; //in seconds
export const DELAY_START_TIME = 6; //in seconds

export enum GameMode {
  basic = "BASIC",
  selfDrawn = "SELF_DRAWN",
}

export class GameState {
  roomData;
  playerParams;
  gameParams;
  firestore;
  auth;
  cloudFuctions;
  gamePriceMode;
  roomId;
  gameStarted;
  gameEnded;
  houseCommission;
  unsubscribeGameplay;

  constructor(firestore, auth, cloudFuctions, gamePriceMode, roomId, roomData, unsubscribeGameplay) {
    this.firestore = firestore;
    this.auth = auth;
    this.cloudFuctions = cloudFuctions;
    this.gamePriceMode = gamePriceMode;
    this.roomId = roomId;
    this.playerParams = oVal(this.reverseTransformPlayersData(roomData.playerParams));
    // this.roomData = this.reverseTransformRoomData(roomData);
    this.roomData = roomData;
    this.houseCommission = HOUSE_COMMISSION;
    this.gameEnded = roomData.gameEnded || null;
    this.setRoomData(roomData);
    this.unsubscribeGameplay = unsubscribeGameplay;
  }

  isPlayersTurn = (playerId) => {
    const playerParams = this.getPlayerParams(playerId);
    // console.log("[isPlayersTurn]", this.roomData, this.playerParams);
    // console.log(`[isPlayersTurn]: ${playerId} playerParams`, playerParams);
    return playerParams.seat === this.gameParams?.seatTurn;
  };

  getPlayerParams = (playerId) => {
    // console.log("in getPlayerParams, (this.playerParams)", this.playerParams);
    // return in arrays
    return _.find(this.playerParams, { id: playerId });
  };

  setRoomData = async (roomData) => {
    // this.playerParams = roomData.playerParams ? oVal(roomData.playerParams) : [];
    this.gameParams = this.roomData.gameParams;
    this.gameStarted = this.roomData.gameStarted;
  };

  getAvailableActions = (id = "", gamePriceMode, roomId) => {
    // console.log("[getAvailableActions] calling getAvailableActions() from", id);
    // console.log(`[getAvailableActions] from Room: ${this.gamePriceMode}-${this.roomId}`);

    // await this.getRoomData();
    // this.setRoomData();
    // const playerParams = await this.getPlayerParams(id);

    const allActions = playerActions;
    let availableActions: any = [];

    if (this.roomData.gameEnding === true) {
      return availableActions;
    }

    allActions?.forEach((action) => {
      const isAvailable = action.isAvailable({
        executingPlayerId: id,
        gameEngine: this,
      });
      if (Array.isArray(isAvailable)) {
        isAvailable.forEach((subaction) => availableActions.push(subaction));
      } else if (isAvailable) {
        availableActions.push(action);
      }
    });

    // console.log(`[getAvailableActions] Player ${id} actions`, availableActions);

    // check if availableActions contains Hu
    const filteredActions = availableActions.filter((action) => action.name === "Hu");

    if (filteredActions.length > 0) {
      // console.log("[getAvailableActions] playerParams:", this.playerParams);

      // console.log("Hu action exists.");
      // console.log("[Hu] looking for other winners");

      const whoElseCanHu = whoElseCan("Hu", {
        executingPlayerId: id,
        gameEngine: this,
      });

      // console.log("[getAvailableActions] whoElseCanHu:", whoElseCanHu);

      let otherWinners: any[] = whoElseCanHu?.filter((p) => p.canHu === true) || [];
      // console.log("[getAvailableActions] otherWinners:", otherWinners);

      if (otherWinners?.length > 0) {
        const lastPlayerSeat = this.getPlayerParams(this.gameParams.lastPlay.by).seat;
        const currentPlayerSeat = this.getPlayerParams(id).seat;
        const otherWinnersSeats: any[] = otherWinners?.map((player) => player.seat);
        const allWinnersSeats = [...otherWinnersSeats, currentPlayerSeat];

        // console.log("lastPlayerSeat", lastPlayerSeat);
        // console.log("currentPlayerSeat", currentPlayerSeat);
        // console.log("otherWinnersSeats", otherWinnersSeats);
        // console.log("allWinnersSeats", allWinnersSeats);

        const winnerSeatWithHigherOrder = getWinnerWithHigherOrder(lastPlayerSeat, allWinnersSeats, 4);

        // console.log("winnerSeatWithHigherOrder", winnerSeatWithHigherOrder);

        if (currentPlayerSeat !== winnerSeatWithHigherOrder) {
          availableActions = [];
          // console.log("[getAvailableActions] current player canHu but has lower seat priority hence cant Hu", availableActions);
        } else {
          // console.log("[getAvailableActions] have Hu action, player has priority:", availableActions);
        }

        // old multiple winner procedure
        // if (!isCurrentPlayerSeatLeast(this.getPlayerParams(id), otherWinners)) {
        //   // current player canHu but has lower seat priority
        //   // isWinnable = false;

        //   availableActions = availableActions.filter((action) => {
        //     return action.name !== "Hu" && action.name !== "Skip";
        //   });
        //   console.log("[getAvailableActions] current player canHu but has lower seat priority hence cant Hu", availableActions);
        // } else {
        //   console.log("[getAvailableActions] have Hu action, player has priority:", availableActions);
        // }
      }
    } else {
      // console.log("[getAvailableActions] no Hu action", availableActions);
    }

    function getWinnerWithHigherOrder(lastPlayIndex, winnerIndices, numberOfPlayers) {
      let highestPrecedenceIndex = winnerIndices[0];
      let highestPrecedenceDistance = (winnerIndices[0] - lastPlayIndex + numberOfPlayers) % numberOfPlayers;

      for (let i = 1; i < winnerIndices.length; i++) {
        const currentIndex = winnerIndices[i];
        const distanceToLastPlay = (currentIndex - lastPlayIndex + numberOfPlayers) % numberOfPlayers;

        if (distanceToLastPlay < highestPrecedenceDistance) {
          highestPrecedenceIndex = currentIndex;
          highestPrecedenceDistance = distanceToLastPlay;
        }
      }

      return highestPrecedenceIndex;
    }

    // function isCurrentPlayerSeatLeast(currentPlayer, players) {
    //   // Loop through each player in the array
    //   for (let i = 0; i < players.length; i++) {
    //     // Check if currentPlayer's seat is not the least
    //     if (currentPlayer.seat < players[i].seat) {
    //       return false; // currentPlayer does not have the least seat
    //     }
    //   }
    //   return true; // currentPlayer has the least seat
    // }

    // console.log("[getAvailableActions] final returning availableActions:", availableActions);
    return availableActions;
  };

  // updatePlayerActions = async (playerId, availableActionsNames) => {
  //   // const roomData = await this.getRoomData();
  //   const roomData = this.roomData;

  //   // await this.firestore
  //   //   .collection(this.gamePriceMode)
  //   //   .doc(this.roomId) //
  //   //   .update({
  //   //     playerParams: {
  //   //       ...roomData.playerParams,
  //   //       [playerId]: {
  //   //         ...roomData.playerParams[playerId],
  //   //         availableActions: availableActionsNames,
  //   //       },
  //   //     },
  //   //   });

  //   await updateDoc(doc(this.firestore, this.gamePriceMode, this.roomId), {
  //     playerParams: {
  //       ...roomData.playerParams,
  //       [playerId]: {
  //         ...roomData.playerParams[playerId],
  //         availableActions: availableActionsNames,
  //       },
  //     },
  //   });

  //   console.log("[GameState]: updatePlayerActions successfully", availableActionsNames);
  // };

  updateAllPlayerParamsDraft = (playerId, newPlayerParams) => {
    // console.log("in updatePlayerParamsDraft playerId, newPlayerParams ", playerId, newPlayerParams);

    let newAllPlayerParamsObj = this.playerParams.reduce((accumulator, currentPlayer) => {
      // console.log("in reduce, currentPlayer", currentPlayer);

      let updatedObject = { ...currentPlayer };
      if (currentPlayer.id === playerId) {
        // console.log("updatePlayerParamsDraft found player id matched");

        updatedObject = { ...updatedObject, ...newPlayerParams };
        // console.log("updatePlayerParamsDraft latest updatedObject", updatedObject);
      }
      accumulator[currentPlayer.id] = updatedObject;
      return accumulator;
    }, {});

    // console.log("[updatePlayerParamsDraft] newAllPlayerParamsObj", newAllPlayerParamsObj);

    return newAllPlayerParamsObj;
  };

  finishTurn = (extraParams?) => {
    let nextSeatTurn = (this.gameParams.seatTurn + 1) % 4;

    let newGameParams = {
      ...this.gameParams,
      ...extraParams,
      seatTurn: nextSeatTurn,
      lastTurnTimestamp: new Date().valueOf(),
      seatReason: null,
      actionLastPlay: null,
    };
    return newGameParams;
  };

  claimTurn = (id: string, reason?: string, lastPlay?) => {
    const player = this.getPlayerParams(id);

    let newGameParams = {
      ...this.gameParams,
      seatTurn: player.seat,
      seatReason: reason || null,
      lastTurnTimestamp: new Date().valueOf(),
      actionLastPlay: lastPlay || null,
    };

    // console.log("[Debug]: In claimTurn(), updating Game Params by", id, new Date());

    return newGameParams;
  };

  updateGameState = async (newRoomData) => {
    // console.log("newRoomData", newRoomData);
    let transformedRoomData = this.transformRoomDataForSave(newRoomData);
    // console.log("transformedRoomData", transformedRoomData);

    let updateGameStateResult;
    try {
      updateGameStateResult = await setDoc(doc(this.firestore, this.gamePriceMode, this.roomId), transformedRoomData);
      // console.log("[updateGameState] updateGameStateResult", updateGameStateResult);
    } catch (error) {
      console.log(error);
    }
  };

  transformRoomDataForSave = (roomData) => {
    const transformedData = { ...roomData };

    Object.keys(transformedData.playerParams).forEach((playerId) => {
      const player = transformedData.playerParams[playerId];
      if (player.openHand && Array.isArray(player.openHand)) {
        const transformedOpenHand = {};
        player.openHand.forEach((handArray, index) => {
          transformedOpenHand[`hand_${index}`] = handArray;
        });
        player.openHand = transformedOpenHand;
      }
    });

    return transformedData;
  };

  reverseTransformRoomData = (fetchedRoomData) => {
    const roomData = { ...fetchedRoomData };

    Object.keys(roomData.playerParams).forEach((playerId) => {
      const player = roomData.playerParams[playerId];
      if (player.openHand && typeof player.openHand === "object" && !Array.isArray(player.openHand)) {
        const openHandArray: any[] = [];
        Object.keys(player.openHand).forEach((key) => {
          if (key.startsWith("hand_")) {
            const index = parseInt(key.split("_")[1], 10);
            openHandArray[index] = player.openHand[key];
          }
        });
        player.openHand = openHandArray;
      }
    });

    return roomData;
  };

  reverseTransformPlayersData = (playerParams) => {
    let transformedPlayerParams = playerParams;
    Object.keys(transformedPlayerParams).forEach((playerId) => {
      const player = transformedPlayerParams[playerId];
      if (player.openHand && typeof player.openHand === "object" && !Array.isArray(player.openHand)) {
        const openHandArray: any[] = [];
        Object.keys(player.openHand).forEach((key) => {
          if (key.startsWith("hand_")) {
            const index = parseInt(key.split("_")[1], 10);
            openHandArray[index] = player.openHand[key];
          }
        });
        player.openHand = openHandArray;
      }
    });

    return transformedPlayerParams;
  };

  updateNextTimeoutTimestamp = async ({ nextTimeoutTimestamp }) => {
    // console.log("[GameStateParser updateNextTimeoutTimestamp]", nextTimeoutTimestamp);
    // console.log("gamePriceMode, roomId", this.gamePriceMode, this.roomId);

    // const updateResult = await this.firestore
    //   .collection(this.gamePriceMode)
    //   .doc(this.roomId) //
    //   .update({ nextTimeoutTimestamp });

    const updateResult = await updateDoc(doc(this.firestore, this.gamePriceMode, this.roomId), { nextTimeoutTimestamp });

    // nextTimeoutTimestamp = nextTimeoutTimestamp;
    // console.log("[updateNextTimeoutTimestamp] update timestamp successfully", nextTimeoutTimestamp);
    return updateResult;
  };

  updateGameEndingState = async ({ gameEnding }) => {
    // console.log("[new GameEngine] calling gameEnding...");

    const updateResult = await updateDoc(doc(this.firestore, this.gamePriceMode, this.roomId), { gameEnding });
    // console.log("[new GameEngine] update gameEnding successfully", gameEnding);
    return updateResult;
  };

  getMySeat = (playerId, playerParams) => {
    let playerInfo = _.find(playerParams, { id: playerId });
    return playerInfo?.seat;
  };

  getOtherPlayersButId = (id: string) => {
    return this.playerParams?.filter((player) => player.id !== id);
  };

  // finishGame = async (huReason: string) => {
  //   console.log("[new GE] Game finishes with reason", huReason);
  //   // this.updateGameParams({ finishAnimationToShow: huReason });

  //   let allPlayersWithSkipResetObj = this.roomData.playerParams;
  //   let currentGameParams = this.roomData.gameParams;

  //   // resetting all players skips
  //   Object.keys(allPlayersWithSkipResetObj).forEach((playerId) => {
  //     allPlayersWithSkipResetObj[playerId].skipped = false;
  //   });

  //   let transformedRoomData = this.transformRoomDataForSave({
  //     playerParams: allPlayersWithSkipResetObj,
  //     gameParams: { ...currentGameParams, finishAnimationToShow: huReason },
  //   });

  //   const updateResult = await setDoc(doc(this.firestore, this.gamePriceMode, this.roomId), transformedRoomData);

  //   await new Promise((resolve) => setTimeout(resolve, 2000));
  // };

  endGame = () => {
    this.gameEnded = true;
    // this.unsubscribeGameplay();
  };
}
